<template>

    <section id="services" class="services section-bg">
        <div class="container" data-aos="fade-up">

            <div class="section-title">
                <h2>{{ $t('services.title') }}</h2>
                <p>{{ $t('services.subtitle') }}</p>
            </div>

            <div class="row">
                <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box">
                        <div class="icon"><i class='bx bx-line-chart'></i></div>
                        <h4><a href="">{{ $t('services.analise') }}</a></h4>
                        <p>{{ $t('services.analise-description') }}</p>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
                    data-aos-delay="200">
                    <div class="icon-box">
                        <div class="icon"><i class='bx bx-target-lock'></i></div>
                        <h4><a href="">{{ $t('services.plano-de-acao') }}</a></h4>
                        <p>{{ $t('services.plano-de-acao-description') }}</p>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in"
                    data-aos-delay="300">
                    <div class="icon-box">
                        <div class="icon"><i class='bx bx-bell'></i></div>
                        <h4><a href="">{{ $t('services.notificacoes') }}</a></h4>
                        <p>{{ $t('services.noticacoes-description') }}</p>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in"
                    data-aos-delay="400">
                    <div class="icon-box">
                        <div class="icon"><i class='bx bx-slideshow'></i></div>
                        <h4><a href="">{{ $t('services.apresentacoes') }}</a></h4>
                        <p>{{ $t('services.apresentacoes-description') }}</p>
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>