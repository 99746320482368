<template>
  <div class="pt-3">

    <b-breadcrumb>
      <b-breadcrumb-item active class="align-middle">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        {{ $t("breadcrumb.grafico-de-tendencia") }}
      </b-breadcrumb-item>
      <b-button variant="secondary" @click="showModalNovoGrupo()" class="ml-auto" size="sm"
        v-if="user.permissao == 'acesso_total'">
        <b-icon-plus />{{ $t('botoes.novo-grupo') }}
      </b-button>
    </b-breadcrumb>

    <b-card class="mt-3" no-body>
      <b-table :items="grupos" :fields="fields" responsive="sm" sticky-header head-variant="light" show-empty class="mb-0"
        :emptyText="$t('global.table-empty')">
        <template #cell(Ações)="row">
          <b-button size="sm" @click="showModalTrend(row.item)">
            {{ $t('botoes.abrir') }} </b-button>
          <b-button size="sm" @click="gerarGrafico(row.item.id)" class="ml-3">
            {{ $t('botoes.gerar-graficos') }} </b-button>
        </template>
        <template #cell(created_at)="row">
          {{ row.item.created_at | dataCompleta }}
        </template>
      </b-table>
    </b-card>

    <apresentacao-trend v-show="carregando" ref="ApresentacaoTrend" @gerou="gerou($event)"
      @carregouGrupos="setTotalPaginas($event)" @error="msgError($event)" />

    <modal-carregando-geral />

    <modal-trend :grupo="grupo_selecionado" @gerarGrafico="gerarGrafico($event)" />
    <modal-novo-grupo @listaMudou="carregarGrupos()" />
    <modal-carregando :count="quantidade_paginas_geradas" :tabs="quantidade_paginas" />

  </div>
</template>

<script>
import ModalTrend from "@/components/modal/Trend.vue";
import ModalNovoGrupo from "@/components/modal/TrendNovoGrupo.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import ApresentacaoTrend from "@/components/apresentacoes/Trend.vue";
import ModalCarregando from "@/components/modal/Carregando.vue";

export default {
  name: "Trend",
  components: {
    ModalCarregandoGeral,
    ModalTrend,
    ModalNovoGrupo,
    ApresentacaoTrend,
    ModalCarregando,
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  data() {
    return {
      quantidade_paginas: 0,
      quantidade_paginas_geradas: 0,
      grupo_selecionado: null,
      grupos: [],
      carregando: false,
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: 'relatorio.componente.familia.descricao', label: this.$t('table.title.familia'), sortable: true },
        { key: 'relatorio.componente.descricao', label: this.$t('table.title.componente'), sortable: true },
        { key: 'relatorio.descricao', label: this.$t('table.title.relatorio'), sortable: true },
        { key: 'descricao', label: this.$t('table.title.grupo'), sortable: true },
        { key: 'created_at', label: this.$t('table.title.criado-em'), sortable: true },
        { key: 'Ações', label: this.$t('table.title.acoes'), sortable: false },
      ],
    };
  },
  methods: {
    msgError($event) {
      this.$bvToast.toast(this.$t('toast.falha'), {
        title: this.$t('toast.erro'),
        variant: 'danger',
        solid: true,
      });
      this.$bvModal.hide("modal-carregando");
      this.carregando = false;
      document.getElementById("app").style.backgroundColor = "";
      document.getElementById("app").style.opacity = "";
    },
    setTotalPaginas(total) {
      this.quantidade_paginas = total;
      if (total == 0) {
        this.$bvToast.toast(this.$t('toast.noFound'), {
          title: this.$t('toast.erro'),
          variant: 'danger',
          solid: true,
        });
        this.$bvModal.hide("modal-carregando");
        this.carregando = false;
        document.getElementById("app").style.backgroundColor = "";
        document.getElementById("app").style.opacity = "";

      }
    },
    gerou() {
      this.quantidade_paginas_geradas++;

      if (this.quantidade_paginas_geradas == this.quantidade_paginas) {
        this.$bvModal.hide("modal-carregando");
        this.carregando = false;
        document.getElementById("app").style.backgroundColor = "";
        document.getElementById("app").style.opacity = "";
      }
    },
    gerarGrafico($event) {
      this.quantidade_paginas = 0,
        this.quantidade_paginas_geradas = 0,
        this.$bvModal.show("modal-carregando");
      this.carregando = true;
      this.$refs.ApresentacaoTrend.carregarGrupo($event);
      document.getElementById("app").style.backgroundColor = "#e4e4e4";
      document.getElementById("app").style.opacity = 0;

    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    showModalTrend(grupo) {
      this.grupo_selecionado = grupo
      this.$bvModal.show("modal-trend");
    },
    showModalNovoGrupo() {
      this.$bvModal.show("modal-trend-novo-grupo");
    },
    carregarGrupos() {
      this.carregando = true;
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/trend-grupo")
        .then((response) => {
          this.grupos = response.data;
          this.carregando = false;
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
  },
  mounted() {
    this.carregarGrupos();
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {},
};
</script>


<style></style>