<template>
  <div id="preloader" v-if="loading"></div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500)
  }

}
</script>

<style>

</style>