<template>

  <section id="pricing" class="pricing section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('pricing.precos') }}</h2>
        <p>{{ $t('pricing.subtitle') }}</p>
      </div>

      <div class="row">

        <div class="col-lg-6 mt-6 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
          <div class="box featured">

            <h3>{{ $t('pricing.plano-standard') }}</h3>
            <h4><sup>R$</sup>350<span>{{ $t('pricing.por-usuario-mes') }}</span></h4>
            <h3>{{ $t('pricing.experimente-por-7-dias-gratis') }}</h3>
            <ul>
              <li><i class="bx bx-check"></i> {{ $t('pricing.tabela-com-resultados') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.graficos-de-avancamento-semanal') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.relatorios-individuais') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.relatorios-cp-and-cpk-e-pp-and-ppk') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.relatorios-boleados') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.planos-de-acao') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.graficos-de-tendencia') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.graficos-de-curva-de-gauss') }}</li>
            </ul>
            <a href="/register" class="buy-btn">{{ $t('site-menu.get-started') }}</a>
          </div>
        </div>

        <div class="col-lg-6 mt-6 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
          <div class="box">
            <h3>{{ $t('pricing.plano-enterprise') }}</h3>
            <ul>
              <li><i class="bx bx-check"></i> {{ $t('pricing.todos-itens-do-plano-standard') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.usuarios-ilimitados') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.servidor-dedicado') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.instalacao-feita-localmente-na-empresa') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.automatizacao-de-geracao-de-apresentacoes') }}</li>
              <li><i class="bx bx-check"></i> {{ $t('pricing.treinamentos') }}</li>
            </ul>
            <router-link class="buy-btn" to="#contact">{{ $t('pricing.fale-com-um-especialista') }}</router-link>

          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>