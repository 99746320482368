<template>
  <div class="componentes">
    <b-row align-h="start" class="mt-3">
      <div class="p-3" v-for="componente in componentes" :key="componente.id">
        <router-link :to="'/componente/' + componente.id" style="text-decoration: none; color: inherit">
          <b-card class="text-center px-3 pb-3 card-componente" no-body>
            <b-row align-h="end" class="pv-0">
              <b-dropdown size="sm" variant="link" no-caret right class="pv-0" >
                <template #button-content v-if="user.permissao =='acesso_total' ">
                  <b-icon icon="three-dots"></b-icon>
                </template>
                <b-dropdown-item @click="editarComponente(componente)">{{ $t('botoes.editar') }}</b-dropdown-item>
              </b-dropdown>
            </b-row>
            <div class="lab__card" :style="'background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
              url + '/organization/' + user.tenant_id + '/api/storage/' +
              '/' +
              componente.imagem +
              ') no-repeat center center /100%'
              "></div>
            <b-card-text class="title-card">
              {{ componente.descricao }}
            </b-card-text>
            <b-card-text class="sub-title-card">
              {{ componente.desenho.toString() }}
            </b-card-text>
          </b-card>
        </router-link>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    componentes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      url: null,
    };
  },
  methods: {
    editarComponente(componente) {
      this.$emit("editarComponente", componente);
    },
  },
  mounted() {
    this.url = process.env.VUE_APP_ROOT_API;
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
};
</script>

<style scoped>
.lab__card {
  height: 150px;
  width: 200px;
  padding: 0px;
  margin-bottom: 5px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
}

.title-card {
  font-weight: bold;
  font-size: 16px;
}

.sub-title-card {
  font-weight: 400;
  font-size: 14px;
  color: gray;
}

.card-componente {
  width: 230px;
  height: 300px;
}
</style>

