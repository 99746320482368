<template>
  <b-modal id="config-geral" size="lg" :title=" $t('title.automarb-configuracoes') ">
    <b-card no-body>
      <b-alert show variant="danger" v-if="menssagem">
        <ul v-if="menssagem">
          <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
        </ul>
      </b-alert>
      <b-tabs card>
        <b-tab title="Cp/Cpk" active>
          <b-card-text>
            <b-card bg-variant="light" :header=" $t('header.margem-de-seguranca') ">
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Cp</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="ms" v-model="form.cp_ms"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Cpk</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="ms" v-model="form.cpk_ms"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
            <b-card bg-variant="light" :header=" $t('header.tamanho-do-subgrupo') " class="mt-3">
              <b-form-group>
                <b-form-select :options="tm_sub" v-model="form.cpk_sub"></b-form-select>
              </b-form-group>
            </b-card>
            <b-card bg-variant="light" :header=" $t('header.objetivo-padrao') " class="mt-3">
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Cp</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="obj" v-model="form.cp_obj"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Cpk</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="obj" v-model="form.cpk_obj"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
          </b-card-text>
        </b-tab>
        <b-tab title="Pp/Ppk">
          <b-card-text>
            <b-card bg-variant="light" :header=" $t('header.margem-de-seguranca') ">
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Pp</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="ms" v-model="form.pp_ms"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Ppk</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="ms" v-model="form.ppk_ms"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>

            <b-card bg-variant="light" :header=" $t('header.objetivo-padrao') " class="mt-3">
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Pp</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="obj" v-model="form.pp_obj"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">Ppk</label>
                  </b-col>
                  <b-col>
                    <b-form-select :options="obj" v-model="form.ppk_obj"></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
          </b-card-text>
        </b-tab>
        <b-tab :title=" $t('title.geral') ">
          <b-row>
            <b-col sm="6">
              <b-card-text>
                <b-card bg-variant="light" :header=" $t('header.nivel-sigma') ">
                  <b-form-group>
                    <b-form-select :options="niveis_sigma" v-model="form.nivel_sigma"></b-form-select>
                  </b-form-group>
                </b-card>

                <b-card bg-variant="light" :header=" $t('header.linguagem') " class="mt-3">
                  <b-form-group>
                    <b-form-select :options="linguagens" v-model="form.linguagem"></b-form-select>
                  </b-form-group>
                </b-card>

                <b-card bg-variant="light" :header=" $t('header.grafico-geral') " class="mt-3">
                  <b-form-group>
                    <b-row align-v="center">
                      <b-col>
                        <label class="mr-sm-2">{{ $t('label.periodo') }}</label>
                      </b-col>
                      <b-col>
                        <b-form-select v-model="form.periodo">
                          <b-form-select-option v-for="n in 59" :key="n + 1" :value="n + 1">{{ n + 1 }}
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-card>
              </b-card-text>
            </b-col>
            <b-col>
              <b-card bg-variant="light" :header=" $t('header.objetivo-padrao') ">
                <b-form-group>
                  <b-row align-v="center">
                    <b-col>
                      <label class="mr-sm-2">{{ $t('label.conformidade') }}</label>
                    </b-col>
                    <b-col>
                      <b-form-select :options="obj" v-model="form.conformidade_obj"></b-form-select>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
              <b-card bg-variant="light" :header=" $t('header.impressao-automatica') " class="mt-3">
                <b-form-checkbox v-model="form.print_capa">{{ $t('modal.capa') }}</b-form-checkbox>
                <b-form-checkbox v-model="form.print_cpk">Cp&Cpk / Pp&Ppk</b-form-checkbox>
                <b-form-checkbox v-model="form.print_workflow">WorkFlow</b-form-checkbox>
                <b-form-checkbox v-model="form.print_conformidade">{{ $t('modal.conformidade') }}</b-form-checkbox>
                <b-form-checkbox v-model="form.print_boleado">{{ $t('modal.boleado') }}</b-form-checkbox>
                <b-form-checkbox v-model="form.print_relatorio">{{ $t('modal.relatorioConvecional') }}</b-form-checkbox>
                <b-form-checkbox v-model="form.print_plano">{{ $t('modal.planoDeAcao') }}</b-form-checkbox>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title=" $t('title.fechamento') ">
          <b-card bg-variant="light" :header=" $t('header.tipo-de-fechamento') ">
            <b-form-group>
              <b-form-select :options="fechamentos" v-model="form.fechamento"></b-form-select>
            </b-form-group>
          </b-card>
          <b-card bg-variant="light" :header=" $t('header.tipo-data-controle') " class="mt-3">
            <b-form-group>
              <b-form-select :options="data_controle_options" v-model="form.data_controle"></b-form-select>
            </b-form-group>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8"> </b-col>
          <b-col cols="6" md="4">
            <b-button variant="primary" class="float-right" v-if="carregando" disabled>
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button v-else type="submit" variant="primary" class="float-right" @click="salvar()">
              {{ $t('botoes.salvar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  computed: {
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    semanas: {
      get() {
        return this.$store.state.semanas;
      },
      set(value) {
        this.$store.commit("setSemanas", value);
      },
    },
    fechamento: {
      get() {
        return this.$store.state.fechamento;
      },
      set(value) {
        this.$store.commit("setFechamento", value);
      },
    },
    lang: {
      get() {
        return this.$store.state.lang;
      },
      set(value) {
        this.$store.commit("setLang", value);
      },
    },
  },
  data() {
    return {
      form: {
        cp_ms: 1.33333333,
        cpk_ms: 1.33333333,
        cpk_sub: 2,
        cp_obj: 0.95,
        cpk_obj: 0.9,

        pp_ms: 1.33333333,
        ppk_ms: 1.33333333,
        pp_obj: 0.95,
        ppk_obj: 0.9,

        conformidade_obj: 1,
        nivel_sigma: 6,
        linguagem: "pt-BR",
        periodo: 35,
        fechamento: 1,
        data_controle: 0,

        print_capa: true,
        print_cpk: true,
        print_workflow: true,
        print_conformidade: true,
        print_boleado: true,
        print_relatorio: true,
        print_plano: true,
      },
      ms: [
        { value: 1.33333333, text: "1S = 1.33333333" },
        { value: 1.67777777, text: "2S = 1.67777777" },
      ],
      tm_sub: [
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
      ],
      obj: [
        { value: 0.0, text: 0.0 },
        { value: 0.05, text: 0.05 },
        { value: 0.1, text: 0.1 },
        { value: 0.15, text: 0.15 },
        { value: 0.2, text: 0.2 },
        { value: 0.25, text: 0.25 },
        { value: 0.3, text: 0.3 },
        { value: 0.35, text: 0.35 },
        { value: 0.4, text: 0.4 },
        { value: 0.45, text: 0.45 },
        { value: 0.5, text: 0.5 },
        { value: 0.55, text: 0.55 },
        { value: 0.6, text: 0.6 },
        { value: 0.65, text: 0.65 },
        { value: 0.7, text: 0.7 },
        { value: 0.75, text: 0.75 },
        { value: 0.8, text: 0.8 },
        { value: 0.85, text: 0.85 },
        { value: 0.9, text: 0.9 },
        { value: 0.95, text: 0.95 },
        { value: 1.0, text: 1.0 },
      ],
      linguagens: [
        { value: "pt-BR", text:  this.$t('idiomas.portugues-bra')  },
        { value: "en", text: this.$t('idiomas.ingles-eua-gbr') },
        { value: "it", text: this.$t('idiomas.italiano-ita') },
        { value: "es", text: this.$t('idiomas.espanhol-esp-arg') },
      ],
      niveis_sigma: [
        { value: 6, text: this.$t('opcoes.sigmas.6-sigma') },
        { value: 8, text: this.$t('opcoes.sigmas.8-sigma') },
        { value: 10, text: this.$t('opcoes.sigmas.10-sigma') },
        { value: 12, text: this.$t('opcoes.sigmas.12-sigma') },
      ],
      fechamentos: [
        { value: 1, text: this.$t('opcoes.tipos-fechamento.automatico') },
        { value: 2, text: this.$t('opcoes.tipos-fechamento.manual') },
      ],
      data_controle_options: [
        { value: 0, text: this.$t('opcoes.tipos-fechamento.realizacao') },
        { value: 1, text: this.$t('opcoes.tipos-fechamento.carregamento') },
      ],
      show: true,
      carregando: false,
      menssagem: null,
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    carregarConfiguracoes() {

      this.carregando = true;
      this.$http
        .get("api/configuracao")
        .then((response) => {

           if (this.linguagem == null) {
            this.linguagem = response.data.linguagem
          }

          this.lang = response.data.linguagem    
          this.$i18n.locale = response.data.linguagem      

          if (this.ano == null) {
            this.ano = response.data.ano
          }

          if (this.semana == null) {
            this.semana = response.data.semana
          }

          if (this.semanas == null) {
            this.semanas = response.data.semanas_ano
          }
          
          this.fechamento = response.data.fechamento        

          this.form = response.data;

          this.form.print_capa = response.data.print_capa ? true : false;
          this.form.print_cpk = response.data.print_cpk ? true : false;
          this.form.print_workflow = response.data.print_workflow
            ? true
            : false;
          this.form.print_conformidade = response.data.print_conformidade
            ? true
            : false;
          this.form.print_boleado = response.data.print_boleado ? true : false;
          this.form.print_relatorio = response.data.print_relatorio
            ? true
            : false;
          this.form.print_plano = response.data.print_plano ? true : false;

          (this.form.cp_ms = response.data.cp_ms.toFixed(8)),
            (this.form.cpk_ms = response.data.cpk_ms.toFixed(8)),
            (this.form.cp_obj = response.data.cp_obj),
            (this.form.cpk_obj = response.data.cpk_obj),
            (this.form.pp_ms = response.data.pp_ms.toFixed(8)),
            (this.form.ppk_ms = response.data.ppk_ms.toFixed(8)),
            (this.form.pp_obj = response.data.pp_obj),
            (this.form.ppk_obj = response.data.ppk_obj),
            (this.form.fechamento = response.data.fechamento),
            (this.form.data_controle = response.data.data_controle),
            (this.carregando = false);
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .put('api/configuracao/1', this.form, config)
        .then((response) => {          
          this.makeToast("success");
          this.$bvModal.hide("config-geral");
          this.carregando = false;
          location.reload();
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarConfiguracoes();
  },
};
</script>

<style scoped>
</style>