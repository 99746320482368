<template>
    <b-card no-body class="p-0 m-0">
        <template #header>
            <b-card-title class="text-center p-0 m-0 title">Top Five - 5 Piores Itens</b-card-title>
        </template>
        <b-row class="px-4 py-2">
            <b-col v-for="n in  5 " :key="n">
                <div v-if="(6 - n) <= verde.length">
                    <b-row class="border geral text-center" align-v="stretch" align-content="center">
                        <b-col class="number_iten text-center" align-self="center">{{ 6 - n }}</b-col>
                        <b-col class="border-left imagem">
                            <div class="lab__card" :style="'background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
                                url + '/organization/' + user.tenant_id + '/api/storage/' +
                                imagem[5 - n] +
                                ') no-repeat center center /100%'
                                "></div>
                        </b-col>
                        <b-col class="border-left" align-self="stretch">
                            <b-row class="" style="height:26px">
                                <b-col class="px-2 text-center" align-self="center">{{ vermelho[5 - n] }}</b-col>
                                <b-col class="vermelho"></b-col>
                            </b-row>
                            <b-row class="border-top border-bottom" style="height:26px">
                                <b-col class="px-2 text-center" align-self="center">{{ amarelo[5 - n] }}</b-col>
                                <b-col class=" amarelo"></b-col>
                            </b-row>
                            <b-row class="" style="height:26px">
                                <b-col class="px-2 text-center" align-self="center">{{ verde[5 - n] }}</b-col>
                                <b-col class=" verde"></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="text-center geral border-bottom border-left border-right">
                        <b-col>{{ desenho[5 - n] }}</b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-row class="border geral text-center" align-v="stretch" align-content="center">
                        <b-col class="number_iten text-center" align-self="center">{{ 6 - n }}</b-col>
                        <b-col class="border-left imagem">
                            <div class="lab__card"></div>
                        </b-col>
                        <b-col class="border-left" align-self="stretch">
                            <b-row class="" style="height:26px">
                                <b-col class="px-2 text-center" align-self="center"></b-col>
                                <b-col class="vermelho"></b-col>
                            </b-row>
                            <b-row class="border-top border-bottom" style="height:26px">
                                <b-col class="px-2 text-center" align-self="center"></b-col>
                                <b-col class=" amarelo"></b-col>
                            </b-row>
                            <b-row class="" style="height:26px">
                                <b-col class="px-2 text-center" align-self="center"></b-col>
                                <b-col class=" verde"></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="text-center geral border-bottom border-left border-right">
                        <b-col> - </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    props: {
        verde: [],
        vermelho: [],
        amarelo: [],
        imagem: [],
        desenho: []
    },
    data() {
        return {
            url: null,
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user.user;
        },
    },
    mounted() {
        this.url = process.env.VUE_APP_ROOT_API;
    },

}
</script>

<style scoped>
.border {
    border: 1px solid black;
}

.border-top {
    border-top: 1px solid black;
}

.border-bottom {
    border-bottom: 1px solid black;
}

.border-top {
    border-top: 1px solid black;
}

.border-left {
    border-left: 1px solid black;
}

.border-right {
    border-right: 1px solid black;
}

.vermelho {
    background-color: red;
    width: 5px;
    padding: 0px;
    margin: 0px;
}

.amarelo {
    background-color: yellow;
    width: 5px;
    padding: 0px;
    margin: 0px;
}

.verde {
    background-color: green;
    width: 5px;
    padding: 0px;
    margin: 0px;
}

.number_iten {
    font-size: 22px;
    font-weight: bold;
}

.imagem {
    height: 80px;
}

.lab__card {
    height: 80px;
    width: 80px;
    padding: 0px;
    margin: 0px;
    object-fit: contain;
    transition: transform 0.45s;
    color: white;
}

.title {
    font-size: small;
    font-weight: bold;
}

.geral {
    font-size: small;
    font-weight: bold;
}
</style>