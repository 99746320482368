<template>
  <div class="bg-gradient-success">
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9 pt-5">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white"></h1>
              <p class="text-lead text-white"></p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5 container-login">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <!-- <b-row class="mb-3">
            <b-col>
              <router-link to="/site" class="text-light"><b-icon icon="chevron-left" aria-hidden="true"></b-icon> {{
                $t('login.ir-para-site') }}</router-link>
            </b-col>
          </b-row> -->
          <b-card no-body class="border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <b-row class="justify-content-center">
                <img src="../assets/logo.png" alt="Logo" height="40" />
              </b-row>

              <div class="text-center text-muted mb-4">
                <small>{{ $t('forgot.esqueceu-senha') }}</small>
              </div>

              <b-form role="form" @submit="onSubmit">
                <b-form-group id="fieldset-1" :label="$t('forgot.entre-com-seu-e-mail')" label-for="input-email"
                  valid-feedback="Thank you!">
                  <b-form-input id="input-email" v-model="form.email" type="email" placeholder="E-mail"></b-form-input>
                </b-form-group>

                
                <div class="text-center">
                  <b-button block variant="primary" v-if="carregando" disabled class="my-4">
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }}
                  </b-button>
                  <b-button block v-else type="primary" variant="primary" native-type="submit" class="my-4">{{
                    $t('botoes.enviar-link-redefinir')
                  }}
                  </b-button>
                </div>
              </b-form>

              <b-alert show variant="success" v-if="menssagemSucesso">
                {{ menssagemSucesso }}
              </b-alert>

              <b-alert show variant="danger" v-if="menssagem">
                <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
              </b-alert>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/login" class="text-light"><b-icon icon="chevron-left" aria-hidden="true"></b-icon> {{ $t('login.voltar-login') }}
              </router-link>
            </b-col>
            <b-col cols="6">
              <b-row align-v="center" align-h="center">
                <b-col cols="auto">
                  <b-icon icon="globe" style="color:white; padding-left: 1rem;"></b-icon>
                </b-col>
                <b-col>
                  <b-form-select size="sm" :options="linguagens" v-model="$i18n.locale"></b-form-select>
                </b-col>

              </b-row>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        email: null,
      },      
      menssagem: null,
      menssagemSucesso: null,
      carregando: null,
    };
  },
  computed: {
    linguagens() {
      return [
        { value: "pt-BR", text: this.$t('idiomas.portugues-bra') },
        { value: "en", text: this.$t('idiomas.ingles-eua-gbr') },
        { value: "it", text: this.$t('idiomas.italiano-ita') },
        { value: "es", text: this.$t('idiomas.espanhol-esp-arg') },
      ]
    },
    mensagemErro() {
      return this.$t('login.usuario-ou-senha-invalido')
    },
    mensagemCamposVazios() {
      return this.$t('login.todos-os-campos-devem-ser-preechidos')
    }
  },
  methods: {
    onSubmit(event) {
      this.menssagem = null
      this.menssagemSucesso = null,
      this.carregando = true
      event.preventDefault();

      if (this.form.email) {        

        let config = {
          headers: {            
            Accept: "application/json",
          },
        };

        this.$http
          .post('api/forgot-password', this.form, config)
          .then((response) => {     
            this.menssagemSucesso = response.data.status      
            this.carregando = false;            
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      }
    }
  },
};
</script>
<style scoped>
.bg-gradient-success {
  background: rgb(31, 23, 77);
  background: linear-gradient(90deg,
      rgba(31, 23, 77, 1) 0%,
      rgba(9, 68, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  min-width: 100vh;
}

.container-login {
  margin-top: 100px;
}

ul {
  list-style-type: none;
 }
</style>
