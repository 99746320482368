var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relatorio ",attrs:{"id":"relatorio"}},[(_vm.menssagem)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('span',[_c('b-icon',{attrs:{"icon":"exclamation-octagon","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.menssagem))],1)]):_c('div',_vm._l((_vm.resultado.paginas),function(pagina,index){return _c('div',{directives:[{name:"b-scrollspy",rawName:"v-b-scrollspy"}],key:pagina.id,ref:'pagina_cpk' + index,refInFor:true,staticClass:"mb-3 pagina",on:{"&scroll":function($event){return _vm.scrollPagina.apply(null, arguments)}}},[(index == 0)?_c('b-row',{staticClass:"relatorio-cabecalho mb-2"},[_c('b-col',{staticClass:"pl-3",attrs:{"cols":"6"}},[(_vm.resultado.componente)?_c('box-indicadores',{attrs:{"fechamento":_vm.resultado.fechamento,"descricao":_vm.resultado.componente.descricao,"desenho":_vm.resultado.componente.desenho,"indicador":_vm.indicador}}):_vm._e()],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"2"}},[(_vm.resultado.fechamento_analysis)?_c('root-cause-bar',{ref:"rootcausebar",refInFor:true,attrs:{"analysis":_vm.resultado.fechamento_analysis}}):_vm._e()],1),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"4"}},[_c('b-card',{staticClass:"container-risk",attrs:{"no-body":""}},[(_vm.resultado.tabela)?_c('risk-bar',{ref:"riskbar",refInFor:true,attrs:{"risks":_vm.resultado.tabela.risks.quantidade,"cores":_vm.resultado.tabela.risks.cor,"categorias":_vm.resultado.tabela.risks.label}}):_vm._e(),_c('risk-legenda')],1)],1)],1):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.carregando,"rounded":"sm","opacity":"1"}},[_c('div',{ref:'pagina' + pagina.id,refInFor:true,class:index == 0 ? 'pagina1 border' : 'pagina border',style:({
            'background-image':
              'url(' + _vm.url + '/organization/' + _vm.user.tenant_id + '/api/storage/' + pagina.imagem + ')',
          }),attrs:{"id":'paginacpk' + pagina.id}},[_c('v-stage',{ref:'stage' + pagina.id,refInFor:true,staticClass:"kanva",attrs:{"config":index == 0 ? _vm.stage1Size : _vm.stageSize}},[_c('v-layer',_vm._l((_vm.avaliableLinhas(pagina.id)),function(line){return _c('v-line',{key:line.id,attrs:{"config":{
                stroke: 'black',
                strokeWidth: 1,
                points: line.points,
              }}})}),1)],1),_vm._l((pagina.elementos),function(elemento){return _c('elemento-componente',{key:'caixa' + elemento.id,style:('left:' +
              elemento.posicaocaixal +
              'px; top:' +
              elemento.posicaocaixat +
              'px;'),attrs:{"elemento":elemento,"indicador":_vm.resultado.componente.indicador,"tipo":"cpk"}})}),_vm._l((pagina.elementos),function(ponto){return _c('ponto-componente',{key:'ponto' + ponto.id,style:('left:' +
              ponto.posicaopontol +
              'px; top:' +
              ponto.posicaopontot +
              'px;'),attrs:{"ponto":ponto,"tipo":"cpk"}})})],2)]),_c('b-row',{staticClass:"align-self-end paginacao",attrs:{"align-v":"end"}},[_c('b-col',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1)+"/"+_vm._s(_vm.resultado.paginas.length))])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }