<template>
  <table class="table table-bordered table-condensed">
    <tr>
      <td style="background-color: #d6dce4"></td>
      <td>To 0,5mm</td>
    </tr>
    <tr>
      <td style="background-color: #9bc2e6"></td>
      <td>To 1,0mm</td>
    </tr>
    <tr>
      <td style="background-color: #e3de00"></td>
      <td>To 1,5mm</td>
    </tr>
    <tr>
      <td style="background-color: #ff4f4f"></td>
      <td>To 2,0mm</td>
    </tr>
    <tr>
      <td style="background-color: #0070c0"></td>
      <td>To 2,5mm</td>
    </tr>
    <tr>
      <td style="background-color: #7030a0"></td>
      <td>To 3,0mm</td>
    </tr>
    <tr>
      <td style="background-color: #002060"></td>
      <td>To 3,5mm</td>
    </tr>
    <tr>
      <td style="background-color: #262626"></td>
      <td>To 4,0mm</td>
    </tr>
    <tr>
      <td style="background-color: #0d0d0d"></td>
      <td>Up 4,5mm</td>
    </tr>
  </table>
</template>

<script>
export default {};
</script>

<style scoped>
.table-condensed {
  margin-top: 0;
  padding-top: 0;
  font-size: 9px !important;
  text-align: center;
  font-weight: bold;
}

tr td,
th {
  height: 5px !important;
  padding: 1px !important;
}
</style>