<template>
  <b-modal id="modal-elemento" size="xl" :title=" $t('title.automarb-elemento') ">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.automarb-elemento') }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>

    <b-form v-if="show">
      <b-row>
        <b-col sm="4">
          <b-card bg-variant="light" :header=" $t('header.elementos') ">
            <b-form-group id="input-group-1">
              <b-form-select
                v-model="elemento_selecionado"
                :options="optionsElemento"
                :select-size="22"
              ></b-form-select>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col sm="8">
          <b-card bg-variant="light" :header=" $t('header.dimensao') ">
            <b-form-group>
              <b-row>
                <b-col class="ml-3">
                  <b-row>
                    <b-form-radio
                      v-model="eixo_selecionado"
                      name="some-radios"
                      value="X"
                      :disabled="form.exibirx == false"
                    ></b-form-radio>
                    <b-form-checkbox v-model="form.exibirx"
                      >{{ $t('modal.eixo-x') }}</b-form-checkbox
                    >
                  </b-row>
                  <b-row
                    ><b-form-radio
                      v-model="eixo_selecionado"
                      name="some-radios"
                      value="Y"
                      :disabled="form.exibiry == false"
                    ></b-form-radio
                    ><b-form-checkbox v-model="form.exibiry"
                      >{{ $t('modal.eixo-y') }}</b-form-checkbox
                    ></b-row
                  >
                  <b-row
                    ><b-form-radio
                      v-model="eixo_selecionado"
                      name="some-radios"
                      value="Z"
                      :disabled="form.exibirz == false"
                    ></b-form-radio
                    ><b-form-checkbox v-model="form.exibirz"
                      >{{ $t('modal.eixo-z') }}</b-form-checkbox
                    ></b-row
                  >
                  <b-row
                    ><b-form-radio
                      v-model="eixo_selecionado"
                      disabled
                      name="some-radios"
                      value="T"
                    ></b-form-radio
                    ><b-form-checkbox disabled v-model="form.exibirt"
                      >{{ $t('modal.erro-total-t') }}</b-form-checkbox
                    ></b-row
                  >
                  <b-row
                    ><b-form-radio
                      v-model="eixo_selecionado"
                      disabled
                      name="some-radios"
                      value="D"
                    ></b-form-radio
                    ><b-form-checkbox disabled v-model="form.exibird"
                      >{{ $t('modal.distancia') }}</b-form-checkbox
                    ></b-row
                  >
                </b-col>
                <b-col cols="8">
                  <b-form-checkbox-group
                    id="checkbox-dimensao"
                    v-model="dimensao_selected"
                    name="dimensoes"
                    stacked
                  >
                    <b-row
                      ><b-form-radio
                        v-model="eixo_selecionado"
                        disabled
                        name="some-radios"
                        value="W"
                      ></b-form-radio
                      ><b-form-checkbox disabled v-model="form.exibirw"
                        >{{ $t('modal.comprimento') }}</b-form-checkbox
                      ></b-row
                    >
                    <b-row
                      ><b-form-radio
                        v-model="eixo_selecionado"
                        disabled
                        name="some-radios"
                        value="L"
                      ></b-form-radio
                      ><b-form-checkbox disabled v-model="form.exibirl"
                        >{{ $t('modal.largura') }}</b-form-checkbox
                      ></b-row
                    >
                    <b-row
                      ><b-form-radio
                        v-model="eixo_selecionado"
                        disabled
                        name="some-radios"
                        value="P"
                      ></b-form-radio
                      ><b-form-checkbox disabled v-model="form.exibirp"
                        >{{ $t('modal.posicao-real-paralelismo-perpendicularidade') }}</b-form-checkbox
                      ></b-row
                    >
                    <b-row
                      ><b-form-radio
                        v-model="eixo_selecionado"
                        disabled
                        name="some-radios"
                        value="A"
                      ></b-form-radio
                      ><b-form-checkbox disabled v-model="form.exibira"
                        >{{ $t('modal.angulo') }}</b-form-checkbox
                      ></b-row
                    >
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-card>

          <b-card bg-variant="light" :header=" $t('header.configuracoes') " class="mt-3">
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label=" $t('label.tipo') "
              label-for="input-default"
            >
              <b-form-select
                v-model="form.tipo_elemento_id"
                :options="tipos_elemento"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label=" $t('labe.nome') "
              label-for="input-default"
            >
              <b-form-input
                id="input-default"
                v-model="form.descricao"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label="$t('label.calculo') +' '+ eixo_selecionado"
              label-for="input-default"
            >
              <b-form-select
                v-if="eixo_selecionado == ''"
                disabled
              ></b-form-select>
              <b-form-select
                v-if="eixo_selecionado == 'X'"
                v-model="form.calculox"
                :options="tipos_calculo"
              ></b-form-select>
              <b-form-select
                v-if="eixo_selecionado == 'Y'"
                v-model="form.calculoy"
                :options="tipos_calculo"
              ></b-form-select>
              <b-form-select
                v-if="eixo_selecionado == 'Z'"
                v-model="form.calculoz"
                :options="tipos_calculo"
              ></b-form-select>
            </b-form-group>
            <b-row align-v="center" align-h="between" class="mb-3">
              <b-col sm="2"
                ><span>{{$t('modal.tolerancia')}} {{ eixo_selecionado }}</span></b-col
              >
              <b-col sm="4">
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == ''"
                  disabled
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == 'X'"
                  v-model="form.lixtol"
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == 'Y'"
                  v-model="form.liytol"
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == 'Z'"
                  v-model="form.liztol"
                ></b-form-input>
              </b-col>
              <b-col sm="2"
                ><b-button
                  block
                  v-b-modal.modal-multi-3
                  :disabled="eixo_selecionado == ''"
                  >: | :</b-button
                ></b-col
              >
              <b-col sm="4">
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == ''"
                  disabled
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == 'X'"
                  v-model="form.lsxtol"
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == 'Y'"
                  v-model="form.lsytol"
                ></b-form-input>
                <b-form-input
                  type="number"
                  v-if="eixo_selecionado == 'Z'"
                  v-model="form.lsztol"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label=" $t('label.sair') "
              label-for="input-default"
            >
              <b-form-select
                v-model="form.saida"
                :options="tipos_saida"
              ></b-form-select>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-3">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col
                ><p class="h1 ml-3">
                  <b-icon
                    icon="exclamation-octagon"
                    variant="primary"
                  ></b-icon></p
              ></b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                  {{ $t('modal.preenchaTodosOsDadosCorretamente') }} </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
             <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvarElemento()"
            >
              {{ $t('botoes.salvar') }} </b-button>

            <b-button
              v-if="elemento.id"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="
                showMsgConfirmation(
                  $t('confirmacao.tem-certeza-que-deseja-remover-esse-elemento-do-relatorio')
                )
              "
            >
              {{ $t('botoes.deletar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <b-modal id="modal-multi-3" size="sm" title="AutoMarb" ok-only centered>
      <p class="my-1">{{ $t('modal.valor-para-tolerancia-simetrica') }}</p>
      <b-form-input
        type="number"
        v-model="valor_simetrico"
        step="any"
      ></b-form-input>
      <template #modal-footer>
        <div class="w-100">
          <b-row align-v="center">
            <b-col cols="12" md="8"> </b-col>
            <b-col cols="6" md="4">
              <b-button
                variant="primary"
                class="float-right"
                v-if="carregando"
                disabled
              >
                <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                {{ $t('botoes.carregando') }} </b-button>

              <b-button
                v-else
                type="submit"
                variant="primary"
                class="float-right"
                @click="aplicarToleranciaSimetrica()"
              >
                {{ $t('botoes.aplicar') }} </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </b-modal>
</template>

<script>
export default {
  props: {
    padrao: {
      type: Object,
      default: function () {
        return {
          id: null,
        };
      },
    },
    pagina: {
      type: Object,
      default: function () {
        return {
          id: null,
          relatorio_id: null,
        };
      },
    },
    elemento: {
      type: Object,
      default: function () {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      valor_simetrico: 0.5,
      eixo_selecionado: "",
      tipos_saida: [
        { value: "Desvio", text: this.$t('opcoes.saida.desvio') },
        { value: "Medido", text: this.$t('opcoes.saida.medido') },
      ],
      saida_selecionado: null,
      controle_padrao: null,
      optionsElemento: [],
      elemento_selecionado: null,
      dimensao_selected: [],
      tipos_elemento: [],
      tipo_elemento_selecionado: null,
      tipos_calculo: [],
      tipo_calculo_selecionado: null,
      form: {
        relatorio_id: null,
        descricao: null,
        descricao_ref: null,
        tipo_elemento_id: 2,
        pagina_id: null,
        saida: 'Desvio',

        exibirx: false,
        exibiry: false,
        exibirz: false,
        exibirt: false,
        exibird: false,
        exibirl: false,
        exibirw: false,
        exibirp: false,
        exibira: false,

        calculox: 1,
        calculoy: 1,
        calculoz: 1,
        calculot: 1,
        calculod: 1,
        calculol: 1,
        calculow: 1,
        calculop: 1,
        calculoa: 1,

        lixtol: -0.5,
        liytol: -0.5,
        liztol: -0.5,
        littol: -0.5,
        lidtol: -0.5,
        liltol: -0.5,
        liwtol: -0.5,
        liptol: -0.5,
        liatol: -0.5,

        lsxtol: 0.5,
        lsytol: 0.5,
        lsztol: 0.5,
        lsttol: 0.5,
        lsdtol: 0.5,
        lsltol: 0.5,
        lswtol: 0.5,
        lsptol: 0.5,
        lsatol: 0.5,
      },
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/elemento",
    };
  },
  methods: {
    aplicarToleranciaSimetrica() {
      var inverso = this.valor_simetrico * -1;

      if (this.eixo_selecionado == "X") {
        this.form.lixtol = inverso;
        this.form.lsxtol = this.valor_simetrico;
      }

      if (this.eixo_selecionado == "Y") {
        this.form.liytol = inverso;
        this.form.lsytol = this.valor_simetrico;
      }

      if (this.eixo_selecionado == "Z") {
        this.form.liztol = inverso;
        this.form.lsztol = this.valor_simetrico;
      }
      this.$bvModal.hide("modal-multi-3");
    },
    salvarElemento() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {          
          Accept: "application/json",
        },
      };

      if (this.elemento.id) {
        this.$http
          .put(this.url + "/" + this.elemento.id, this.form, config)
          .then((response) => {
            console.log(response);
            this.makeToast("success");
            this.$bvModal.hide("modal-elemento");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      } else {
        this.$http
          .post(this.url, this.form, config)
          .then((response) => {
            console.log(response);
            this.makeToast("success");
            this.$bvModal.hide("modal-elemento");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      }
    },
    carregarPadrao() {
      this.$http
        .get("api/padrao/" + this.padrao.id)
        .then((response) => {
          console.log(response.data);

          this.controle_padrao = response.data;
          this.optionsElemento = [];
          this.controle_padrao.valores.forEach((element) => {
            this.optionsElemento.push({
              value: element.descricao,
              text: element.descricao,
            });
          });

          this.tipos_elemento = [];
          this.controle_padrao.tipos_elemento.forEach((element) => {
            this.tipos_elemento.push({
              value: element.id,
              text: element.descricao,
            });
          });

          this.tipos_calculo = [];
          this.controle_padrao.tipos_calculo.forEach((element) => {
            this.tipos_calculo.push({
              value: element.id,
              text: element.descricao,
            });
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-elemento");
    },
    showMsgConfirmation(msg) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: this.$t('title.confirme-por-favor'),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t('confirmacao.sim'),
          cancelTitle: this.$t('confirmacao.nao'),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },    
    deletar() { 

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.url = "api/elemento/" + this.elemento.id;

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-elemento");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarPadrao();
  },
  watch: {
    padrao: function (){
      console.log('atualizou')
      this.carregarPadrao();
    },
    pagina: function (pagina) {
      this.form.pagina_id = pagina.id;
      this.form.relatorio_id = pagina.relatorio_id;
    },
    elemento_selecionado: function (elemento_selecionado) {
      this.form.descricao = elemento_selecionado;
      this.form.descricao_ref = elemento_selecionado;      
    },
    elemento: function (elemento) {
      if(elemento.id){
        this.elemento = elemento;      
        this.form.descricao = elemento.descricao;
        this.form.descricao_ref = elemento.descricao_ref;
        this.form.tipo_elemento_id = elemento.tipo_elemento_id;      
        this.form.saida = elemento.saida;

        this.form.exibirx = elemento.exibirx ? true : false;
        this.form.exibiry = elemento.exibiry ? true : false;
        this.form.exibirz = elemento.exibirz ? true : false;
        this.form.exibirt = elemento.exibirt ? true : false;
        this.form.exibird = elemento.exibird ? true : false;
        this.form.exibirl = elemento.exibirl ? true : false;
        this.form.exibirw = elemento.exibirw ? true : false;
        this.form.exibirp = elemento.exibirp ? true : false;
        this.form.exibira = elemento.exibira ? true : false;

        this.form.calculox = elemento.calculox;
        this.form.calculoy = elemento.calculoy;
        this.form.calculoz = elemento.calculoz;
        this.form.calculot = elemento.calculot;
        this.form.calculod = elemento.calculod;
        this.form.calculol = elemento.calculol;
        this.form.calculow = elemento.calculow;
        this.form.calculop = elemento.calculop;
        this.form.calculoa = elemento.calculoa;

        this.form.lixtol = elemento.lixtol;
        this.form.liytol = elemento.liytol;
        this.form.liztol = elemento.liztol;
        this.form.littol = elemento.littol;
        this.form.lidtol = elemento.lidtol;
        this.form.liltol = elemento.liltol;
        this.form.liwtol = elemento.liwtol;
        this.form.liptol = elemento.liptol;
        this.form.liatol = elemento.liatol;

        this.form.lsxtol = elemento.lsxtol;
        this.form.lsytol = elemento.lsytol;
        this.form.lsztol = elemento.lsztol;
        this.form.lsttol = elemento.lsttol;
        this.form.lsdtol = elemento.lsdtol;
        this.form.lsltol = elemento.lsltol;
        this.form.lswtol = elemento.lswtol;
        this.form.lsptol = elemento.lsptol;
        this.form.lsatol = elemento.lsatol;
      }
    },
  },
};
</script>

<style >
</style>