<template>
  <div class="container-plano">
    <table class="table table-bordered table-condensed">
      <thead>
        <tr>
          <th scope="col" colspan="10">FRONT BUMPER - 52077330</th>
          <th scope="col" rowspan="2" class="rotate">
            <div>Risk</div>
          </th>
          <th scope="col" rowspan="2" class="rotate">
            <div>Analysis</div>
          </th>
          <th scope="col" rowspan="2" class="w-200">{{ $t('table.title.acao') }}</th>
          <th scope="col" rowspan="2">{{ $t('table.title.responsavel') }}</th>
          <th scope="col" rowspan="1" colspan="11">{{ $t('table.title.semana') }}</th>
          <th scope="col" rowspan="2">Status</th>
          <th scope="col" rowspan="2" class="remove-me" v-if="!capturing">{{ $t('table.title.acao') }}</th>
        </tr>
        <tr>
          <th scope="col">SEQ</th>
          <th scope="col">PONTO</th>
          <th scope="col">AX</th>
          <th scope="col">LIE</th>
          <th scope="col">LSE</th>
          <th scope="col">{{ $t('table.title.tipo') }}</th>
          <th scope="col">XMED</th>
          <th scope="col">{{ indicadorCp }}</th>
          <th scope="col">{{ indicadorCp }}K</th>
          <th scope="col">RANGE</th>
          <th scope="col" class="rotate wdate">
            <div>{{ $t('table.title.data-plano') }}</div>
          </th>
          <th scope="col" class="wdate" :style="formatarSemanaAtual(semana)" v-for="semana in semanas" :key="semana">
            {{ semana }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in plano" :key="item.ponto + ' ' + index">
          <td :rowspan="item.count" v-if="item.chave == 0">{{ item.seq }}</td>
          <td>{{ item.caracteristica.elemento }}</td>
          <td>{{ item.caracteristica.ax }}</td>
          <td>{{ avaliableTable(item.caracteristica)[0].lie | duasCasas }}</td>
          <td>{{ avaliableTable(item.caracteristica)[0].lse | duasCasas }}</td>
          <td>
            <img :src="'simbol/' + avaliableTable(item.caracteristica)[0].tipo.imagem | imgBase" width="24" />
            <!-- {{avaliableTable(item.caracteristicas[0])[0].tipo.descricao}} -->
          </td>
          <td :style="
            'background-color:' +
            avaliableTable(item.caracteristica)[0].medcor
          ">
            {{ avaliableTable(item.caracteristica)[0].med | duasCasas }}
          </td>
          <td :style="
            'background-color:' + avaliableTable(item.caracteristica)[0].cpcor
          ">
            {{ avaliableTable(item.caracteristica)[0].cp | duasCasas }}
          </td>
          <td :style="
            'background-color:' +
            avaliableTable(item.caracteristica)[0].cpkcor
          ">
            {{ avaliableTable(item.caracteristica)[0].cpk | duasCasas }}
          </td>
          <td>
            {{ avaliableTable(item.caracteristica)[0].range | duasCasas }}
          </td>
          <td class="rotate" :style="
            'background-color:' +
            avaliableTable(item.caracteristica)[0].riskcor
          ">
            <div>{{ avaliableTable(item.caracteristica)[0].risk }}</div>
          </td>
          <td class="rotate" :style="
            'background-color:' +
            item.analysis.cor +
            '; color:' +
            corTextoAnalysis(item.analysis.descricao)
          ">
            <div>{{ item.analysis.descricao }}</div>
          </td>
          <td :rowspan="item.count" v-if="item.chave == 0" class="w-200">
            {{ item.descricao }}
          </td>
          <td :rowspan="item.count" v-if="item.chave == 0">
            <span variant="primary" class="text-responsaveis" v-for="responsavel in item.responsaveis" :key="responsavel.user.id" >
              {{ responsavel.user.name }} {{responsavel.user.departamento ? ' ('+responsavel.user.departamento.descricao+')' : ''}}
            </span>
          </td>

          <td class="rotate" :rowspan="item.count" v-if="item.chave == 0">
            <div>{{ new Date(item.prazo).toLocaleDateString("pt-br") }}</div>
          </td>
          <template v-if="item.chave == 0">
            <th :rowspan="item.count" scope="col" v-for="semana in semanas" :key="semana"
              :style="formatarSemanaAtual(semana)">
              {{ semanaAtualX(semana, item.acoes) }}
            </th>
          </template>
          <td :rowspan="item.count" v-if="item.chave == 0">
            {{ item.status }}
          </td>
          <td :rowspan="item.count" v-if="item.chave == 0 && !capturing" class="remove-me">
            <b-button size="sm" @click="editarPlano(item.id)">{{ $t('botoes.editar') }}</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Plano",
  props: {
    plano: null,
    tabela: null,
    descricao: "",
    desenho: "",
    semanas: null,
    semana: null,
    capturing: false,
    indicador: null
  },
  data() {
    return {};
  },
  methods: {
    formatarSemanaAtual(s) {
      if (s == this.semana) {
        return "border-left: 1.2px solid grey; border-right: 1px solid grey;background-color: #DCDCDC; font-size:16px; ";
      } else {
        return "";
      }
    },
    semanaAtualX(semana, acoes) {
      var filtrarSemana = acoes.filter((acao) => acao.semana === semana)      

      if (filtrarSemana.length > 0) {
        return filtrarSemana[0].sigla;
      } else {
        return "";
      }
    },
    avaliableTable(p) {
      return this.tabela.caracteristicas
        .filter((c) => c.ponto.toUpperCase() == p.elemento.toUpperCase())
        .filter((c) => c.ax.toUpperCase() == p.ax.toUpperCase());
    },
    corTextoAnalysis(a) {
      return a == "Investigation" ? "white" : "black";
    },
    editarPlano(plano_id) {
      this.$emit("editarPlano", plano_id);
    },
  },
  computed: {
    indicadorCp() {
      return this.indicador == "cp" ? "CP" : "PP";
    },
  },
};
</script>

<style scoped>
.container-plano {
  /* overflow-y: auto;  */
}

.table-condensed {
  font-size: 12px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

tr td,
thead,
th {
  padding: 2px !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle !important;
}

.rotate {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 2em;
  height: 80px;
  font-size: 14px;
}

.wdate {
  width: 20px;
}

.rotate div {
  -moz-transform: rotate(-90deg);
  /* FF3.5+ */
  -o-transform: rotate(-90deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(-90deg);
  /* Saf3.1+, Chrome */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);
  /* IE6,IE7 */
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
  /* IE8 */
  margin-left: -10em;
  margin-right: -10em;
}

.w-200 {
  max-width: 200px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.text-responsaveis{
  font-size: medium;
  display: block;  
  margin: 2px;
}
</style>