<template>
  <div>
    <b-row align-h="start">
      <div class="p-3" v-for="item in itens" :key="item.item_fechamento.id">
        <b-card class="text-center px-1 pb-1 card-componente" no-body>
          <div class="lab__card mt-2" :style="'background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
            url + '/organization/' + user.tenant_id + '/api/storage/' +
            '/' +
            item.relatorio.componente.imagem +
            ') no-repeat center center /100%'
            "></div>
          <b-card-text class="title-card">
            {{ item.relatorio.componente.descricao }}
          </b-card-text>
          <b-card-text class="sub-title-card">
            {{ item.relatorio.componente.desenho.toString() }}
          </b-card-text>

          <b-alert show :variant="getCorStatus(item.item_fechamento.status)" class="m-2 p-0">
            <b-row class="m-0 p-1 box-odm" align-h="center" align-v="center">
              <b-col sm="3" class="m-0 p-2">
                <button>
                  <b-icon icon="circle-fill" :variant="getCorStatus(item.item_fechamento.status)" font-scale="1.5"
                    @click="showModalStatusPeca(item.item_fechamento)"></b-icon>
                </button>
              </b-col>
              <b-col sm="9">ODM: {{ item.item_fechamento.odm }}</b-col>
            </b-row>
          </b-alert>
        </b-card>
      </div>
    </b-row>
    <modal-status-peca :peca="peca_selecionada" @salvou="salvou" />
  </div>
</template>

<script>
import ModalStatusPeca from '@/components/modal/StatusPeca.vue'
export default {
  components: {
    ModalStatusPeca
  },
  props: {
    itens: [],
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  data() {
    return {
      url: null,
      peca_selecionada: null
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    salvou() {
      this.makeToast("success");
    },
    getCorStatus(status) {
      if (!status)
        return 'secondary'

      if (status == 'aprovado')
        return 'success'

      if (status == 'reprogramado')
        return 'warning'

      if (status == 'reprovado')
        return 'danger'

    },
    showModalStatusPeca(peca) {
      this.peca_selecionada = peca;
      this.$bvModal.show("modal-status-peca");
    },
    editarComponente(componente) {
      this.$emit("editarComponente", componente);
    },
  },
  mounted() {
    this.url = process.env.VUE_APP_ROOT_API;
  },
};
</script>

<style>
.lab__card {
  height: 150px;
  width: 200px;
  padding: 0px;
  margin-bottom: 5px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
}

.title-card {
  font-weight: bold;
  font-size: 16px;
}

.sub-title-card {
  font-weight: 400;
  font-size: 14px;
  color: gray;
}

.card-componente {
  width: 230px;
  height: 300px;
}

.box-odm {
  font-size: small;
  font-weight: bold;
}
</style>