<template>
  <div class="pagina">
    <b-row>
      <b-col cols="8">
        <b-row class="m-3">Semana {{ semana }}</b-row>
        <b-row class="m-3">{{ descricao }}</b-row>
        <b-row class="m-3">{{ desenho }}</b-row>
      </b-col>
      <b-col cols="4">
        <b-card class="imagem">
          <b-img center height="120" :src="imagem | urlImagem" />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input
        v-model="capa.texto1"
        class="texto-capa"
        @change="atualizarCapa"
      ></b-form-input>
    </b-row>
    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input
        v-model="capa.texto2"
        class="texto-capa"
        @change="atualizarCapa"
      ></b-form-input>
    </b-row>
    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input
        v-model="capa.texto3"
        class="texto-capa"
        @change="atualizarCapa"
      ></b-form-input>
    </b-row>
    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input
        v-model="capa.texto4"
        class="texto-capa"
        @change="atualizarCapa"
      ></b-form-input>
    </b-row>
    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input
        v-model="capa.texto5"
        class="texto-capa"
        @change="atualizarCapa"
      ></b-form-input>
    </b-row>
    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input
        v-model="capa.texto6"
        class="texto-capa"
        @change="atualizarCapa"
      ></b-form-input>
    </b-row>
    <b-row class="mx-3 my-2" align-v="center">
      <span class="circulo"></span>
      <b-form-input v-model="capa.texto7" class="texto-capa"></b-form-input>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Capa",
  props: {
    semana: null,
    descricao: null,
    desenho: null,
    imagem: null,
    capa: {
      texto1: null,
      texto2: null,
      texto3: null,
      texto4: null,
      texto5: null,
      texto6: null,
      texto7: null,
    },
  },
  data() {
    return {};
  }, 
  methods: {
    atualizarCapa() {
      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .put("api/capa/" + this.capa.id, this.capa, config)
        .then((response) => {
          console.log(response);
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
};
</script>

<style scoped>
.pagina {
  width: 1035px;
  height: 607px;
  background-color: #478ece;
  padding: 30px;
  font-size: 26px;
  color: white;
  font-weight: bold;
}

.pagina .texto-capa {
  background-color: #478ece;
  color: white;
  font-size: 18px;
  /* border-color: #478ECE; */
}
.circulo {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  margin-left: -30px;
  margin-right: 10px;
}
.imagem {
  margin-right: 15px;
}
</style>