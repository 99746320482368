<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ $t('contact.title') }}</h2>
        <p>{{ $t('contact.paragrafo-1') }}</p>

        <p class="mt-2">{{ $t('contact.paragrafo-2') }}</p>

        <p class="mt-2">{{ $t('contact.paragrafo-3') }}</p>
      </div>

      <div class="row">

        <div class="col-lg-5 d-flex align-items-stretch">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"><b-icon icon="geo-alt" aria-hidden="true"></b-icon></i>
              <h4>{{ $t('contact.localizacao') }}</h4>
              <p>Rua Cubatão | Nº: 379 | Monte Castelo | CEP: 32285-170</p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"><b-icon icon="envelope" aria-hidden="true"></b-icon></i>
              <h4>E-mail:</h4>
              <p>negocios@comarb.com.br<br>
                comarb.mb@gmail.com</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"> <b-icon icon="phone" aria-hidden="true"></b-icon> </i>
              <h4>{{ $t('contact.telefone') }}</h4>
              <p>31 99289-0133</p>
            </div>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.495674196406!2d-44.07428600000001!3d-19.9456463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa6bfdf05ce1639%3A0xd60c96ddbc35b149!2sR.%20Cubat%C3%A3o%2C%20379%20-%20Monte%20Castelo%2C%20Contagem%20-%20MG%2C%2032285-170!5e0!3m2!1spt-BR!2sbr!4v1674062856824!5m2!1spt-BR!2sbr"
              frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
          </div>

        </div>

        <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{ $t('contact.seu-nome') }}</label>
                <input type="text" name="name" class="form-control" id="name" v-model="form.name" required>
              </div>
              <div class="form-group col-md-6">
                <label for="name">{{ $t('contact.seu-email') }}</label>
                <input type="email" class="form-control" name="email" id="email" v-model="form.email" required>
              </div>
            </div>
            <div class="form-group">
              <label for="name">{{ $t('contact.assunto') }}</label>
              <input type="text" class="form-control" name="subject" id="subject" v-model="form.subject" required>
            </div>
            <div class="form-group">
              <label for="name">{{ $t('contact.mensagem') }}</label>
              <textarea class="form-control" name="message" rows="10" v-model="form.message" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading" v-if="carregando">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="button" class="buy-btn" @click="enviar">{{ $t('contact.enviar-mensagem') }}</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/contato",
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.mensagem_enviada_com_sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    limpar() {
      this.form.nome = "";
      this.form.email = "";
      this.form.subject = "";
      this.form.message = "";
      this.menssagem = null;
    },
    enviar() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, this.form, config)
        .then((response) => {
          this.makeToast("success");          
          this.carregando = false;    
          this.limpar();      
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
};
</script>

<style></style>