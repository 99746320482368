<template>
  <div class="apresentacao-componente mt-4 p-3">
    <div class="mt-3">
      <b-alert show variant="danger" v-if="menssagem" class="mt-3">
        <span
          ><b-icon icon="exclamation-octagon" aria-hidden="true"></b-icon>
          {{ menssagem }}</span
        >
      </b-alert>

      <b-card no-body v-else>
        <b-overlay>
          <b-tabs card v-model="tabIndex">
            <b-tab :title=" $t('relatorios.tabela') " active>
              <Tabela
                :tabela="resultado.tabela"
                :analysis="resultado.fechamento_analysis"
                :fechamento="resultado.fechamento"
                :descricao="resultado.componente.descricao"
                :desenho="resultado.componente.desenho"
                v-if="resultado.tabela.caracteristicas"
              />
            </b-tab>
            <b-tab :title=" $t('relatorios.capa') ">
              <capa
                ref="capa"
                v-if="resultado.capa"
                :semana="semana"
                :descricao="componente.descricao"
                :desenho="componente.desenho"
                :imagem="componente.imagem"
                :capa="resultado.capa"
              />
            </b-tab>
            <b-tab title="WorkFlow">
              <work-flow
                :resultado="resultado"
                v-if="resultado.fechamento"
                ref="workflow"               
              />
            </b-tab>
            <b-tab :title=" $t('title.conformidade') ">
              <div class="pagina-padrao">
                <Conformidade
                  ref="relatorioConformidade"
                  :semanas="resultado.cg_semanas"
                  :verde="resultado.f_geral_cg_verde"
                  :amarelo="resultado.f_geral_cg_amarelo"
                  :vermelho="resultado.f_geral_cg_vermelho"
                  calculo="CG"
                  height="600"
                />
              </div>
            </b-tab>
            <b-tab title="Cp/ Cpk">
              <div class="pagina-padrao">
                <div ref="relatorioCPCPK">
                  <Conformidade
                    ref="relatorioCPBarra"
                    :semanas="resultado.cp_semanas"
                    :verde="resultado.f_geral_cp_verde"
                    :amarelo="resultado.f_geral_cp_amarelo"
                    :vermelho="resultado.f_geral_cp_vermelho"
                    calculo="CP"
                    height="300"
                  />
                  <Conformidade
                    ref="relatorioCPKBarra"
                    :semanas="resultado.cpk_semanas"
                    :verde="resultado.f_geral_cpk_verde"
                    :amarelo="resultado.f_geral_cpk_amarelo"
                    :vermelho="resultado.f_geral_cpk_vermelho"
                    calculo="CPK"
                    height="300"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab :title=" $t('title.boleado') ">
              <div class="pagina-padrao">
                <relatorio-boleado
                  ref="relatorioBoleado"
                  v-if="resultado_boleado"
                  :relatorio_id="relatorio"
                  :paginas="resultado.paginas"
                  :resultado="resultado_boleado"
                />
              </div>
            </b-tab>
            <b-tab title="Cpk">
              <div class="pagina-padrao">
                <relatorio-cpk
                  ref="relatorioCpk"
                  v-if="relatorio"
                  :relatorio_id="relatorio"
                  :paginas="resultado.paginas"
                  :resultado="resultado"
                />
              </div>
            </b-tab>
            <b-tab :title=" $t('title.individual') ">
              <div class="pagina-padrao">
                <relatorio-individual
                  ref="relatorioIndividual"
                  v-if="resultado.individual"
                  :relatorio_id="relatorio"
                  :resultado="resultado.individual"
                  :componente="resultado.componente"
                />
              </div>
            </b-tab>
            <b-tab :title=" $t('title.plano-de-acao') ">
              <div class="pagina-padrao">
                <Plano
                  :tabela="resultado.tabela"
                  :plano="resultado.plano"
                  :descricao="resultado.componente.descricao"
                  :desenho="resultado.componente.desenho"
                  :semanas="resultado.semanas"
                  :semana="semana"
                  :capturing="capturing"
                  v-if="resultado.tabela"
                  ref="planoDeAcao"
                />
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<style scoped>
.pagina-padrao {
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import Componente from "@/components/Componentes.vue";
import Conformidade from "@/components/Conformidade.vue";

//relatorio
import RelatorioCpk from "@/views/relatorio/RelatorioCpk.vue";
import RelatorioIndividual from "@/views/relatorio/RelatorioIndividual.vue";
import RelatorioBoleado from "@/views/relatorio/RelatorioBoleado.vue";
import Tabela from "@/views/relatorio/Tabela.vue";
import Plano from "@/views/relatorio/Plano.vue";
import WorkFlow from "@/views/relatorio/WorkFlow.vue";
import Capa from "@/views/relatorio/Capa.vue";

//gerar imagens

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

export default {
  computed: {},
  components: {
    Componente,
    Conformidade,

    RelatorioCpk,
    RelatorioIndividual,
    Tabela,
    Plano,
    RelatorioBoleado,
    WorkFlow,
    Capa,
  },
  data() {
    return {
      semana: 0,
      url: null,
      img: null,
      tabIndex: 1,
      capturing: false,
      grafico: "conformidade",
      componente: {
        id: null,
        desenho: null,
        descricao: null,
        imagem: null,
        familia: {
          descricao: null,
        },
        relatorios: [],
      },
      relatorio_selecionado: null,
      relatorio: null,
      controle_individual_id: 0,
      optionsRelatorio: [],
      carregando: false,
      menssagem: null,
      resultado_boleado: null,
      resultado: {
        componente: {
          descricao: null,
          desenho: null,
        },
        paginas: [],
        semanas: [],
        tabela: {
          caracteristicas: null,
        },
      },
      limpar: {
        componente: {
          descricao: null,
          desenho: null,
        },
        paginas: [],
        semanas: [],
        tabela: {
          caracteristicas: null,
          risks: {
            quantidade: null,
            cor: null,
          },
        },
      },
      plano_selecionado: {
        id: null,
      },
      tabs: [
        { tab: 1, ref: "capa" },
        { tab: 2, ref: "workflow" },
        { tab: 3, ref: "relatorioConformidade" },
        { tab: 4, ref: "relatorioCPCPK" },
        { tab: 5, ref: "relatorioBoleado" },
        { tab: 6, ref: "relatorioCpk" },
        { tab: 7, ref: "relatorioIndividual" },
        { tab: 8, ref: "planoDeAcao" },
      ],
      screenshots: {
        capa: [],
        workflow: [],
        relatorioConformidade: [],
        relatorioCPCPK: [],
        relatorioBoleado: [],
        relatorioCpk: [],
        relatorioIndividual: [],
        planoDeAcao: [],
      },
      imprimir: {
        print_boleado: true,
        print_capa: true,
        print_conformidade: true,
        print_cpk: true,
        print_individual: true,
        print_plano: true,
        print_relatorio: true,
        print_workflow: true,
      },
      count: 0,
      quantidade_paginas_imprimir: 0,
    };
  },
  methods: {
    enviarDadosApresentacao() {
      this.capturing = false;
      this.count = 0;

      document.getElementById("app").style.backgroundColor = "";
      document.getElementById("app").style.opacity = "";

      /* var els = document.getElementsByClassName("tab-pane");

      Array.prototype.forEach.call(els, function (el, index) {
        if (index == 0) {
          el.style.display = "block";
        } else {
          el.style.display = "none";
        }
      }); */

      this.tabIndex = 0;

      var dados = {
        item_id: this.resultado.id,
        tipo: "componente",
        imagens: this.screenshots,
      };

      this.$emit("gerou", dados);
    },
    gerarApresentacao() {
      setTimeout(() => {
        this.onCapture();
        //this.atualizar();
      }, 900);
    },
    capiturando() {
      if (this.count == this.quantidade_paginas_imprimir) {
        setTimeout(() => {
          this.enviarDadosApresentacao();
        }, 300);
      }
    },
    gerandoImagem(){
      this.$emit("gerouImage");
    },
    onCapture() {
      this.capturing = true;

      /* var els = document.getElementsByClassName("tab-pane");

      Array.prototype.forEach.call(els, function (el) {
        el.style.display = "block";
      }); */

      this.quantidade_paginas_imprimir = 0;
      this.count = 0;

      this.tabs.forEach((tab, i) => {
        if (tab.tab == 1) {
          if (this.imprimir.print_capa == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 2) {
          if (this.imprimir.print_workflow == true) {
            this.quantidade_paginas_imprimir +=
              1 + this.$refs[tab.ref].paginas.length;
          }
        }

        if (tab.tab == 3) {
          if (this.imprimir.print_conformidade == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 4) {
          if (this.imprimir.print_cpk == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 5) {
          if (this.imprimir.print_boleado == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 6) {
          if (this.imprimir.print_relatorio == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 7) {
          if (this.imprimir.print_individual == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 8) {
          if (this.imprimir.print_plano == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }
      });      

      this.screenshots = {
        capa: [],
        workflow: [],
        relatorioConformidade: [],
        relatorioCPCPK: [],
        relatorioBoleado: [],
        relatorioCpk: [],
        relatorioIndividual: [],
        planoDeAcao: [],
      };

      setTimeout(() => {
        this.tabs.forEach((tab, i) => {
          if (tab.tab == 1) {
            if (this.imprimir.print_capa == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.capa.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 2) {
            if (this.imprimir.print_workflow == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$refs.pagina_workflow0;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.workflow.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });

                this.$refs[tab.ref].paginas.forEach((pg, o) => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_workflow" + (o + 1)][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.workflow.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                });
              }, 300);
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 3) {
            if (this.imprimir.print_conformidade == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.relatorioConformidade.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 4) {
            if (this.imprimir.print_cpk == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.relatorioCPCPK.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 5) {
            if (this.imprimir.print_boleado == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_boleado" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioBoleado.push({'img': img, 'ordem': u});
                      this.count++;
                      this.capiturando();
                      if((u+1) == this.$refs[tab.ref].resultado.paginas.length){
                        this.gerandoImagem()
                      }
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 6) {
            if (this.imprimir.print_relatorio == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_cpk" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioCpk.push({'img': img, 'ordem': u});
                      this.count++;
                      this.capiturando();
                      if((u+1) == this.$refs[tab.ref].resultado.paginas.length){
                        this.gerandoImagem()
                      }
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 7) {
            if (this.imprimir.print_individual == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_individual" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioIndividual.push({'img': img, 'ordem': u});
                      this.count++;
                      this.capiturando();
                      if((u+1) == this.$refs[tab.ref].resultado.paginas.length){
                        this.gerandoImagem()
                      }
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }else{
              this.gerandoImagem();
            }
          }

          if (tab.tab == 8) {
            if (this.imprimir.print_plano == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.planoDeAcao.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }else{
              this.gerandoImagem();
            }
          }
        });
      }, 900);
    },
    crt_planos() {
      var ctr = [];
      this.resultado.plano.forEach(function (item) {
        ctr.push(item.caracteristica.elemento + " - " + item.caracteristica.ax);
      });

      return ctr;
    },
    atualizar() {
      if (this.relatorio) {
        this.$refs.relatorioCpk.carregarRelatorio();
        this.$refs.relatorioBoleado.carregarRelatorio();
        this.$refs.relatorioConformidade.updateGrafico();
        this.$refs.relatorioCPBarra.updateGrafico();
        this.$refs.relatorioCPKBarra.updateGrafico();
        this.$refs.relatorioIndividual.carregarRelatorio();
      }
    },
    carregarControleBoleado() {
      this.resultado_boleado = JSON.parse(JSON.stringify(this.resultado));

      if (
        this.resultado.boleado_indicador != null &&
        this.resultado.boleado_criterio != null
      ) {
        if (this.resultado.boleado_indicador == "conformidade") {
          if (this.resultado.boleado_criterio == "vermelho") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpcor"] == "red") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else if (this.resultado.boleado_criterio == "amarelo") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpcor"] == "yellow") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else {
            this.resultado_boleado.paginas.forEach((pagina) => {
              pagina.elementos.forEach(function (item, index, object) {
                var arrayIndex = [];
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (
                      item[eixo + "cpcor"] == "yellow" ||
                      item[eixo + "cpcor"] == "red"
                    ) {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          }
        } else {
          if (this.resultado.boleado_criterio == "vermelho") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;
                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpkcor"] == "red") {
                      itens_filtro++;
                    }
                  }
                });
                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else if (this.resultado.boleado_criterio == "amarelo") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpkcor"] == "yellow") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (
                      item[eixo + "cpkcor"] == "yellow" ||
                      item[eixo + "cpkcor"] == "red"
                    ) {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          }
        }
      }

      this.resultado_boleado.paginas.forEach(function (item, index, object) {
        if (item.elementos.length == 0) {
          object.splice(index, 1);
        }
      });
    },
    carregarRelatorio(ano, semana, relatorio) {
      this.carregando = true;
      this.menssagem = null;
      this.resultado = this.limpar;

      if (relatorio != null) {
        this.$http
          .get(
            "api/relatorio/resultado/" +
              relatorio +
              "/" +
              ano +
              "/" +
              semana +
              "/" +
              this.controle_individual_id
          )
          .then((response) => {
            this.resultado = response.data;
            this.relatorio = response.data.id;
            this.componente = response.data.componente;
            this.crt_planos();
            this.carregarControleBoleado();
            this.gerarApresentacao();
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      }
    },
  },
  mounted() {
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {},
};
</script>
