<template>
  <div class="componente mt-3">
    <b-breadcrumb>
      <b-breadcrumb-item :to="'/familias'">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        {{ $t("breadcrumb.familias") }}
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="'/familia/' + componente.familia.id">{{
        componente.familia.descricao
      }}</b-breadcrumb-item>
      <b-breadcrumb-item active>{{
        $t("breadcrumb.componente")
      }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body class="px-5 py-3">
      <b-row align-v="center" align-h="between">
        <div class="card-imagem">
          <b-row align-v="center">
            <b-img center height="150" :src="componente.imagem | urlImagem" />
            <b-col>
              <h5>{{ componente.descricao }}</h5>
              <h5>
                <small class="subtitle"> {{ componente.desenho }}</small>
              </h5>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col cols="auto">
            <label>{{ $t("label.relatorio") }}</label>
            <b-form-select v-model="relatorio" :options="optionsRelatorio"></b-form-select>
          </b-col>
          <b-col cols="auto">
            <label>{{ $t("label.ano") }}</label>
            <select class="form-control" v-model="ano">
              <option>2019</option>
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
            </select>
          </b-col>
          <b-col>
            <label>{{ $t("label.semana") }}</label>
            <select class="form-control" v-model="semana">
              <option v-for="n in semanas" :key="n" :value="n">
                {{ n }}
              </option>
            </select>
          </b-col>
        </b-row>

        <div>
          <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
            <b-button-group class="mx-1" v-if="user.permissao == 'acesso_total'">
              <b-button @click="showModalCarregarControle()" :disabled="!relatorio">{{ $t("botoes.carregarControle") }}
              </b-button>
            </b-button-group>

            <b-dropdown class="mx-1" right :text="$t('botoes.relatorio')">
              <b-dropdown-item v-if="user.permissao == 'acesso_total'" @click="showModalRelatorio()">{{
                $t("botoes.novo")
              }}</b-dropdown-item>
              <b-dropdown-item v-if="user.permissao == 'acesso_total'" :to="'../../relatorio/' + relatorio"
                :disabled="!relatorio">{{ $t("botoes.editar") }}
              </b-dropdown-item>
              <b-dropdown-item v-if="user.permissao == 'acesso_total'" @click="
                showMsgConfirmation(
                  $t(
                    'confirmacao.tem-certeza-que-deseja-remover-esse-relatorio-com-todos-seus-relatorios'
                  )
                )
                " :disabled="!relatorio">{{ $t("botoes.deletar") }}</b-dropdown-item>
              <b-dropdown-item @click="showModalApresentacao()" :disabled="!relatorio">
                {{ $t("botoes.gerarApresentacao") }}
              </b-dropdown-item>
              <b-dropdown-item v-if="user.permissao == 'acesso_total'" @click="exportar()" :disabled="!relatorio">
                {{ $t("botoes.exportar") }}
              </b-dropdown-item>

            </b-dropdown>
          </b-button-toolbar>
        </div>
      </b-row>
    </b-card>

    <div class="mt-3" v-if="relatorio">
      <b-alert show variant="danger" v-if="menssagem" class="mt-3">
        <span>
          <b-icon icon="exclamation-octagon" aria-hidden="true"></b-icon>
          {{ menssagem }}
        </span>
      </b-alert>

      <b-card no-body v-else>
        <b-overlay :show="capturing" opacity="1" rounded="sm">
          <b-tabs card v-model="tabIndex">
            <b-tab :title="$t('tabs.tabela')" active @click="atualizar">
              <Tabela :tabela="resultado.tabela" :analysis="resultado.fechamento_analysis"
                :fechamento="resultado.fechamento" :descricao="resultado.componente.descricao"
                :desenho="resultado.componente.desenho" :indicador="resultado.componente.indicador"
                v-if="resultado.tabela" />
            </b-tab>
            <b-tab :title="$t('tabs.capa')" @click="atualizar">
              <capa ref="capa" v-if="resultado.capa" :semana="semana" :descricao="componente.descricao"
                :desenho="componente.desenho" :imagem="componente.imagem" :capa="resultado.capa" />
            </b-tab>
            <b-tab title="WorkFlow" @click="atualizar">
              <b-button :disabled="!relatorio" @click="showModalSelecionarComponentes()" class="mb-3" size="sm" v-if="user.permissao =='acesso_total' ">
                {{ $t("botoes.selecionar-componentes") }}
              </b-button>
              <b-button :disabled="!relatorio" @click="salvarDatas()" class="mb-3 ml-3" size="sm" v-if="user.permissao =='acesso_total' ">
                {{ $t("botoes.salvar-datas") }}
              </b-button>
              <work-flow :resultado="resultado" v-if="resultado.fechamento" ref="workflow"
                @listaMudou="carregarRelatorio()" />
            </b-tab>
            <b-tab :title="$t('tabs.conformidade')" @click="atualizar">
              <b-button @click="showModalConfigurarRelatorio('conformidade')" :disabled="!relatorio" v-if="user.permissao =='acesso_total' ">
                {{ $t("botoes.configuracoes") }}
              </b-button>
              <div class="pagina-padrao">
                <Conformidade ref="relatorioConformidade" :semanas="resultado.cg_semanas"
                  :verde="resultado.f_geral_cg_verde" :amarelo="resultado.f_geral_cg_amarelo"
                  :vermelho="resultado.f_geral_cg_vermelho" :forecast="resultado.f_geral_cg_forecast" calculo="CG"
                  height="600" />
              </div>
            </b-tab>
            <b-tab :title="componente.indicador == 'cp' ? 'Cp / Cpk' : 'Pp / Ppk'" @click="atualizar" >
              <b-button @click="showModalConfigurarRelatorio('cpk')" :disabled="!relatorio" v-if="user.permissao =='acesso_total' ">
                {{ $t("botoes.configuracoes") }}
              </b-button>
              <div class="pagina-padrao">
                <div ref="relatorioCPCPK">
                  <Conformidade ref="relatorioCPBarra" :semanas="resultado.cp_semanas" :verde="resultado.f_geral_cp_verde"
                    :amarelo="resultado.f_geral_cp_amarelo" :vermelho="resultado.f_geral_cp_vermelho"
                    :calculo="componente.indicador == 'cp' ? 'CP' : 'PP'" :forecast="resultado.f_geral_cp_forecast"
                    height="300" />
                  <Conformidade ref="relatorioCPKBarra" :semanas="resultado.cpk_semanas"
                    :verde="resultado.f_geral_cpk_verde" :amarelo="resultado.f_geral_cpk_amarelo"
                    :vermelho="resultado.f_geral_cpk_vermelho" :calculo="componente.indicador == 'cp' ? 'CPK' : 'PPK'"
                    :forecast="resultado.f_geral_cpk_forecast" height="300" />
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('tabs.boleado')" @click="atualizar">
              <b-button :disabled="!relatorio" @click="showModalCriterioBoleado()" class="mb-3" v-if="user.permissao =='acesso_total' ">
                {{ $t("botoes.criterio") }}
              </b-button>

              <relatorio-boleado ref="relatorioBoleado" v-if="resultado_boleado" :relatorio_id="relatorio"
                :paginas="resultado.paginas" :resultado="resultado_boleado" :indicador="resultado.componente.indicador" />
            </b-tab>
            <b-tab :title="componente.indicador == 'cp' ? 'Cpk' : 'Ppk'" @click="atualizar">
              <relatorio-cpk ref="relatorioCpk" v-if="relatorio" :relatorio_id="relatorio" :paginas="resultado.paginas"
                :resultado="resultado" :indicador="resultado.componente.indicador" />
            </b-tab>
            <b-tab :title="$t('tabs.individual')" @click="atualizar">
              <b-button :disabled="!relatorio" @click="showModalControleIndividual()" class="mb-3">
                {{ $t("botoes.selecionar-controle") }}
              </b-button>

              <relatorio-individual ref="relatorioIndividual" v-if="resultado.individual" :relatorio_id="relatorio"
                :resultado="resultado.individual" :componente="resultado.componente"
                :indicador="resultado.componente.indicador" />
            </b-tab>
            <b-tab :title="$t('tabs.plano-de-acao')" @click="atualizar">
              <b-button :disabled="!relatorio" @click="showModalPlanoDeAcao()" class="mb-3" v-if="user.permissao !='visualizar' ">
                {{ $t("botoes.novo-plano-de-acao") }}
              </b-button>

              <b-button :disabled="!relatorio" @click="enviarPlanoDeAcao()" class="mb-3 ml-2" v-if="user.permissao =='acesso_total' ">
                {{ $t("botoes.enviar-para-os-responsaveis") }}
              </b-button>
              <div class="pagina-padrao">
                <Plano :tabela="resultado.tabela" :plano="resultado.plano" :descricao="resultado.componente.descricao"
                  :desenho="resultado.componente.desenho" :semanas="resultado.semanas" :semana="semana"
                  :indicador="resultado.componente.indicador" :capturing="capturing" v-if="resultado.tabela"
                  @editarPlano="editarPlano" ref="planoDeAcao" />
              </div>
            </b-tab>
            <b-tab :title="$t('tabs.risk')">
              <div class="pagina-padrao">
                <risk-assesment ref="risk" v-if="resultado" :analysis="resultado.fechamento_analysis"
                  :analysis_type="resultado.fechamento_analysis_type" :risks="resultado.tabela.risks"
                  :componente="componente" />
              </div>
            </b-tab>
            <b-tab :title="$t('tabs.grafico-tendencia')">
              <grafico-trend :trend="resultado.trend" v-if="resultado.trend" />
            </b-tab>
            <b-tab :title="$t('tabs.gaus')">
              <gauss :trend="resultado.trend" v-if="resultado.trend" />
            </b-tab>
          </b-tabs>
        </b-overlay>
      </b-card>
    </div>
    <modal-componente :familia="componente.familia" :componente="componente" @listaMudou="carregarComponente()" />
    <modal-relatorio :componente="componente" @listaMudou="carregarComponente()" />

    <modal-carregar-controle v-if="relatorio && fechamento == 1" :relatorio="relatorio"
      @controlesMudou="carregarRelatorio()" />
    <modal-carregar-controle-manual v-if="relatorio && fechamento == 2" :relatorio="relatorio"
      @controlesMudou="carregarRelatorio()" />

    <modal-controle-individual v-if="relatorio" :relatorio="relatorio" @controle_id="setarControleIndividual" />
    <modal-configurar-relatorio v-if="relatorio" :relatorio="relatorio" :grafico="grafico"
      @configMudou="carregarRelatorio()" />
    <modal-plano-de-acao v-if="resultado.tabela.caracteristicas" :caracteristicas="resultado.tabela.caracteristicas"
      :relatorio_id="relatorio" :plano="plano_selecionado" :indicador="resultado.componente.indicador"
      :planos="crt_planos()" @listaMudou="carregarRelatorio()" />
    <modal-work-flow v-if="resultado.workflow" :workflow="resultado.workflow" :relatorio_id="relatorio"
      @listaMudou="carregarRelatorio()" />
    <modal-criterio-boleado v-if="relatorio_selecionado" :relatorio="relatorio_selecionado[0]"
      @listaMudou="carregarRelatorio()" :indicador="resultado.componente.indicador" />
    <modal-carregando :count="count" :tabs="quantidade_paginas_imprimir" />
    <modal-apresentacao @gerarApresentacao="gerarApresentacao" />
    <modal-carregando-geral />
    <!-- <modal-trend v-if="resultado.tabela.caracteristicas" :caracteristicas="resultado.tabela.caracteristicas"
      :relatorio_id="relatorio"/> -->
  </div>
</template>

<style scoped>
table.comBordaComplexa {
  text-align: center;
  font-weight: bold;
  border-collapse: collapse;
  /* CSS2 */
  background: #fffff0;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}

.subtitle {
  font-weight: 500;
  color: gray;
}

.overlay {
  position: absolute !important;
  width: 100%;
  height: 100%;
  text-align: center;
}

.modal-backdrop {
  opacity: 1 !important;
  background-color: #e4e4e4 !important;
}

.pagina-padrao {
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card-imagem {
  height: 150px;
}
</style>

<script>
import Componente from "@/components/Componentes.vue";
import Conformidade from "@/components/Conformidade.vue";
import GraficoTrend from "@/components/GraficoTrend.vue";
import RiskAssesment from "@/components/RiskAssesment.vue";
import Gauss from "@/components/Gauss.vue";

//Modal
import ModalComponente from "@/components/modal/Componente.vue";
import ModalRelatorio from "@/components/modal/Relatorio.vue";
import ModalPlanoDeAcao from "@/components/modal/PlanoDeAcao.vue";
import ModalCarregarControle from "@/components/modal/CarregarControle.vue";
import ModalCarregarControleManual from "@/components/modal/CarregarControleManual.vue";
import ModalControleIndividual from "@/components/modal/ControleIndividual.vue";
import ModalConfigurarRelatorio from "@/components/modal/ConfiguracaoRelatorio.vue";
import ModalCriterioBoleado from "@/components/modal/CriterioBoleado.vue";
import ModalWorkFlow from "@/components/modal/WorkFlow.vue";
import ModalCarregando from "@/components/modal/Carregando.vue";
import ModalApresentacao from "@/components/modal/Apresentacao.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import ModalTrend from "@/components/modal/Trend.vue";

//relatorio
import RelatorioCpk from "@/views/relatorio/RelatorioCpk.vue";
import RelatorioIndividual from "@/views/relatorio/RelatorioIndividual.vue";
import RelatorioBoleado from "@/views/relatorio/RelatorioBoleado.vue";
import Tabela from "@/views/relatorio/Tabela.vue";
import Plano from "@/views/relatorio/Plano.vue";
import WorkFlow from "@/views/relatorio/WorkFlow.vue";
import Capa from "@/views/relatorio/Capa.vue";

//gerar imagens

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

export default {
  name: "Componente",
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    fechamento: {
      get() {
        return this.$store.state.fechamento;
      },
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    semanas: {
      get() {
        return this.$store.state.semanas;
      },
      set(value) {
        this.$store.commit("setSemanas", value);
      },
    },
  },
  components: {
    Componente,
    Conformidade,
    GraficoTrend,
    RiskAssesment,
    Gauss,

    RelatorioCpk,
    RelatorioIndividual,
    Tabela,
    Plano,
    RelatorioBoleado,
    WorkFlow,
    Capa,

    ModalComponente,
    ModalRelatorio,
    ModalCarregarControle,
    ModalApresentacao,
    ModalConfigurarRelatorio,
    ModalPlanoDeAcao,
    ModalControleIndividual,
    ModalCriterioBoleado,
    ModalWorkFlow,
    ModalCarregando,
    ModalCarregandoGeral,
    ModalCarregarControleManual,
    ModalTrend,
  },
  data() {
    return {
      url: null,
      img: null,
      tabIndex: 1,
      capturing: false,
      grafico: "conformidade",
      componente: {
        id: null,
        desenho: null,
        descricao: null,
        imagem: null,
        familia: {
          descricao: null,
        },
        relatorios: [],
      },
      relatorio_selecionado: null,
      relatorio: null,
      controle_individual_id: 0,
      optionsRelatorio: [],
      carregando: false,
      menssagem: null,
      resultado_boleado: null,
      resultado: {
        componente: {
          descricao: null,
          desenho: null,
        },
        paginas: [],
        semanas: [],
        tabela: {
          caracteristicas: null,
        },
      },
      limpar: {
        componente: {
          descricao: null,
          desenho: null,
        },
        paginas: [],
        semanas: [],
        tabela: {
          caracteristicas: null,
          risks: {
            quantidade: null,
            cor: null,
          },
        },
      },
      plano_selecionado: {
        id: null,
      },
      tabs: [
        { tab: 1, ref: "capa" },
        { tab: 2, ref: "workflow" },
        { tab: 3, ref: "relatorioConformidade" },
        { tab: 4, ref: "relatorioCPCPK" },
        { tab: 5, ref: "relatorioBoleado" },
        { tab: 6, ref: "relatorioCpk" },
        { tab: 7, ref: "relatorioIndividual" },
        { tab: 8, ref: "planoDeAcao" },
        { tab: 9, ref: "risk" },
      ],
      screenshots: {
        capa: [],
        workflow: [],
        relatorioConformidade: [],
        relatorioCPCPK: [],
        relatorioBoleado: [],
        relatorioCpk: [],
        relatorioIndividual: [],
        planoDeAcao: [],
        risk: [],
      },
      imprimir: null,
      count: 0,
      quantidade_paginas_imprimir: 0,
      imagemPlanoAcao: null,
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    enviarPlanoDeAcao() {
      this.$bvModal.show("modal-carregando-geral");
      setTimeout(() => {
        const capture = this.$refs["planoDeAcao"].$el;
        htmlToImage
          .toPng(capture)
          .then((dataUrl) => {
            var img = new Image();
            img.src = dataUrl;
            this.imagemPlanoAcao = img;
            this.enviarPlano();
          })
          .catch((error) => {
            console.error("oops, something went wrong!", error);
          });
      }, 300);
    },
    enviarPlano() {
      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio);
      formData.append("imagem", this.imagemPlanoAcao.src);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/plano/enviar-para-responsaveis", formData, config)
        .then((response) => {
          this.makeToast("success");
          this.capturing = false;
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          if (errors.response) {
            this.menssagem = errors.response.data;
          }
          this.carregando = false;
          this.capturing = false;

          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    enviarDadosApresentacao() {
      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio);
      formData.append("semana", this.semana);
      formData.append("ano", this.ano);
      formData.append("tipo", "relatorio");

      if (this.screenshots.capa) {
        this.screenshots.capa.forEach((element) => {
          formData.append("capa[]", element.src);
        });
      }
      if (this.screenshots.workflow) {
        this.screenshots.workflow.forEach((element) => {
          formData.append("workflow[]", element.src);
        });
      }
      if (this.screenshots.relatorioConformidade) {
        this.screenshots.relatorioConformidade.forEach((element) => {
          formData.append("relatorioConformidade[]", element.src);
        });
      }
      if (this.screenshots.relatorioCPCPK) {
        this.screenshots.relatorioCPCPK.forEach((element) => {
          formData.append("relatorioCPCPK[]", element.src);
        });
      }
      if (this.screenshots.relatorioBoleado) {
        this.screenshots.relatorioBoleado.forEach((element) => {
          formData.append("relatorioBoleado[]", element.src);
        });
      }
      if (this.screenshots.relatorioCpk) {
        this.screenshots.relatorioCpk.forEach((element) => {
          formData.append("relatorioCpk[]", element.src);
        });
      }
      if (this.screenshots.relatorioIndividual) {
        this.screenshots.relatorioIndividual.forEach((element) => {
          formData.append("relatorioIndividual[]", element.src);
        });
      }
      if (this.screenshots.planoDeAcao) {
        this.screenshots.planoDeAcao.forEach((element) => {
          formData.append("planoDeAcao[]", element.src);
        });
      }
      if (this.screenshots.risk) {
        this.screenshots.risk.forEach((element) => {
          formData.append("risk[]", element.src);
        });
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        maxContentLength: 1000000000000,
        maxBodyLength: 10000000000000,
      };

      this.$http
        .post("api/apresentacao", formData, config)
        .then((response) => {
          var path =
            this.url + "/" + response.data.arquivo;
          var nome = response.data.arquivo;

          this.download(path, nome);

          setTimeout(() => {
            this.makeToast("success");
            this.capturing = false;
            this.count = 0;
            document.getElementById("app").style.backgroundColor = "";
            document.getElementById("app").style.opacity = "";

            var els = document.getElementsByClassName("tab-pane");

            Array.prototype.forEach.call(els, function (el, index) {
              if (index == 0) {
                el.style.display = "block";
              } else {
                el.style.display = "none";
              }
            });

            this.tabIndex = 0;

            this.$bvModal.hide("modal-carregando");
          }, 300);
        })
        .catch((errors) => {
          if (errors.response) {
            this.menssagem = errors.response.data;
          }

          this.carregando = false;
          this.capturing = false;
          this.count = 0;
          document.getElementById("app").style.backgroundColor = "";
          document.getElementById("app").style.opacity = "";

          var els = document.getElementsByClassName("tab-pane");

          Array.prototype.forEach.call(els, function (el, index) {
            if (index == 0) {
              el.style.display = "block";
            } else {
              el.style.display = "none";
            }
          });

          this.tabIndex = 0;
          this.$bvModal.hide("modal-carregando");
        });
    },
    download(file, nome) {
      window.location.href = file;
    },
    gerarApresentacao(imp) {
      this.imprimir = imp;
      this.onCapture();
    },
    capiturando() {
      if (this.count == this.quantidade_paginas_imprimir) {
        setTimeout(() => {
          this.enviarDadosApresentacao();
        }, 300);
      }
    },
    onCapture() {
      this.capturing = true;

      document.getElementById("app").style.backgroundColor = "#e4e4e4";
      document.getElementById("app").style.opacity = 0;

      var els = document.getElementsByClassName("tab-pane");

      Array.prototype.forEach.call(els, function (el) {
        el.style.display = "block";
      });

      this.quantidade_paginas_imprimir = 0;
      this.count = 0;

      this.tabs.forEach((tab, i) => {
        if (tab.tab == 1) {
          if (this.imprimir.print_capa == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 2) {
          if (this.imprimir.print_workflow == true) {
            this.quantidade_paginas_imprimir +=
              1 + this.$refs[tab.ref].paginas.length;
          }
        }

        if (tab.tab == 3) {
          if (this.imprimir.print_conformidade == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 4) {
          if (this.imprimir.print_cpk == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 5) {
          if (this.imprimir.print_boleado == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 6) {
          if (this.imprimir.print_relatorio == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 7) {
          if (this.imprimir.print_individual == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 8) {
          if (this.imprimir.print_plano == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 9) {
          if (this.imprimir.print_risk == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }
      });

      this.showModalCarregando();
      this.atualizar();
      this.screenshots = {
        capa: [],
        workflow: [],
        relatorioConformidade: [],
        relatorioCPCPK: [],
        relatorioBoleado: [],
        relatorioCpk: [],
        relatorioIndividual: [],
        planoDeAcao: [],
        risk: [],
      };

      setTimeout(() => {
        this.tabs.forEach((tab, i) => {
          //this.tabIndex = tab.tab;

          if (tab.tab == 1) {
            if (this.imprimir.print_capa == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.capa.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 2) {
            if (this.imprimir.print_workflow == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$refs.pagina_workflow0;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.workflow.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });

                this.$refs[tab.ref].paginas.forEach((pg, o) => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_workflow" + (o + 1)][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.workflow.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                });
              }, 300);
            }
          }

          if (tab.tab == 3) {
            if (this.imprimir.print_conformidade == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.relatorioConformidade.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 4) {
            if (this.imprimir.print_cpk == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.relatorioCPCPK.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 5) {
            if (this.imprimir.print_boleado == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_boleado" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioBoleado.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }
          }

          if (tab.tab == 6) {
            if (this.imprimir.print_relatorio == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_cpk" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioCpk.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }
          }

          if (tab.tab == 7) {
            if (this.imprimir.print_individual == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_individual" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioIndividual.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }
          }

          if (tab.tab == 8) {
            if (this.imprimir.print_plano == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.planoDeAcao.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 9) {
            if (this.imprimir.print_risk == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.risk.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }
        });
      }, 300);
    },
    crt_planos() {
      var ctr = [];
      this.resultado.plano.forEach(function (item) {
        ctr.push(item.caracteristica.elemento + " - " + item.caracteristica.ax);
      });

      return ctr;
    },
    selecionar_relatorio() {
      this.relatorio_selecionado = this.componente.relatorios.filter(
        (rl) => rl.id == this.relatorio
      );
    },
    atualizar() {
      if (this.relatorio) {
        this.$refs.relatorioCpk.carregarRelatorio();
        this.$refs.relatorioBoleado.carregarRelatorio();
        this.$refs.relatorioConformidade.updateGrafico();
        this.$refs.relatorioCPBarra.updateGrafico();
        this.$refs.relatorioCPKBarra.updateGrafico();
        this.$refs.relatorioIndividual.carregarRelatorio();
      }
    },
    setarControleIndividual(controle_id) {
      this.controle_individual_id = controle_id;
      this.carregarRelatorio();
    },
    editarPlano(e) {
      this.plano_selecionado = this.resultado.planos.filter(
        (p) => p.id == e
      )[0];
      this.$bvModal.show("modal-plano-de-acao");
    },
    salvarDatas() {
      this.$refs.workflow.atualizarData();
    },
    showModalSelecionarComponentes() {
      this.$bvModal.show("modal-work-flow");
    },
    showModalCriterioBoleado() {
      this.$bvModal.show("modal-criterio");
    },
    showModalControleIndividual() {
      this.$bvModal.show("modal-controle-individual");
    },
    showModalCarregarControle() {
      this.$bvModal.show("modal-controle");
    },
    showModalComponente() {
      this.$bvModal.show("modal-componente");
    },
    showModalRelatorio() {
      this.$bvModal.show("modal-relatorio");
    },
    showModalConfigurarRelatorio(tipoGrafico) {
      this.grafico = tipoGrafico;
      this.$bvModal.show("modal-config-relatorio");
    },
    showModalPlanoDeAcao() {
      this.plano_selecionado = { id: null };
      this.$bvModal.show("modal-plano-de-acao");
    },
    showModalCarregando() {
      this.$bvModal.show("modal-carregando");
    },
    showModalApresentacao() {
      this.$bvModal.show("modal-apresentacao");
    },
    showModalTrend() {
      this.$bvModal.show("modal-trend");
    },
    carregarComponente() {
      this.$http
        .get("api/componente/" + this.$route.params.id)
        .then((response) => {
          this.componente = response.data;
          this.optionsRelatorio = [];
          response.data.relatorios.forEach((element) => {
            this.optionsRelatorio.push({
              value: element.id,
              text: element.descricao,
            });
          });

          if (this.$route.params.relatorio) {
            this.relatorio = this.$route.params.relatorio;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async carregarControleBoleado() {
      this.resultado_boleado = JSON.parse(JSON.stringify(this.resultado));

      if (
        this.relatorio_selecionado[0].boleado_indicador != null &&
        this.relatorio_selecionado[0].boleado_criterio != null
      ) {
        if (this.relatorio_selecionado[0].boleado_indicador == "conformidade") {
          if (this.relatorio_selecionado[0].boleado_criterio == "vermelho") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "medcor"] == "red") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else if (
            this.relatorio_selecionado[0].boleado_criterio == "amarelo"
          ) {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "medcor"] == "yellow") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else {
            this.resultado_boleado.paginas.forEach((pagina) => {
              pagina.elementos.forEach(function (item, index, object) {
                var arrayIndex = [];
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (
                      item[eixo + "medcor"] == "yellow" ||
                      item[eixo + "medcor"] == "red"
                    ) {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          }
        } else if (this.relatorio_selecionado[0].boleado_indicador == "cpk") {
          if (this.relatorio_selecionado[0].boleado_criterio == "vermelho") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;
                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpkcor"] == "red") {
                      itens_filtro++;
                    }
                  }
                });
                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else if (
            this.relatorio_selecionado[0].boleado_criterio == "amarelo"
          ) {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpkcor"] == "yellow") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (
                      item[eixo + "cpkcor"] == "yellow" ||
                      item[eixo + "cpkcor"] == "red"
                    ) {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          }
        } else {
          if (this.relatorio_selecionado[0].boleado_criterio == "vermelho") {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpcor"] == "red") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else if (
            this.relatorio_selecionado[0].boleado_criterio == "amarelo"
          ) {
            this.resultado_boleado.paginas.forEach((pagina) => {
              var arrayIndex = [];
              pagina.elementos.forEach(function (item, index, object) {
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (item[eixo + "cpcor"] == "yellow") {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          } else {
            this.resultado_boleado.paginas.forEach((pagina) => {
              pagina.elementos.forEach(function (item, index, object) {
                var arrayIndex = [];
                var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                var itens_filtro = 0;

                eixos.forEach((eixo) => {
                  if (item["exibir" + eixo] == 1) {
                    if (
                      item[eixo + "cpcor"] == "yellow" ||
                      item[eixo + "cpcor"] == "red"
                    ) {
                      itens_filtro++;
                    }
                  }
                });

                if (itens_filtro == 0) {
                  arrayIndex.push(item);
                }
              });
              arrayIndex.forEach(function (item) {
                pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
              });
            });
          }
        }
      }

      this.resultado_boleado.paginas.forEach(function (item, index, object) {
        if (item.elementos.length == 0) {
          object.splice(index, 1);
        }
      });
    },
    carregarRelatorio() {
      this.carregando = true;
      this.menssagem = null;
      this.resultado = this.limpar;

      if (this.relatorio != null) {
        this.$bvModal.show("modal-carregando-geral");
        this.$http
          .get(
            "api/relatorio/resultado/" +
            this.relatorio +
            "/" +
            this.ano +
            "/" +
            this.semana +
            "/" +
            this.controle_individual_id
          )
          .then((response) => {
            this.resultado = response.data;
            this.crt_planos();
            this.selecionar_relatorio();
            this.carregarControleBoleado();
            this.$bvModal.hide("modal-carregando-geral");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          });
      }
    },
    deletar() {
      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/relatorio/" + this.relatorio, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.carregando = false;
          this.carregarComponente();
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    showMsgConfirmation(msg) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: this.$t("title.confirme-por-favor"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("confirmacao.sim"),
          cancelTitle: this.$t("confirmacao.nao"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportar() {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/zip",
        },
      };
      this.$http
        .get("api/relatorio/exportar/" + this.relatorio, config)
        .then((response) => {
          console.log(response.data)

          var path =
            this.url + "/" + response.data.arquivo;
          var nome = response.data.arquivo;
          this.download(path, nome);

          this.makeToast("success");
          this.carregando = false;
          this.carregarComponente();
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarComponente();
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    relatorio: function () {
      this.carregarRelatorio();
    },
    ano: function () {
      this.carregarRelatorio();
    },
    semana: function () {
      this.carregarRelatorio();
    },
  },
};
</script>
