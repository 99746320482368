<template>
  <div class="lang-dropdown">
    <b-row align-v="center" align-h="center">

      <b-col cols="auto" v-if="!showMobileMenu">
        <b-icon icon="globe" style="color:white; padding-left: 1rem;"></b-icon>
      </b-col>
      <b-col :class="showMobileMenu ? 'mx-3' : ''">
        <b-form-select size="sm" :options="linguagens" v-model="$i18n.locale"></b-form-select>
      </b-col>

    </b-row>

  </div>
</template>  
<script>
export default {
  props:{
    showMobileMenu:false
  },
  computed: {
    linguagens() {
      return [
        { value: "pt-BR", text: this.$t('site-idiomas.portugues-bra') },
        { value: "en", text: this.$t('site-idiomas.ingles-eua-gbr') },
        { value: "it", text: this.$t('site-idiomas.italiano-ita') },
        { value: "es", text: this.$t('site-idiomas.espanhol-esp-arg') },
      ]
    },
  }
}  
</script>