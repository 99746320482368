<template>
  <div class="container-tabela">
    <b-row class="container-indicadores">
      <b-col cols="6">
        <box-indicadores
          :fechamento="fechamento"
          :descricao="descricao"
          :desenho="desenho"
          :indicador="indicador"
          v-if="descricao"
        />
      </b-col>
      <b-col cols="2" class="p-0">
        <root-cause-bar 
        v-if="analysis"
        :analysis="analysis"/>
      </b-col>
      <b-col cols="4">
        <b-card no-body class="container-risk">
          <risk-bar 
          v-if="tabela.risks.quantidade"
          :risks=" tabela.risks.quantidade"
          :cores=" tabela.risks.cor"
          :categorias="tabela.risks.label"
           />
          <risk-legenda />
        </b-card>
      </b-col>
    </b-row>
    <table class="table table-bordered table-condensed">
      <thead>
        <tr>
          <th scope="col">Ponto</th>
          <th scope="col">AX</th>
          <th scope="col">CALC</th>
          <th scope="col">LIE</th>
          <th scope="col">LSE</th>
          <th scope="col">XM</th>
          <th scope="col">RG</th>
          <th scope="col">SIGMA</th>
          <th scope="col">{{indicador == 'cp' ? 'CP' : 'PP'}}</th>
          <th scope="col">{{indicador == 'cp' ? 'CPK' : 'PPK'}}</th>
          <th scope="col">RISK</th>
          <th scope="col">N</th>
          <th
            scope="col"
            v-for="(data, index) in tabela.datas"
            :key="data + index"
          >
            {{ data | data }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tabela.caracteristicas"
          :key="item.ponto + index"
        >
          <td>{{ item.ponto }}</td>
          <td>{{ item.ax.toUpperCase() }}</td>
          <td>{{ item.calc }}</td>
          <td>{{ item.lie | duasCasas }}</td>
          <td>{{ item.lse | duasCasas }}</td>
          <td :style="'background-color:' + item.medcor">
            {{ item.med | duasCasas }}
          </td>
          <td>{{ item.range | duasCasas }}</td>
          <td>{{ item.sigma | duasCasas }}</td>
          <td :style="'background-color:' + item.cpcor">
            {{ item.cp | duasCasas }}
          </td>
          <td :style="'background-color:' + item.cpkcor">
            {{ item.cpk | duasCasas }}
          </td>
          <td :style="'background-color:' + item.riskcor">{{ item.risk }}</td>
          <td>{{ item.desvios.length }}</td>
          <td v-for="(desvios, index) in item.desvios" :key="desvios + index">
            {{ desvios | duasCasas }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RiskLegenda from "@/components/RiskLegenda.vue";
import RiskBar from "@/components/RiskBar.vue";
import BoxIndicadores from "@/components/BoxIndicadores.vue";
import RootCauseBar from "@/components/RootCauseBar.vue";

export default {
  name: "Tabela",
  components: {
    RiskLegenda,
    RiskBar,
    BoxIndicadores,
    RootCauseBar,
  },
  props: {
    analysis:null,
    tabela: {
      risks:{
        quantidade:null,
        cor:null
      }
    },
    fechamento: null,
    descricao: "",
    desenho: "",
    indicador: null
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.container-tabela {
  overflow-y: auto;
}

.table-condensed {
  font-size: 12px;
  padding: 0;
  text-align: center;
}
tr td,
th {
  padding: 2px !important;
}
.container-indicadores {
  height: 140px;
}
.container-risk {
  height: 120px;
}
</style>