<template>
  <div class="bg-gradient-success">
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9 pt-5">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white"></h1>
              <p class="text-lead text-white"></p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt-8 pb-5 container-login">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <!-- <b-row class="mb-3">
            <b-col>
              <router-link to="/site" class="text-light"><b-icon icon="chevron-left" aria-hidden="true"></b-icon> {{
                $t('login.ir-para-site') }}</router-link>
            </b-col>
          </b-row> -->
          <b-card no-body class="border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <b-row class="justify-content-center">
                <img src="../assets/logo.png" alt="Logo" height="40" />
              </b-row>

              <div class="text-center text-muted mb-4">
                <small>{{ $t('login.entre-com-suas-crendenciais') }}</small>
              </div>

              <b-form role="form" @submit="onSubmit">
                <b-form-group id="fieldset-1" :label="$t('login.entre-com-seu-e-mail')" label-for="input-email"
                  valid-feedback="Thank you!">
                  <b-form-input id="input-email" v-model="user.email" type="email"></b-form-input>
                </b-form-group>

                <b-form-group :label=" $t('label.organizacao') " v-if="emailValidado && organizacoes.length > 0">
                  <b-form-select :options="organizacoes" v-model="user.tenant_id" required></b-form-select>
                </b-form-group>

                <b-form-group id="fieldset-1" :label="$t('login.entre-com-sua-senha')" label-for="input-senha"
                  valid-feedback="Thank you!" v-if="emailValidado">
                  <b-form-input id="input-senha" v-model="user.password" type="password"></b-form-input>
                </b-form-group>

                <b-form-group id="fieldset-1" :label="$t('login.entre-com-codigo')" label-for="input-codigo"
                  valid-feedback="Thank you!" v-if="emailValidado">
                  <b-form-input id="input-codigo" v-model="user.two_factor_code" type="number"></b-form-input>
                </b-form-group>                

                <b-form-checkbox v-model="model.rememberMe" v-if="emailValidado">{{ $t('login.manter-conectado') }}</b-form-checkbox>
                <div class="text-center">
                  <b-button block variant="primary" v-if="carregando" disabled class="my-4">
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }}
                  </b-button>
                  <b-button block v-else-if="emailValidado" type="primary" variant="primary" native-type="submit" class="my-4">{{
                    $t('botoes.entrar')
                  }}
                  </b-button>
                  <b-button block v-else type="primary" variant="primary" native-type="button" class="my-4" @click="validarEmail()">{{
                    $t('botoes.entrar')
                  }}
                  </b-button>
                </div>
              </b-form>

              <b-alert show variant="danger" v-if="menssagem">  
                
                  <template v-if="Array.isArray(menssagem.erro)">
                  <p v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</p>   
                  </template>   
                  
                  <template v-else>{{ menssagem.erro }}</template>
              </b-alert>

              <!-- <b-alert show variant="danger" v-if="menssagem">
                {{ menssagem }}
              </b-alert> -->

              <!-- <b-row>
                <b-col>
                  {{ $t('login.novo-em-nossa-plataforma') }} <router-link to="/register">{{
                    $t('login.crie-a-sua-conta-aqui') }}</router-link>
                </b-col>
              </b-row> -->
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/forgot-password" class="text-light">{{ $t('login.esqueceu-a-senha') }}
              </router-link>
            </b-col>
            <b-col cols="6">
              <b-row align-v="center" align-h="center">
                <b-col cols="auto">
                  <b-icon icon="globe" style="color:white; padding-left: 1rem;"></b-icon>
                </b-col>
                <b-col>
                  <b-form-select size="sm" :options="linguagens" v-model="$i18n.locale"></b-form-select>
                </b-col>

              </b-row>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import User from '../models/user';
export default {
  data() {
    return {
      user: new User('', ''),
      model: {
        email: "",
        password: "",
        rememberMe: false,
        two_factor_code: "",
      },
      emailValidado: false,
      organizacoes: [],
      menssagem: null,
      carregando: null,
    };
  },
  computed: {
    linguagens() {
      return [
        { value: "pt-BR", text: this.$t('idiomas.portugues-bra') },
        { value: "en", text: this.$t('idiomas.ingles-eua-gbr') },
        { value: "it", text: this.$t('idiomas.italiano-ita') },
        { value: "es", text: this.$t('idiomas.espanhol-esp-arg') },
      ]
    },
    mensagemErro() {
      return this.$t('login.usuario-ou-senha-invalido')
    },
    mensagemCamposVazios() {
      return this.$t('login.todos-os-campos-devem-ser-preechidos')
    }
  },
  methods: {
    validarEmail(event) {
      this.carregando = true;
      this.emailValidado = false;

      this.$http
        .post("api/verificaEmail", {email : this.user.email})
        .then((response) => {
          this.organizacoes = [];
          if (response.data) {
            response.data.forEach((element) => {
              this.organizacoes.push({
                value: element.tenant_id,
                text: element.descricao,
              });
            });

            this.user.tenant_id = this.organizacoes[0].value;

            this.emailValidado = true;
          }

          this.carregando = false;

        })
        .catch((errors) => {
          console.log(errors);
        });

    },
    onSubmit(event) {
      this.menssagem = null
      event.preventDefault();

      if (!this.user.email && !this.user.password) {
        this.menssagem = this.mensagemCamposVazios;
      }

      if (this.user.email && this.user.password) {
        this.carregando = true
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            this.$router.push('/familias');
          },
          error => {
            console.log(error);
            this.menssagem = error.response.data;           
            this.carregando = false;
          }
        );
      }
    }
  },
};
</script>
<style scoped>
.bg-gradient-success {
  background: rgb(31, 23, 77);
  background: linear-gradient(90deg,
      rgba(31, 23, 77, 1) 0%,
      rgba(9, 68, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  min-width: 100vh;
}

.container-login {
  margin-top: 100px;
}
</style>
