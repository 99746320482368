<template>
  <b-modal id="modal-trend-grupo" size="lg" :title="$t('title.configuracao-grupo')" centered>
    <template #modal-header>
      <h5>{{ novo ? $t('title.novo-grupo'): $t('title.configuracao-grupo') }}</h5>
      <p class="h4 btn m-0 p-0">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>
    <b-form v-if="!novo">

      <b-form-group id="input-group-1" :label="$t('label.description')" label-for="input-1">
        <b-form-input id="input-1" v-model="grupo[0].description" type="text"
          :placeholder="$t('placeholder.entre-com-a-descricao')" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.title-higher')" label-for="input-1">
        <b-form-input id="input-1" v-model="grupo[0].title" type="text" :placeholder="$t('placeholder.entre-com-o-titulo')"
          required></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('label.formato-impressao')">
        <b-form-radio-group size="sm" v-model="grupo[0].format" :options="optionsFormatos"
          name="radio-options-conversor"></b-form-radio-group>
      </b-form-group>

    </b-form>

    <b-form v-if="novo">

      <b-form-group id="input-group-1" :label="$t('label.description')" label-for="input-1">
        <b-form-input id="input-1" v-model="form.description" type="text"
          :placeholder="$t('placeholder.entre-com-a-descricao')" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.title-higher')" label-for="input-1">
        <b-form-input id="input-1" v-model="form.title" type="text" :placeholder="$t('placeholder.entre-com-o-titulo')"
          required></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('label.formato-impressao')">
        <b-form-radio-group size="sm" v-model="form.format" :options="optionsFormatos"
          name="radio-options-conversor"></b-form-radio-group>
      </b-form-group>

    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-2">
      {{ menssagem }}
    </b-alert>

    <template #modal-footer>
      <b-button type="submit" variant="secondary" class="float-right mr-2" @click="close()">
        {{ $t('botoes.fechar') }} </b-button>

      <b-button variant="primary" class="float-left" v-if="carregando" disabled>
        <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
        {{ $t('botoes.carregando') }}
      </b-button>

      <b-button v-else type="submit" variant="success" class="float-left" @click="salvar()">
        {{ novo ? $t('botoes.cadastrar') : $t('botoes.salvar') }} </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    grupos:null,
    grupo: null,
    novo: false,
  },
  data() {
    return {
      show: true,
      menssagem: null,
      carregando: null,
      form: {
        title: null,
        description: null,
        format: 1,
      },
      optionsFormatos: [
        { text: "PDF", value: 1 },
        { text: "JPG", value: 2 }
      ],
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-trend-grupo");
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    makeToastErro(variant = null) {
      this.$bvToast.toast(this.$t('toast.nenhum-controle-selecionado'), {
        title: this.$t('title.erro'),
        variant: variant,
        solid: true,
      });
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;
      this.form.id = this.grupos.length + 2;
      this.grupos.push(this.form);
      this.makeToast("success");
      this.carregando = false;
      this.close();
    },
  },
  computed: {},
  mounted() {
    if (this.novo) {
      this.form = {
        title: null,
        description: null,
        format: 1,
      };
    } else {
      this.form = {
        title: this.grupo[0].title,
        description: this.grupo[0].description,
        format: this.grupo[0].format,
      };
    }
  },
  watch: {
    grupo: function () {
      if (this.novo) {
        this.form = {
          title: null,
          description: null,
          format: 1,
        };
      } else {
        this.form = {
          title: this.grupo[0].title,
          description: this.grupo[0].description,
          format: this.grupo[0].format,
        };
      }
    },
    novo: function () {
      if (this.novo) {
        this.form = {
          title: null,
          description: null,
          format: 1,
        };
      } else {
        this.form = {
          title: this.grupo[0].title,
          description: this.grupo[0].description,
          format: this.grupo[0].format,
        };
      }
    },
  },
};
</script>

<style>
</style>