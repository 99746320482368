<template>
  <b-modal id="modal-controle" size="xl" :title=" $t('title.automarb-controles') ">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.automarb-controles') }}</h5>
      <p class="h4 btn">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>
    <b-form v-if="show">
      <b-row>     

        <b-col>
          <b-card bg-variant="light" :header="'Controles da Semana ' + $store.state.semana" id="controles-da-semana">
            <b-table :items="controles" :fields="fields" select-mode="multi" bordered hover small center
              :current-page="currentPage" :per-page="perPage" class="table-controles text-center" head-variant="light">
              <!-- Example scoped slot for select state illustrative purposes -->
              <template v-slot:cell(ativo)="row">
                <b-row align-h="center">
                  <b-icon icon="check-circle-fill" variant="primary" v-if="row.item.ativo"
                    @click="mudarStatus(row.item.cod, row.item.ativo)"></b-icon>
                  <b-icon icon="x-circle" variant="default" v-else @click="mudarStatus(row.item.cod, row.item.ativo)">
                  </b-icon>
                  <!--<b-form-checkbox v-model="row.item.ativo"/> -->
                </b-row>
              </template>

              <template v-slot:cell(excluir)="row">
                <b-icon icon="trash-fill" variant="danger" @click="excluir(row.item.cod)"></b-icon>
              </template>
            </b-table>

            <b-row>
              <b-col sm="5" md="6" class="my-1">
                <b-form-group>
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>   
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-2">
      {{ menssagem }}
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-button  type="button" variant="success" class="float-left"  @click="showModalConverterControle()" >
          {{ $t('botoes.carregar-controles') }} </b-button>
          </b-col>
          <b-col cols="6" md="4">
            <b-button variant="primary" class="float-right" v-if="carregando" disabled>
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button v-else type="submit" variant="primary" class="float-right" @click="close()">
              {{ $t('botoes.fechar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <modal-converter-controle :relatorio="relatorio" @listaMudou="carregarControles()" />
  </b-modal>
</template>

<script>
import ModalConverterControle from "@/components/modal/ConverterControle.vue";
export default {
  components: { ModalConverterControle },
  props: {
    relatorio: null,
  },
  computed: {
    ano: {
      get() {
        return this.$store.state.ano;
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
    },
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 5, text:  this.$t('paginacao.5-itens-por-pagina')  },
        { value: 10, text: this.$t('paginacao.10-itens-por-pagina') },
        { value: 15, text: this.$t('paginacao.15-itens-por-pagina') },
        { value: 100, text: this.$t('paginacao.100-itens-por-pagina') },
      ],
      show: true,
      menssagem: null,
      carregando: null,
      form: {
        controle: null,
        conversor: 1,
      },
      optionsElemento: [],
      elemento_selecionado: null,
      controles: [],
      output: null,
      fields: ["ativo", "cod", "data", "excluir"],
      selected: [],
      optionsConversores: [
        { text: "PC-Dmis", value: 1 },
        { text: "PolyWorks", value: 5, disabled: false },
        { text: "Metrolog", value: 2 },
        { text: "GeoPak", value: 3, disabled: true },
        { text: "CTR", value: 4, disabled: true },        
      ],
    };
  },
  methods: {
    showModalConverterControle() {
      this.$bvModal.show("modal-converter");
    },
    excluir(item) {
      let confirmacao = confirm(
         this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-familia-com-todos-seus-componentes') 
      );

      if (!confirmacao) {
        return false;
      }

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/controle/" + item, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.carregarControles();
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    mudarStatus(item, status) {
      var novo_status = !status;

      this.menssagem = null;
      this.carregando = true;

      let formData = new FormData();
      formData.append("ativo", novo_status ? 1 : 0);
      formData.append("_method", "patch");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/controle/" + item, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.carregarControles();
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    makeToastErro(variant = null) {
      this.$bvToast.toast(this.$t('toast.nenhum-controle-selecionado'), {
        title: this.$t('title.erro'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-controle");
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio);
      formData.append("conversor", this.form.conversor);

      if (this.form.controle) {
        this.form.controle.forEach((element) => {
          formData.append("controle[]", element);
        });

        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        };

        this.$http
          .post("api/controle", formData, config)
          .then((response) => {
            this.makeToast("success");
            this.carregarControles();
            //this.$bvModal.hide("modal-controle");
            this.carregando = false;
            this.form.controle = null;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      } else {
        this.makeToastErro("danger");
        this.carregando = false;
      }
    },
    deletar() { },
    carregarControles() {
      this.carregando = true;
      this.menssagem = null;
      this.controles = null;

      this.$http
        .get(
          "api/relatorio/controles/" +
          this.relatorio +
          "/" +
          this.ano +
          "/" +
          this.semana
        )
        .then((response) => {
          this.controles_bd = response.data;

          this.controles = [];

          this.controles_bd.forEach((controle) => {
            const d = new Date(controle.data).toLocaleDateString("pt-br");
            const h = new Date(controle.data).toLocaleTimeString("pt-br");
            this.controles.push({
              ativo: controle.ativo ? true : false,
              cod: controle.id,
              data: d + " " + h,
            });
          });

          this.totalRows = this.controles.length;

          this.carregando = false;
          //this.$emit("controlesMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarControles();
  },
  watch: {
    relatorio: function () {
      this.carregarControles();
    },
    ano: function () {
      this.carregarControles();
    },
    semana: function () {
      this.carregarControles();
    },
  },
};
</script>

<style scoped>
.table-controles {
  background-color: white;
}

.table-controles tr td {
  padding: 0 !important;
  margin: 0 !important;
}

.form-control {
  border-radius: 0px !important;
}

.controles-da-semana {
  height: 100vh;
}
</style>