<template>
  <div class="home pt-3">
    <b-breadcrumb>
      <b-breadcrumb-item active class="align-middle">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        {{ $t("breadcrumb.familias") }}
      </b-breadcrumb-item>
      <b-button variant="secondary" @click="showModal()" class="ml-auto" size="sm" v-if="user.permissao =='acesso_total' ">
        <b-icon-plus />{{ $t("botoes.novaFamilia") }}
      </b-button>
    </b-breadcrumb>

    <b-row align-h="end">
      <b-col cols="auto">
        <label>{{ $t('label.ano') }}</label>
        <select class="form-control" v-model="ano">
          <option>2019</option>
          <option>2020</option>
          <option>2021</option>
          <option>2022</option>
          <option>2023</option>
        </select>
      </b-col>
      <b-col cols="auto" class="pr-3">
        <label>{{ $t('label.semana') }}</label>
        <select class="form-control" v-model="semana">
          <option v-for="n in semanas" :key="n" :value="n">
            {{ n }}
          </option>
        </select>
      </b-col>
    </b-row>

    <b-container fluid>
      <b-row align-h="start" v-if="!carregando">
        <div class="p-3" v-for="familia in familias" :key="familia.id">
          <router-link :to="'/familia/' + familia.id" style="text-decoration: none; color: inherit">
            <b-card class="text-center px-3 pb-3" no-body @mouseover="isHovering = true" @mouseout="isHovering = false">
              <b-row align-h="end" class="pv-0" >
                <b-dropdown size="sm" variant="link" no-caret right class="pv-0" v-if="user.permissao =='acesso_total' ">
                  <template #button-content>
                    <b-icon icon="three-dots"></b-icon>
                  </template>
                  <b-dropdown-item @click="showModalEditarFamilia(familia)">{{
                    $t("botoes.editar")
                  }}</b-dropdown-item>
                </b-dropdown>
              </b-row>
              <b-card-text class="title-card mt-2">
                {{ familia.descricao }}
              </b-card-text>
              <div class="lab__card" :style="
                'background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
                url +
                '/' +
                familia.imagem +
                ') no-repeat center center /100%'
              "></div>

              <div class="container-analytics" v-if="familia.fechamento_hoje">
                <b-row>
                  <b-col class="m-0 p-0">
                    <div
                      :class="verificaStatusValor(familia.fechamento_hoje.cg.pc_verde, familia.fechamento_ontem.cg.pc_verde)">
                      <b-row class="indicador">
                        <b-col class="mr-0 pr-0">Cg</b-col>
                        <b-col cols="auto"><b-icon-arrow-up v-if="
                          familia.fechamento_hoje.cg.pc_verde >
                          familia.fechamento_ontem.cg.pc_verde
                        " />
                          <b-icon-arrow-right v-else-if="
                            familia.fechamento_hoje.cg.pc_verde ==
                            familia.fechamento_ontem.cg.pc_verde
                          " />
                          <b-icon-arrow-down v-else /></b-col>
                      </b-row>
                      <b-row class="valor-atual justify-content-md-end pr-1">
                        <b-col cols="auto">{{ familia.fechamento_hoje.cg.pc_verde | umaCasa }}%</b-col>
                      </b-row>
                      <b-row class="semana-atual justify-content-md-end pr-1">
                        <b-col cols="auto">Semana {{ familia.fechamento_hoje.semana }}</b-col>
                      </b-row>
                      <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                        <b-col cols="auto">{{ familia.fechamento_ontem.cg.pc_verde | umaCasa }}%</b-col>
                      </b-row>
                      <b-row class="semana-anterior justify-content-md-end pr-1">
                        <b-col cols="auto">Semana {{ familia.fechamento_ontem.semana }}</b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col class="m-0 p-0 px-1">
                    <div
                      :class="verificaStatusValor(familia.fechamento_hoje.cp.pc_verde, familia.fechamento_ontem.cp.pc_verde)">
                      <b-row class="indicador">
                        <b-col class="mr-0 pr-0">{{ indicadorCp(familia.fechamento_indicador)}}</b-col>
                        <b-col cols="auto"><b-icon-arrow-up v-if="
                          familia.fechamento_hoje.cp.pc_verde >
                          familia.fechamento_ontem.cp.pc_verde
                        " />
                          <b-icon-arrow-right v-else-if="
                            familia.fechamento_hoje.cp.pc_verde ==
                            familia.fechamento_ontem.cp.pc_verde
                          " />
                          <b-icon-arrow-down v-else /></b-col>
                      </b-row>
                      <b-row class="valor-atual justify-content-md-end pr-1">
                        <b-col cols="auto">{{ familia.fechamento_hoje.cp.pc_verde | umaCasa }}%</b-col>
                      </b-row>
                      <b-row class="semana-atual justify-content-md-end pr-1">
                        <b-col cols="auto">Semana {{ familia.fechamento_hoje.semana }}</b-col>
                      </b-row>
                      <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                        <b-col cols="auto">{{ familia.fechamento_ontem.cp.pc_verde | umaCasa }}%</b-col>
                      </b-row>
                      <b-row class="semana-anterior justify-content-md-end pr-1">
                        <b-col cols="auto">Semana {{ familia.fechamento_ontem.semana }}</b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <div
                      :class="verificaStatusValor(familia.fechamento_hoje.cpk.pc_verde, familia.fechamento_ontem.cpk.pc_verde)">
                      <b-row class="indicador">
                        <b-col class="mr-0 pr-0">{{ indicadorCpk(familia.fechamento_indicador)}}</b-col>
                        <b-col cols="auto"><b-icon-arrow-up v-if="
                          familia.fechamento_hoje.cpk.pc_verde >
                          familia.fechamento_ontem.cpk.pc_verde
                        " />
                          <b-icon-arrow-right v-else-if="
                            familia.fechamento_hoje.cpk.pc_verde ==
                            familia.fechamento_ontem.cpk.pc_verde
                          " />
                          <b-icon-arrow-down v-else /></b-col>
                      </b-row>
                      <b-row class="valor-atual justify-content-md-end pr-1">
                        <b-col cols="auto">{{ familia.fechamento_hoje.cpk.pc_verde | umaCasa }}%</b-col>
                      </b-row>
                      <b-row class="semana-atual justify-content-md-end pr-1">
                        <b-col cols="auto">Semana {{ familia.fechamento_hoje.semana }}</b-col>
                      </b-row>
                      <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                        <b-col cols="auto">{{ familia.fechamento_ontem.cpk.pc_verde | umaCasa }}%</b-col>
                      </b-row>
                      <b-row class="semana-anterior justify-content-md-end pr-1">
                        <b-col cols="auto">Semana {{ familia.fechamento_ontem.semana }}</b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="container-analytics" v-else>
                <b-row>
                  <b-col class="m-0 p-0">
                    <div class="box-score-grey">
                      <b-row class="indicador">
                        <b-col> &nbsp;</b-col>
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="valor-atual justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="semana-atual justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="semana-anterior justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col class="m-0 p-0 px-1">
                    <div class="box-score-grey">
                      <b-row class="indicador">
                        <b-col> &nbsp;</b-col>
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="valor-atual justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="semana-atual justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="semana-anterior justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col class="m-0 p-0">
                    <div class="box-score-grey">
                      <b-row class="indicador">
                        <b-col> &nbsp;</b-col>
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="valor-atual justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="semana-atual justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                      <b-row class="semana-anterior justify-content-md-end pr-1">
                        <b-col cols="auto"> &nbsp;</b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>

            </b-card>
          </router-link>
        </div>
      </b-row>
    </b-container>
    <modal-familia :familia="familia" @listaMudou="carregarLista()" />
    <modal-carregando-geral />
  </div>
</template>
<script>
import ModalFamilia from "@/components/modal/Familia.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
export default {
  components: {
    ModalFamilia,
    ModalCarregandoGeral,
  },
  computed: {
    user() {
            return this.$store.state.auth.user.user;
        },
    ano: {      
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    semanas: {
      get() {
        return this.$store.state.semanas;
      },
      set(value) {
        this.$store.commit("setSemanas", value);
      },
    },
  },
  data() {
    return {
      isHovering: false,
      familia: {
        id: null,
        descricao: null,
      },
      url: null,
      familias: { data: [] },
      modalShow: false,
      carregando: false,
    };
  },
  methods: {
    indicadorCp(indicador) {
      return indicador == "cp" ? "CP" : "PP";
    },
    indicadorCpk(indicador) {
      return indicador == "cp" ? "CPK" : "PPK";
    },
    verificaStatusValor($atual, $anterior) {
      if ($atual > $anterior) {
        return 'box-score-green'
      } else if ($atual == $anterior) {
        return 'box-score-blue'
      } else {
        return 'box-score-red'
      }
    },
    showModalEditarFamilia(familia) {
      this.familia = familia;
      this.$bvModal.show("modal-familia");
    },
    showModal() {
      this.familia = {
        id: null,
        descricao: null,
      };
      this.$bvModal.show("modal-familia");
    },
    carregarLista() {
      this.carregando = true;
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/familia?ano=" + this.ano + "&semana=" + this.semana)
        .then((response) => {
          this.familias = response.data;
          this.carregando = false;
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
  },
  mounted() {
    if (this.ano && this.semana) {
      this.carregarLista();
    }
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    ano: function () {
      this.carregarLista();
    },
    semana: function () {
      this.carregarLista();
    },
  },
};
</script>

<style scoped>
.lab__card {
  height: 150px;
  width: 200px;
  padding: 0px;
  margin: 0px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
}

.title-card {
  font-weight: bold;
  font-size: 16px;
}

.box-score-blue {
  background-color: #a9cbff;
  color: #1f284d;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-red {
  background-color: #ffb6b6;
  color: #732F2F;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-green {
  background-color: #A4D095;
  color: #2a4422;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-grey {
  background-color: #ececec;
  color: #464646;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.valor-atual {
  font-size: 22px;
}

.semana-atual {
  font-size: 10px;
  font-weight: 100;
  opacity: 0.7;
}

.valor-anterior {
  font-size: 12px;
}

.semana-anterior {
  font-size: 10px;
  font-weight: 100;
  opacity: 0.7;
}

.container-analytics {
  font-size: small;
  color: rgb(116, 116, 116);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.container-analytics .analytics-red {
  padding: 0.2rem;
  background-color: rgb(255, 224, 224);
  color: rgb(255, 105, 105);
  border-radius: 0.5rem;
}

.container-analytics .analytics-blue {
  padding: 0.2rem;
  background-color: rgb(225, 232, 255);
  color: rgb(108, 118, 255);
  border-radius: 0.5rem;
}

.container-analytics .analytics-green {
  padding: 0.2rem;
  background-color: rgb(215, 255, 215);
  color: rgb(72, 172, 72);
  border-radius: 0.5rem;
}

.container-analytics .row-hoje {
  border: 1px solid #1f1f1f;
  border-radius: 0.5rem;
}

.container-analytics .row-ontem {
  font-size: x-small;
}

.container-analytics .row-ontem .col-ontem {
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.col-semana {
  padding: 0px;
  font-size: x-small;
}
</style>
