<template>
    <div class="minha_area pt-3">
        <b-breadcrumb>
            <b-breadcrumb-item active class="align-middle">
                <b-icon icon="person-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                {{ $t("breadcrumb.minha-area") }}
            </b-breadcrumb-item>
        </b-breadcrumb>

        <!-- <b-alert show variant="danger" v-if="statusSubscription.prazo_trial == 0 && !statusSubscription.status_subscription">

            <h4 class="alert-heading">Seu período de
                testes encerrou e sua conta foi bloqueada!</h4>
            <p>
                Para continuar a utilizar o sistema acessa a aba "Assinatura" e crie sua assinatura.
            </p>
            <p>Somente será liberado o acesso após o pagamento da fatura, lembrando que o prazo de liberação pode variar
                conforme a forma de pagamento.</p>
            <hr>
            <p class="mb-0">
                <i>Contas bloqueadas serão excluidas conforme os termos de uso</i>
            </p>
        </b-alert> -->

        <b-card no-body>
            <b-tabs card>
                <b-tab :title="$t('title.detalhe-perfil')" active>
                    <b-card-text>
                        <b-row align-v="center">
                            <b-col cols="auto">
                                <b-avatar rounded size="5rem" :src="avatarImage | urlImagem"></b-avatar>
                            </b-col>
                            <b-col class="ml-3">
                                <b-row>
                                    <b-button variant="primary" class="mr-2" @click="showModalUploadImagem()">Enviar
                                        nova foto</b-button>
                                    <b-button @click="redefinirProfileImage()">Redefinir</b-button>
                                </b-row>
                                <b-row class="mt-1"><small>JPG, GIF ou PNG permitidos. Tamanho máximo de
                                        800K</small></b-row>
                            </b-col>
                        </b-row>

                        <MeusDados />

                    </b-card-text>
                </b-tab>
                <b-tab title="Organização" v-if="user.role == 'admin'">
                    <b-card-text>
                        <Organizacao />
                    </b-card-text>
                </b-tab>
                <b-tab title="Segurança">
                    <b-card-text>
                        <Seguranca />
                    </b-card-text>
                </b-tab>
                <!-- <b-tab title="Assinatura" v-if="user.role == 'admin'">
                    <b-card-text>
                        <subscription-management />
                    </b-card-text>
                </b-tab> -->

            </b-tabs>
        </b-card>

        <upload-imagem @imagemMudou="carregarMeusDados" />

    </div>
</template>

<script>
import UploadImagem from "@/components/modal/UploadImagemProfile.vue";
import MeusDados from "@/components/form/ProfileData.vue";
import Organizacao from "@/components/form/Organizacao.vue";
import Seguranca from '@/components/form/Seguranca.vue';
import SubscriptionManagement from '@/components/form/SubscriptionManagement.vue';


export default {
    components: {
        UploadImagem,
        MeusDados,
        Organizacao,
        Seguranca,
        SubscriptionManagement        
    },
    data() {
        return {
            organizacao: null,
            avatarImage: null,
            show: true,
            menssagem: null,
            carregando: null,
            url: "api/user",
        };
    },
    computed: {
        user() {
            return this.$store.state.auth.user.user;
        },
        statusSubscription() {
            return this.$store.state.auth.user.user.organizacao.responsavel;
        },
    },
    methods: {        
        redefinirProfileImage() {
            this.$bvModal.show("modal-carregando-geral");
            this.carregando = true;
            this.$http
                .post('api/user/updateProfileImagem')
                .then((response) => {
                    this.$bvModal.hide("modal-carregando-geral");
                    this.carregando = false;                    
                    this.carregarMeusDados();
                    
                })
                .catch((errors) => {
                    this.$bvModal.hide("modal-carregando-geral");
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        showModalUploadImagem() {
            this.$bvModal.show("modal-upload-imagem");
        },
        carregarMeusDados() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/me")
                .then((response) => {
                    this.avatarImage = response.data.profileImage;
                    this.$bvModal.hide("modal-carregando-geral");
                    
                    const userData = JSON.parse(localStorage.getItem("user"))                     
                    userData.user = response.data                           
                    localStorage.removeItem('user')           
                    localStorage.setItem('user', JSON.stringify(userData))                     
                    this.$store.state.auth.user = userData
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
        carregarOrganizacao() {
            this.$http
                .get("api/organizacao")
                .then((response) => {
                    this.organizacao = response.data.id;
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        }
    },
    mounted() {
        this.carregarMeusDados();
        this.carregarOrganizacao();
    },

}
</script>

<style></style>