import axios from 'axios';
let API_URL = process.env.VUE_APP_ROOT_API

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/api/login', {
        email: user.email,
        password: user.password,
        tenant_id: user.tenant_id,
        two_factor_code: user.two_factor_code,
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    let auth = JSON.parse(localStorage.getItem('user'));

    if (auth && auth.user.tenant_id) {
      API_URL = process.env.VUE_APP_ROOT_API + '/organization/' + auth.user.tenant_id
    }

    axios
      .post(API_URL + '/api/logout')
      .then(response => {
        if (response.data.msg) {
          localStorage.removeItem('user');
        }
        return response.data;
      });

  }
  register(user) {

    return axios.post(API_URL + '/api/register', user)
      .then(response => {        
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }        
        return response.data;
      });
  }
}
export default new AuthService();