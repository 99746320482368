<template>
  <div class="card">
    <table class="text-center table-bordered table-condensed">
      <tr>
        <th>
          <img
            :src="'../assets/simbol/' + elemento.tipo_elemento.imagem"
            width="24"
          />
        </th>
        <th colspan="3">{{ elemento.descricao }}</th>
        <th colspan="2">AUTOMARB</th>
      </tr>
      <tr>
        <th>Ax</th>
        <th>{{indicadorCp}}</th>
        <th>{{indicadorCp}}K</th>
        <th>XMED</th>
        <th>RANGE</th>
        <th>TOLERANCE</th>
      </tr>
      <tr v-if="elemento.exibirx">
        <td :style="elemento.calculox | calculo">X</td>
        <td :style="'background-color:'+elemento.xcpcor">{{ elemento.xcp | duasCasas }}</td>
        <td :style="'background-color:'+elemento.xcpkcor">{{ elemento.xcpk | duasCasas }}</td>
        <td :style="'background-color:'+elemento.xmedcor">{{ elemento.xmed | duasCasas }}</td>
        <td>{{ elemento.xrange | duasCasas }}</td>
        <td>
          {{ elemento.lixtol | duasCasas }} | {{ elemento.lsxtol | duasCasas }}
        </td>
      </tr>
      <tr v-if="elemento.exibiry">
        <td :style="elemento.calculoy | calculo">Y</td>
        <td :style="'background-color:'+elemento.ycpcor">{{ elemento.ycp | duasCasas }}</td>
        <td :style="'background-color:'+elemento.ycpkcor">{{ elemento.ycpk | duasCasas }}</td>
        <td :style="'background-color:'+elemento.ymedcor">{{ elemento.ymed | duasCasas }}</td>
        <td>{{ elemento.yrange | duasCasas }}</td>
        <td>
          {{ elemento.liytol | duasCasas }} | {{ elemento.lsytol | duasCasas }}
        </td>
      </tr>
      <tr v-if="elemento.exibirz">
        <td :style="elemento.calculoz | calculo">Z</td>
        <td :style="'background-color:'+elemento.zcpcor">{{ elemento.zcp | duasCasas }}</td>
        <td :style="'background-color:'+elemento.zcpkcor">{{ elemento.zcpk | duasCasas }}</td>
        <td :style="'background-color:'+elemento.zmedcor">{{ elemento.zmed | duasCasas }}</td>
        <td>{{ elemento.zrange | duasCasas }}</td>
        <td>
          {{ elemento.liztol | duasCasas }} | {{ elemento.lsztol | duasCasas }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
    indicador: null
  },
  computed: {
    indicadorCp() {
      return this.indicador == "cp" ? "CP" : "PP";
    },
  },
};
</script>

<style scoped>
.table-condensed {
  font-size: 12px;
  padding: 0;
}
tr td,
th {
  padding: 2px !important;
}
</style>