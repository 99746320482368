import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

// Adicionando um interceptor de Request
axios.interceptors.request.use(function (config) { 
  
  let auth = JSON.parse(localStorage.getItem('user'));

  if (auth && auth.user.tenant_id) {
    config.baseURL = process.env.VUE_APP_ROOT_API + '/organization/' + auth.user.tenant_id
  }
  else {
    config.baseURL = process.env.VUE_APP_ROOT_API
  }

  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    config.headers.Authorization = 'Bearer ' + user.token
    config.maxBodyLength = 10000000000000
    config.maxContentLength = 10000000000000
  }

  return config;
}, function (error) {
  // Faça algo com erro da solicitação
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {

    const access_token = localStorage.getItem("user");
    if (error.response.status === 401 && access_token) {
      //const response = await refreshToken(error);
      localStorage.removeItem('user');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios
  }
})
