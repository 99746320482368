var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dot align-middle"},[_c('b-row',{staticClass:"align-middle",staticStyle:{"margin-top":"15%"}},[_c('b-col',[_vm._v(_vm._s(_vm.elemento.descricao))])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"eixos"},[(_vm.elemento.exibirx)?_c('span',{style:('background-color:' + _vm.elemento.xriskcor)},[_vm._v("X")]):_vm._e(),(_vm.elemento.exibiry)?_c('span',{style:('background-color:' + _vm.elemento.yriskcor)},[_vm._v("Y")]):_vm._e(),(_vm.elemento.exibirz)?_c('span',{style:('background-color:' + _vm.elemento.zriskcor)},[_vm._v("Z")]):_vm._e()])])],1),_c('b-row',{staticClass:"align-middle"},[_c('b-col',[_c('div',{staticClass:"planos"},[(
            _vm.elemento.exibirx && _vm.avaliablePlano(_vm.elemento.descricao_ref, 'x')[0]
          )?_c('span',{style:('background-color:' +
            _vm.avaliablePlano(_vm.elemento.descricao_ref, 'x')[0].analysis.cor)},[_vm._v(" "+_vm._s(_vm.avaliablePlano(_vm.elemento.descricao_ref, "x")[0].seq))]):_vm._e(),(
            _vm.elemento.exibirx &&
            !_vm.avaliablePlano(_vm.elemento.descricao_ref, 'x')[0]
          )?_c('span',[_vm._v(" - ")]):_vm._e(),(
            _vm.elemento.exibiry && _vm.avaliablePlano(_vm.elemento.descricao_ref, 'y')[0]
          )?_c('span',{style:('background-color:' +
            _vm.avaliablePlano(_vm.elemento.descricao_ref, 'y')[0].analysis.cor)},[_vm._v(" "+_vm._s(_vm.avaliablePlano(_vm.elemento.descricao_ref, "y")[0].seq))]):_vm._e(),(
            _vm.elemento.exibiry &&
            !_vm.avaliablePlano(_vm.elemento.descricao_ref, 'y')[0]
          )?_c('span',[_vm._v(" - ")]):_vm._e(),(
            _vm.elemento.exibirz && _vm.avaliablePlano(_vm.elemento.descricao_ref, 'z')[0]
          )?_c('span',{style:('background-color:' +
            _vm.avaliablePlano(_vm.elemento.descricao_ref, 'z')[0].analysis.cor)},[_vm._v(" "+_vm._s(_vm.avaliablePlano(_vm.elemento.descricao_ref, "z")[0].seq))]):_vm._e(),(
            _vm.elemento.exibirz &&
            !_vm.avaliablePlano(_vm.elemento.descricao_ref, 'z')[0]
          )?_c('span',[_vm._v(" - ")]):_vm._e()])])],1),_c('b-row',{staticClass:"align-middle"},[_c('b-col',[_vm._v("Plan")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }