<template>
  <div>

    <Header></Header>

    <Hero />

    <main id="main">
      <About />
      <Services />
      <Skills />
      <Pricing />      
      <Contact />
    </main>

    <Footer></Footer>

     <Preloader /> 

    <BackToTop />

  </div>
</template>

<script>
import Header from '@/components/site/Header.vue'
import BackToTop from '@/components/site/BackToTop.vue'
import Hero from '@/components/site/Hero.vue'
import Footer from '@/components/site/Footer.vue'
import Preloader from '@/components/site/Preloader.vue'
import Pricing from '@/components/site/Pricing.vue'
import About from '@/components/site/About.vue'
import Services from '@/components/site/Services.vue'
import Skills from '@/components/site/Skills.vue'
import Team from '@/components/site/Team.vue'
import Contact from '@/components/site/Contact.vue'


export default {
  components: { Header, BackToTop, Hero, Footer, Preloader, Pricing, About, Services, Skills, Team, Contact },
  name: 'Home',
  methods: {

  },
}
</script>
