<template>
    <Home />
</template>
  
<script>
import '@/assets/css/main.css'
import '@/assets/vendor/boxicons/css/boxicons.min.css'
import Home from '@/components/site/Home.vue'

export default {
    components: { Home },
    name: 'IndexPage'
}
</script>