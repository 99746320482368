<template>
    <div class="home pt-3">
        <b-breadcrumb>
            <b-breadcrumb-item active class="align-middle">
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                {{ $t("breadcrumb.notificacoes") }}
            </b-breadcrumb-item>
        </b-breadcrumb>


        <b-card class="mt-3" no-body>
            <b-table :items="notificacoes" :fields="fields" responsive="sm" head-variant="light" sort-icon-left hover
                show-empty class="mb-0" :emptyText="$t('global.table-empty')">
                <template #cell(created_at)="row">
                    {{ row.item.created_at | dataCompleta }}
                </template>

                <template #cell(data)="row" class="text-right" :to="row.item.data.url">

                    <b-row align-v="center" align-h="center" class="text-sm-left ml-2"
                        :class="row.item.read_at ? 'text-gray' : 'text-gray-bold'">
                        <b-col cols="auto" class="pl-0">
                            <b-img center height="30" :src="row.item.data.urlImagem | urlImagem" />
                        </b-col>
                        <b-col class="pr-3">
                            <b-row class="font-weight-bold small">{{ row.item.data.message }}</b-row>
                            <b-row class="text-truncate font-weight-light small">{{ row.item.data.componente }}</b-row>
                        </b-col>
                        <b-col cols="auto">
                            <b-icon v-if="!row.item.read_at" icon="circle-fill" animation="throb" variant="primary">
                            </b-icon>
                        </b-col>
                    </b-row>
                </template>

                <template v-slot:cell(ações)="row">
                    <router-link :to="row.item.data.url" class="button">
                        <b-icon icon="folder-symlink" variant="primary" />
                    </router-link>
                </template>


            </b-table>
        </b-card>
        <b-row align-h="center" class="my-3">
            <b-col sm="5" md="2" class="my-1">
                <b-form-group>
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="2" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
        <modal-carregando-geral />        
    </div>
</template>

<script>
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
export default {
    name: "Notificacoes",
    components: {
        ModalCarregandoGeral,       
    },
    data() {
        return {
           
            notificacoes: [],
            fields: [
                { key: "data", label: this.$t('label.notificacao'), sortable: false },
                { key: "created_at", label: this.$t('label.data'), sortable: false },
                this.$t('table.title.acoes'),
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [
                { value: 5, text: this.$t('paginacao.5-itens-por-pagina') },
                { value: 10, text: this.$t('paginacao.10-itens-por-pagina') },
                { value: 15, text: this.$t('paginacao.15-itens-por-pagina') },
                { value: 100, text: this.$t('paginacao.100-itens-por-pagina') },
            ],
        };
    },
    methods: {
        carregarNotificacoes() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/notifications?page=" + this.currentPage + "&perpage=" + this.perPage)
                .then((response) => {
                    this.totalRows = response.data.total
                    this.notificacoes = response.data.data
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
    },
    mounted() {
        this.carregarNotificacoes()
    },
    watch: {
        currentPage: function () {
            this.carregarNotificacoes();
        },
        perPage: function () {
            this.carregarNotificacoes();
        }
    },

};
</script>

<style scoped>
.text-gray {
    color: gray;
    min-width: 25rem;
}

.text-gray-bold {
    color: rgb(78, 78, 78);
    min-width: 25rem;
}
</style>