<template>
    <div>
        <b-row>
            <b-col>
                <b-card header="Método de Pagamento" header-tag="header">
                    <b-card-text>
                        <div v-show="boxAddCard">
                            <label>{{ $t('assinatura.nome-do-titular') }}</label>
                            <input id="card-holder-name" type="text" v-model="name" class="form-control mb-2">

                            <label>{{ $t('assinatura.cartao') }}</label>
                            <div id="card-element"></div>

                            <b-button class="btn btn-primary mt-3" v-if="addPaymentStatus == 1" disabled>
                                <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                                {{ $t('botoes.salvando') }} </b-button>

                            <button v-else class="btn btn-primary mt-3" id="add-card-button"
                                v-on:click="submitPaymentMethod()">
                                {{ $t('assinatura.salvar-forma-de-pagamento') }} </button>
                        </div>

                        <div v-show="paymentMethodsLoadStatus == 2
                            && paymentMethods.length > 0">
                            <div v-for="(method, key) in paymentMethods" v-bind:key="'method-' + key"
                                v-on:click="paymentMethodSelected = method.id" class="border rounded row p-1">
                                <div class="col-2">
                                    {{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}
                                </div>
                                <div class="col-7">
                                    &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {{
                                        method.last_four }} <!-- Exp: {{ method.exp_month }} / {{ method.exp_year }} -->
                                </div>
                                <div class="col-3">
                                    <b-button v-on:click.stop="removePaymentMethod(method.id)">{{ $t('botoes.remover')
                                    }}</b-button>
                                </div>
                            </div>
                        </div>

                        <div v-show="paymentMethodsLoadStatus == 2
                            && paymentMethods.length == 0 && !boxAddCard" class="">
                            <p>{{ $t('assinatura.nenhum-metodo-de-pagamento-registrado') }}</p>
                            <b-button @click="boxAddCard = true">{{ $t('assinatura.adicione-um-metodo-de-pagamento')
                            }}</b-button>
                        </div>
                    </b-card-text>

                </b-card>

                <b-card header="Assinatura" header-tag="header" class="mt-3">
                    <b-card-text>
                        <div v-if="boxAddSubscription">
                            <label>{{ $t('assinatura.plano-stantard') }} <sup>R$</sup>150,00 <span>{{
                                $t('pricing.por-usuario-mes') }}</span>
                            </label>
                            <hr />

                            <b-row class="mt-2 text-center">
                                <b-col>
                                    <label>{{ $t('assinatura.quantidade-de-usuarios') }}</label>
                                    <input type="number" v-model="userQuantityPlan" class="form-control mb-2 text-center">
                                </b-col>
                                <b-col>
                                    <label>{{ $t('assinatura.total-da-assinatura') }}</label>
                                    <div class="valor-total mt-2">
                                        <b><sup>R$</sup>{{ userQuantityPlan * 150 }},00</b>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-button class="btn btn-primary mt-3" v-if="updateSubscriptionStatus == 1" disabled>
                                <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                                {{ $t('botoes.salvando') }} </b-button>

                            <b-button v-else class="btn btn-primary mt-3" id="add-card-button"
                                v-on:click="updateSubscription()">
                                {{ mySubscription ? 'Atualizar' : 'Assinar' }}
                            </b-button>

                        </div>

                        <div v-if="mySubscriptionLoadStatus == 2 && mySubscription && !boxAddSubscription">
                            <p><b>{{ $t('label.plano') }}:</b> {{ $t('assinatura.plano-stantard') }} <sup>R$</sup>150,00 {{
                                $t('pricing.por-usuario-mes') }}</p>
                            <p><b>{{ $t('assinatura.quantidade-usuarios') }}</b> {{ mySubscription.quantity }}</p>
                            <p><b>{{ $t('label.status') }}:</b><b-badge variant="success">{{ mySubscription.stripe_status
                            }}</b-badge></p>
                            <p v-if="mySubscription.ends_at"><b>{{ $t('assinatura.validade') }}:</b> {{
                                $t('assinatura.sua-assinatura-foi-cancelada-mas-voce-pode-usar-ate-mysubscription-ends_at-or-datacompleta',
                                    [mySubscription.ends_at | dataCompleta]) }}</p>

                            <b-row>
                                <b-col>
                                    <b-button v-if="cancelSubscriptionStatus == 1" disabled>
                                        <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                                        {{ $t('botoes.cancelando') }} </b-button>
                                    <b-button class="mr-3" v-show="!mySubscription.ends_at" v-else
                                        @click="cancelSubscription()">{{ $t('botoes.cancelar') }}</b-button>
                                    <b-button v-show="!mySubscription.ends_at" @click="boxAddSubscription = true">{{
                                        $t('assinatura.adicionar-usuarios') }} </b-button>


                                    <b-button v-if="resumeSubscriptionStatus == 1" disabled>
                                        <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                                        {{ $t('botoes.retomando') }} </b-button>
                                    <b-button v-else v-show="mySubscription.ends_at" @click="resumeSubscription()">{{
                                        $t('assinatura.continuar-assinante') }}</b-button>
                                </b-col>
                            </b-row>
                        </div>

                        <div v-show="!boxAddSubscription && mySubscriptionLoadStatus == 2 && !mySubscription">
                            <p>{{ $t('assinatura.no-momento-nao-ha-assinatura-ativa-para-sua-organizacao') }}</p>
                            <b-button @click="boxAddSubscription = true" :disabled="paymentMethods.length == 0">{{
                                $t('assinatura.adicione-uma-assinatura') }}a</b-button>
                            <p class="mt-2" v-if="paymentMethods.length == 0"><i>{{
                                $t('assinatura.e-nesserario-ter-o-metodo-de-pagamento-cadastrado') }}.</i></p>
                        </div>
                    </b-card-text>

                </b-card>


            </b-col>
            <b-col>
                <b-card header="Faturas" header-tag="header" no-body>
                    <b-card-text>
                        <b-table :items="invoices" :fields="invoicesFields" responsive="sm" head-variant="light" show-empty
                            class="mb-0" :emptyText="$t('global.table-empty')">
                            <template #cell(created)="row">
                                {{ row.item.created * 1000 | dataCompleta }}
                            </template>
                            <template #cell(amount_due)="row">
                                {{ row.item.amount_due | moeda }}
                            </template>
                            <template #cell(status)="row">
                                <b-badge variant="success">{{ row.item.status }} </b-badge>
                            </template>
                            <template #cell(invoice_pdf)="row">
                                <b-button :href="row.item.invoice_pdf">
                                    {{ $t('botoes.baixar') }} </b-button>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-card>
            </b-col>

        </b-row>

    </div>
</template>

<script>

export default {
    data() {
        return {
            stripeAPIToken: 'pk_test_51MShx3LJYrcYJKcE69mVCTcdumyR4XWdDnBPTnBIb62RO6BWHgvavAcDp8628K2J1oAxbXBlsJeHqX42h1MkWdqi008v1mVg9k',

            stripe: '',
            elements: '',
            card: '',

            intentToken: '',

            name: '',
            addPaymentStatus: 0,
            addPaymentStatusError: '',

            paymentMethods: [],
            paymentMethodsLoadStatus: 0,
            paymentMethodSelected: {},

            selectedPlan: 'price_1MSj42LJYrcYJKcEyP0aiADt',

            boxAddCard: false,
            boxAddSubscription: false,
            userQuantityPlan: 1,

            mySubscription: null,
            mySubscriptionLoadStatus: 0,

            updateSubscriptionStatus: 0,

            invoices: null,
            invoicesFields: [
                { key: 'created', label: this.$t('label.data') },
                { key: 'amount_due', label: this.$t('label.valor') },
                { key: 'status', label: this.$t('label.status') },
                { key: 'invoice_pdf', label: this.$t('assinatura.download') },
            ],
            getInvoicesStatus: 0,
            cancelSubscriptionStatus: 0,
            resumeSubscriptionStatus: 0,
        }
    },

    computed: {
        user() {
            return this.$store.state.auth.user.user;
        },
    },

    mounted() {
        this.includeStripe('js.stripe.com/v3/', function () {
            this.configureStripe();
        }.bind(this));

        this.loadIntent();

        this.loadPaymentMethods();

        this.getSubscription();

        this.getInvoices();
    },

    methods: {

        getInvoices() {
            this.getInvoicesStatus = 1
            this.$http.get('/api/user/getInvoices')
                .then(function (response) {
                    if (response.data) {
                        this.invoices = response.data;
                    }
                    this.getInvoicesStatus = 2
                }.bind(this));
        },

        getSubscription() {
            this.$http.get('/api/user/getSubscription')
                .then(function (response) {
                    if (response.data) {
                        this.mySubscription = response.data;
                    }
                    this.mySubscriptionLoadStatus = 2

                }.bind(this));
        },
        /*
            Includes Stripe.js dynamically
        */
        includeStripe(URL, callback) {
            var documentTag = document, tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },

        /*
            Configures Stripe by setting up the elements and 
            creating the card element.
        */
        configureStripe() {
            this.stripe = Stripe(this.stripeAPIToken);

            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');

            this.card.mount('#card-element');
        },

        /*
            Loads the payment intent key for the user to pay.
        */
        loadIntent() {
            this.$http.get('/api/user/setup-intent')
                .then(function (response) {
                    this.intentToken = response.data;
                }.bind(this));
        },

        /*
            Uses the intent to submit a payment method
            to Stripe. Upon success, we save the payment
            method to our system to be used.
        */
        submitPaymentMethod() {
            this.addPaymentStatus = 1;

            this.stripe.confirmCardSetup(
                this.intentToken.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.name
                    }
                }
            }
            ).then(function (result) {
                if (result.error) {
                    this.addPaymentStatus = 3;
                    this.addPaymentStatusError = result.error.message;
                } else {
                    this.savePaymentMethod(result.setupIntent.payment_method);
                    this.addPaymentStatus = 2;
                    this.card.clear();
                    this.name = '';
                }
                this.boxAddCard = false;
            }.bind(this));
        },

        /*
            Saves the payment method for the user and
            re-loads the payment methods.
        */
        savePaymentMethod(method) {
            this.$http.post('/api/user/payments', {
                payment_method: method
            }).then(function () {
                this.loadPaymentMethods();
            }.bind(this));
        },

        /*
            Loads all of the payment methods for the
            user.
        */
        loadPaymentMethods() {
            this.paymentMethodsLoadStatus = 1;

            this.$http.get('/api/user/payment-methods')
                .then(function (response) {
                    this.paymentMethods = response.data;

                    this.paymentMethodsLoadStatus = 2;

                    this.setDefaultPaymentMethod();
                }.bind(this));
        },

        setDefaultPaymentMethod() {
            if (this.paymentMethods.length > 0) {
                this.paymentMethodSelected = this.paymentMethods[0].id
            }
        },

        removePaymentMethod(paymentID) {
            this.$http.post('/api/user/remove-payment', {
                id: paymentID
            }).then(function (response) {
                this.loadPaymentMethods();
            }.bind(this));
        },

        updateSubscription() {
            this.updateSubscriptionStatus = 1
            this.$http.put('/api/user/subscription', {
                plan: this.selectedPlan,
                payment: this.paymentMethodSelected,
                userQuantityPlan: this.userQuantityPlan
            }).then(function (response) {
                this.getSubscription()
                this.updateSubscriptionStatus = 2
                this.boxAddSubscription = false
                this.getInvoices()
                this.updateUser()
            }.bind(this));
        },

        updateUser() {
            this.$http.get('/api/me')
                .then(function (response) {
                    const userData = JSON.parse(localStorage.getItem("user"))
                    userData.user = response.data
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(userData))
                    this.$store.state.auth.user = userData
                }.bind(this));
        },

        cancelSubscription() {
            this.cancelSubscriptionStatus = 1
            this.$http.get('/api/user/cancel-subscription')
                .then(function (response) {
                    this.updateUser()
                    this.getSubscription()
                    this.cancelSubscriptionStatus = 2
                }.bind(this));
        },

        resumeSubscription() {
            this.resumeSubscriptionStatus = 1
            this.$http.get('/api/user/resume-subscription')
                .then(function (response) {
                    this.updateUser()
                    this.getSubscription()
                    this.resumeSubscriptionStatus = 2
                }.bind(this));
        }
    }
}

</script>