<template>
  <b-modal id="modal-pagina" size="lg" :title=" $t('title.automarb-pagina') ">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.automarb-pagina') }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>
    <b-form v-if="show">
      <b-form-group id="input-group-2" :label=" $t('label.imagem') " label-for="input-2">
        <b-form-file
          accept="image/*"
          v-model="form.imagem"
          :placeholder=" $t('placeholder.escolha-uma-imagem-ou-solte-o-aqui') "
          :drop-placeholder=" $t('drop-placeholder.solte-a-imagem-aqui') "
        ></b-form-file>
      </b-form-group>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col
                ><p class="h1 ml-3">
                  <b-icon
                    icon="exclamation-octagon"
                    variant="primary"
                  ></b-icon></p
              ></b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                  {{ $t('modal.preenchaTodosOsDadosCorretamente') }} </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
             <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t('botoes.salvar') }} </b-button>

            <!-- <b-button
              v-if="pagina.id"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="deletar()"
            >
              {{ $t('botoes.deletar') }} </b-button> -->
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    pagina: {
      type: Object,
      default: function () {
        return {
          id: null,
          relatorio_id: null,
          imagem: null,
        };
      },
    },
    relatorio: {
      type: Object,
      default: function () {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      form: {
        relatorio_id: null,
        imagem: null,
      },
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/pagina",
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      //this.limpar();
      this.$bvModal.hide("modal-pagina");
    },
    limpar() {
      this.form.relatorio_id = "";
      this.form.imagem = null;
      this.menssagem = null;
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;
      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio.id);

      if (this.form.imagem) {
        formData.append("imagem", this.form.imagem);
      }

      if (this.pagina.id) {
        formData.append("_method", "patch");
        this.url = "api/pagina/" + this.pagina.id;
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-pagina");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    deletar() {
      let confirmacao = confirm(
        this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-pagina-com-todos-seus-componentes')
      );

      if (!confirmacao) {
        return false;
      }

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.url = "api/pagina/" + this.pagina.id;

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.$bvModal.hide("modal-pagina");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  watch: {
    pagina: function (pagina) {
      console.log(pagina);
      if (pagina.id) {
        this.form.relatorio_id = pagina.relatorio_id;
        this.url = "api/pagina/" + this.pagina.id;
      } else {
        this.url = "api/pagina";
      }
    },
    relatorio: function (relatorio) {
      this.form.relatorio_id = relatorio.id;
    },
  },
};
</script>

<style >
</style>