<template>
    <div>
        <b-row class="border-top mt-3 pt-3">
            <b-col>
                <b-form v-if="show">
                    
                    <b-form-group :label="$t('labe.nome')">
                        <b-skeleton type="input" v-if="carregando"></b-skeleton>
                        <b-form-input v-else v-model="form.name" type="text" :placeholder="$t('placeholder.entre-com-o-nome')"
                            required>
                        </b-form-input>
                    </b-form-group>

                    <b-form-group label="E-mail">
                        <b-skeleton type="input" v-if="carregando"></b-skeleton>
                        <b-form-input v-else v-model="form.email" type="email"
                            :placeholder="$t('placehoader.entre-com-o-e-mail')" required>
                        </b-form-input>
                    </b-form-group>


                    <b-form-group :label="$t('label.departamento')">
                        <b-skeleton type="input" v-if="carregando"></b-skeleton>
                        <b-form-select v-else :options="departamentos" v-model="form.departamento_id" required></b-form-select>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button variant="primary" class="float-left" v-if="carregando" disabled>
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }} </b-button>

                <b-button v-else type="submit" variant="primary" class="float-left" @click="salvar()">
                    {{ $t('botoes.salvar-alteracoes') }} </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true,
            menssagem: null,
            carregando: null,
            form: {
                id: null,
                name: null,
                email: null,
                departamento_id: null,
            },
            departamentos: [],
        };
    },
    methods: {
        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                 title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        carregarMeusDados() {
            this.carregando = true
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/me")
                .then((response) => {
                    this.form.id = response.data.id;
                    this.form.name = response.data.name;
                    this.form.email = response.data.email;
                    this.form.departamento_id = response.data.departamento_id;
                    this.carregando = false
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.carregando = false
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
        carregarDepartamentos() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/departamento")
                .then((response) => {
                    this.departamentos = []
                    response.data.forEach((element) => {
                        this.departamentos.push({
                            value: element.id,
                            text: element.descricao,
                        });
                    });
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;

            this.$http
                .patch('api/user/' + this.form.id, this.form)
                .then((response) => {
                    this.makeToast("success");
                    this.updateUser();
                    this.carregando = false;
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        updateUser(){
            this.$http.get('/api/me').then(function(response){
                const userData = JSON.parse(localStorage.getItem("user"))                     
                userData.user = response.data                           
                localStorage.removeItem('user')           
                localStorage.setItem('user', JSON.stringify(userData))                     
                this.$store.state.auth.user = userData     
            })
        }
    },
    mounted() {
        this.carregarDepartamentos();
        this.carregarMeusDados();
    },

}
</script>

<style>

</style>