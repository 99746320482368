import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ano:null,
    semana:null,
    semanas:null,
    is_expanded:false,
    fechamento: 1,
    lang: "pt-BR"
  },
  getters: {
  },
  mutations: {
    setSemana(state, semana) {
      state.semana = semana;
    },
    setAno(state, ano) {
      state.ano = ano;
    },
    setSemanas(state, semanas) {
      state.semanas = semanas;
    },   
    setExpanded(state, is_expanded) {
      state.is_expanded = is_expanded;
    },
    setFechamento(state, fechamento) {
      state.fechamento = fechamento;
    },   
    setLang(state, lang) {
      state.lang = lang;
    }   
  },
  actions: {
    
  },
  modules: {
    auth
  }
})
