<template>
  <div class="dot align-middle">
    <b-row class="align-middle" style="margin-top: 15%">
      <b-col>{{ elemento.descricao }}</b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="eixos">
          <span
            v-if="elemento.exibirx"
            :style="'background-color:' + elemento.xriskcor"
            >X</span
          >
          <span
            v-if="elemento.exibiry"
            :style="'background-color:' + elemento.yriskcor"
            >Y</span
          >
          <span
            v-if="elemento.exibirz"
            :style="'background-color:' + elemento.zriskcor"
            >Z</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row class="align-middle">
      <b-col>
        <div class="planos">
          <span
            v-if="
              elemento.exibirx && avaliablePlano(elemento.descricao_ref, 'x')[0]
            "
            :style="
              'background-color:' +
              avaliablePlano(elemento.descricao_ref, 'x')[0].analysis.cor
            "
          >
            {{ avaliablePlano(elemento.descricao_ref, "x")[0].seq }}</span
          >
          <span
            v-if="
              elemento.exibirx &&
              !avaliablePlano(elemento.descricao_ref, 'x')[0]
            "
          >
            -
          </span>
          <span
            v-if="
              elemento.exibiry && avaliablePlano(elemento.descricao_ref, 'y')[0]
            "
            :style="
              'background-color:' +
              avaliablePlano(elemento.descricao_ref, 'y')[0].analysis.cor
            "
          >
            {{ avaliablePlano(elemento.descricao_ref, "y")[0].seq }}</span
          >
          <span
            v-if="
              elemento.exibiry &&
              !avaliablePlano(elemento.descricao_ref, 'y')[0]
            "
          >
            -
          </span>
          <span
            v-if="
              elemento.exibirz && avaliablePlano(elemento.descricao_ref, 'z')[0]
            "
            :style="
              'background-color:' +
              avaliablePlano(elemento.descricao_ref, 'z')[0].analysis.cor
            "
          >
            {{ avaliablePlano(elemento.descricao_ref, "z")[0].seq }}</span
          >
          <span
            v-if="
              elemento.exibirz &&
              !avaliablePlano(elemento.descricao_ref, 'z')[0]
            "
          >
            -
          </span>
        </div>
      </b-col>
    </b-row>

    <b-row class="align-middle">
      <b-col>Plan</b-col>
    </b-row>

    <!-- <table class="text-center table-bordered table-condensed">
      <tr>
        <th>
          <img
            :src="'../assets/simbol/' + elemento.tipo_elemento.imagem"
            width="24"
          />
        </th>
        <th colspan="3">{{ elemento.descricao }}</th>
        <th colspan="2">AUTOMARB</th>
      </tr>
      <tr>
        <th>Ax</th>
        <th>CP</th>
        <th>CPK</th>
        <th>XMED</th>
        <th>RANGE</th>
        <th>TOLERANCE</th>
      </tr>
      <tr v-if="elemento.exibirx">
        <td :style="elemento.calculox | calculo">X</td>
        <td :style="'background-color:'+elemento.xcpcor">{{ elemento.xcp | duasCasas }}</td>
        <td :style="'background-color:'+elemento.xcpkcor">{{ elemento.xcpk | duasCasas }}</td>
        <td :style="'background-color:'+elemento.xmedcor">{{ elemento.xmed | duasCasas }}</td>
        <td>{{ elemento.xrange | duasCasas }}</td>
        <td>
          {{ elemento.lixtol | duasCasas }} | {{ elemento.lsxtol | duasCasas }}
        </td>
      </tr>
      <tr v-if="elemento.exibiry">
        <td :style="elemento.calculoy | calculo">Y</td>
        <td :style="'background-color:'+elemento.ycpcor">{{ elemento.ycp | duasCasas }}</td>
        <td :style="'background-color:'+elemento.ycpkcor">{{ elemento.ycpk | duasCasas }}</td>
        <td :style="'background-color:'+elemento.ymedcor">{{ elemento.ymed | duasCasas }}</td>
        <td>{{ elemento.yrange | duasCasas }}</td>
        <td>
          {{ elemento.liytol | duasCasas }} | {{ elemento.lsytol | duasCasas }}
        </td>
      </tr>
      <tr v-if="elemento.exibirz">
        <td :style="elemento.calculoz | calculo">Z</td>
        <td :style="'background-color:'+elemento.zcpcor">{{ elemento.zcp | duasCasas }}</td>
        <td :style="'background-color:'+elemento.zcpkcor">{{ elemento.zcpk | duasCasas }}</td>
        <td :style="'background-color:'+elemento.zmedcor">{{ elemento.zmed | duasCasas }}</td>
        <td>{{ elemento.zrange | duasCasas }}</td>
        <td>
          {{ elemento.liztol | duasCasas }} | {{ elemento.lsztol | duasCasas }}
        </td>
      </tr>
    </table> -->
  </div>
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
    plano: null,
  },
  methods: {
    avaliablePlano(elemento, ax) {
      return this.plano
        .filter((c) => c.caracteristica.elemento == elemento)
        .filter((c) => c.caracteristica.ax == ax);
    },
  },
};
</script>

<style scoped>
.table-condensed {
  font-size: 12px;
  padding: 0;
}
tr td,
th {
  padding: 2px !important;
}

.dot {
  height: 100px;
  width: 100px;
  background-color: #cdb19d;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  -webkit-box-shadow: -9px 14px 13px 0px rgba(50, 50, 50, 0.31);
  -moz-box-shadow: -9px 14px 13px 0px rgba(50, 50, 50, 0.31);
  box-shadow: -9px 14px 13px 0px rgba(50, 50, 50, 0.31);
}

.eixos {
  font-size: 14px;
  padding: 0px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  border-radius: 20px 20px 20px 20px;
}
.eixos span {
  padding: 2px 6px 2px 6px;
  border-radius: 10px 10px 10px 10px;
}

.planos {  
  padding: 0px;
  margin-top: 3px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;  
}

.planos span {
  padding: 3px 6px 3px 6px;
  
}

</style>