<template>
  <b-modal
    id="modal-carregando-geral"
    size="md"
    :title="$t('modal.carregando')"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop    
    hide-header-close
    header-text-center    
  >
    <b-row class="justify-content-md-center">
      <b-col md="auto" >
        <h4>{{ $t('modal.carregando') }}</h4>
        <center>
          <b-spinner class="m-5" label="Busy"></b-spinner>
        </center>        
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    count: null,
    tabs: null,
  },
};
</script>

<style scoped>

</style>