<template>
  <b-modal
    id="modal-carregando"
    size="md"
    :title=" $t('title.gerando-relatorio') "
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop    
    hide-header-close
    header-text-center
    class="background-backdrop"
  >
    <b-row class="justify-content-md-center">
      <b-col md="auto">
        <h4>{{ $t('modal.aguarde-o-processamento') }}</h4>
        <center>
          <b-spinner class="m-5" label="Busy"></b-spinner>
        </center>
        <b-progress class="w-100" :max="tabs" height="1.5rem">
          <b-progress-bar
            :value="count"
            :label="`${((count / tabs) * 100).toFixed(0)}%`"
          ></b-progress-bar>
        </b-progress>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    count: null,
    tabs: null,
  },
};
</script>

<style scoped>

</style>