<template>
  <div class="bg-gradient-success">
    <!-- Header -->
    <div class="header py-0 py-lg-8 pt-lg-2 pt-3">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white"></h1>
              <p class="text-lead text-white"></p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt3 pb-3 container-login">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <!-- <b-row class="mb-3">
            <b-col>
              <router-link to="/site" class="text-light"><b-icon icon="chevron-left" aria-hidden="true"></b-icon> {{
                $t('login.ir-para-site') }}</router-link>
            </b-col>
          </b-row> -->
          <b-card no-body class="border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <b-row class="justify-content-center">
                <img src="../assets/logo.png" alt="Logo" height="40" />
              </b-row>

              <!-- <div class="text-center text-muted mb-4 mt-2">
                {{ $t('register.subtitle') }}
              </div> -->

              <b-form role="form" @submit="onSubmit" class="pt-3">
                <b-form-group :label="$t('login.entre-com-seu-nome')" label-for="input-name" valid-feedback="Thank you!">
                  <b-form-input id="input-name" v-model="user.name" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('login.entre-com-seu-e-mail')" label-for="input-email"
                  valid-feedback="Thank you!">
                  <b-form-input id="input-email" v-model="user.email" type="email" required></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('login.entre-com-a-organizacao')" label-for="input-organization"
                  valid-feedback="Thank you!">
                  <b-form-input id="input-organization" v-model="user.organization" type="text" required></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('register.quantidade-de-usuarios')" label-for="input-plan"
                  valid-feedback="Thank you!">
                  <b-form-input id="input-plan" v-model="user.token" type="text" required></b-form-input>
                </b-form-group>

                <!-- <b-form-group :label="$t('login.entre-com-seu-plano')" label-for="input-plan"
                  valid-feedback="Thank you!">
                  <b-form-select :options="planos" v-model="user.plano_id" required></b-form-select>
                </b-form-group> -->

                <b-form-group :label="$t('login.entre-com-sua-senha')" label-for="input-senha"
                  valid-feedback="Thank you!">
                  <VuePassword v-model="user.password" id="input-senha" disableStrength type="password" required />
                </b-form-group>

                <b-form-group :label="$t('label.confirme-a-senha')">
                  <VuePassword v-model="user.password_confirmation" type="password" disableStrength required />
                </b-form-group>

                <b-form-checkbox v-model="terms">{{ $t('login.concordo-termos') }}</b-form-checkbox>
                <div class="text-center">
                  <b-button block variant="primary" v-if="carregando" disabled class="my-4">
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }}
                  </b-button>
                  <b-button block v-else type="primary" variant="primary" native-type="submit" class="my-4">{{
                    $t('botoes.cadastrar')
                  }}
                  </b-button>
                </div>
              </b-form>

              <b-alert show variant="danger" v-if="menssagem">                               
                <p v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</p>
              </b-alert>

             <!--  <b-alert show variant="danger" v-if="menssagem">
                {{ menssagem }}
              </b-alert> -->
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6" class="text-light">

              {{ $t('register.ja-possui-conta') }}
              <router-link to="/login" class="text-light">{{ $t('botoes.entrar') }}
              </router-link>

            </b-col>
            <b-col cols="6">
              <b-row align-v="center" align-h="center">
                <b-col cols="auto">
                  <b-icon icon="globe" style="color:white; padding-left: 1rem;"></b-icon>
                </b-col>
                <b-col>
                  <b-form-select size="sm" :options="linguagens" v-model="$i18n.locale"></b-form-select>
                </b-col>

              </b-row>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import VuePassword from 'vue-password';

export default {
  components: {
    VuePassword,
  },
  data() {
    return {
      terms: null,
      user: {
        name: null,
        password: null,
        email: null,
        plano_id: 'price_1MSj42LJYrcYJKcEyP0aiADt',
        organization: null,
        token: null,
        password_confirmation: null
      },
      planos: [
        { value: 'price_1MSj42LJYrcYJKcEyP0aiADt', text: "Standard" }
      ],
      menssagem: null,
      carregando: null,
    };
  },
  computed: {
    linguagens() {
      return [
        { value: "pt-BR", text: this.$t('idiomas.portugues-bra') },
        { value: "en", text: this.$t('idiomas.ingles-eua-gbr') },
        { value: "it", text: this.$t('idiomas.italiano-ita') },
        { value: "es", text: this.$t('idiomas.espanhol-esp-arg') },
      ]
    },
    mensagemErro() {
      return this.$t('login.usuario-ou-senha-invalido')
    },
    mensagemCamposVazios() {
      return this.$t('login.todos-os-campos-devem-ser-preechidos')
    }
  },
  methods: {
    onSubmit(event) {
      this.menssagem = null
      event.preventDefault();

      if (!this.user.email && !this.user.password) {
        this.menssagem = this.mensagemCamposVazios;
      }

      if (this.user.email && this.user.password) {
        this.carregando = true
        this.$store.dispatch('auth/register', this.user).then(
          () => {
            this.$router.push('/familias');
          },
          error => {
            console.log(error.response.data);
            this.menssagem = error.response.data;
            this.carregando = false;
          }
        );
      }
    }
  },
};
</script>
<style scoped>
.bg-gradient-success {
  background: rgb(31, 23, 77);
  background: linear-gradient(90deg,
      rgba(31, 23, 77, 1) 0%,
      rgba(9, 68, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  min-width: 100vh;
}

.container-login {
  margin-top: 50px;
}
</style>
