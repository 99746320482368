<template>
  <b-card v-if="fechamento" no-body>
    <table width="100%" class="comBordaComplexa" height="100%">
      <tr>
        <td>TOTAL</td>
        <td>{{fechamento.geral_cg_amarelo + fechamento.geral_cg_verde + fechamento.geral_cg_vermelho}}</td>
        <td>{{fechamento.geral_pc_cg_amarelo + fechamento.geral_pc_cg_verde + fechamento.geral_pc_cg_vermelho | duasCasas}}%</td>
        <td colspan="6" v-if="descricao" >{{descricao}} - {{desenho}}</td>
      </tr>
      <tr>
        <td style="background-color: #cce7ff">CG</td>
        <td style="background-color: #cce7ff">
          {{
            (fechamento.geral_cg_verde + fechamento.geral_cg_amarelo) | digitos(3)
          }}
        </td>
        <td style="background-color: #cce7ff">
          {{
            (fechamento.geral_pc_cg_verde + fechamento.geral_pc_cg_amarelo)
              | duasCasas
          }}%
        </td>
        <td style="background-color: #ffd7bd"> {{indicadorCp}}</td>
        <td style="background-color: #ffd7bd">QH</td>
        <td style="background-color: #ffd7bd">
          {{
            (fechamento.geral_pc_cp_verde +
              fechamento.geral_pc_cp_amarelo +
              fechamento.geral_pc_cp_vermelho)
              | duasCasas
          }}%
        </td>
        <td style="background-color: #ffd7bd"> {{indicadorCp}}K</td>
        <td style="background-color: #ffd7bd">QH</td>
        <td style="background-color: #ffd7bd">
          {{
            (fechamento.geral_pc_cpk_verde +
              fechamento.geral_pc_cpk_amarelo +
              fechamento.geral_pc_cpk_vermelho)
              | duasCasas
          }}%
        </td>
      </tr>
      <tr>
        <td style="background-color: green">CG &le; 75%</td>
        <td style="background-color: green">
          {{ fechamento.geral_cg_verde | digitos(3) }}
        </td>
        <td style="background-color: green">
          {{ fechamento.geral_pc_cg_verde | duasCasas }}%
        </td>
        <td style="background-color: green"> {{indicadorCp}} &ge; 1,33</td>
        <td style="background-color: green">
          {{ fechamento.geral_cp_verde | digitos(3) }}
        </td>
        <td style="background-color: green">
          {{ fechamento.geral_pc_cp_verde | duasCasas }}%
        </td>
        <td style="background-color: green"> {{indicadorCp}}K &ge; 1,33</td>
        <td style="background-color: green">
          {{ fechamento.geral_cpk_verde | digitos(3) }}
        </td>
        <td style="background-color: green">
          {{ fechamento.geral_pc_cpk_verde | duasCasas }}%
        </td>
      </tr>
      <tr>
        <td style="background-color: yellow">75% &lt; CG &le; 100%</td>
        <td style="background-color: yellow">
          {{ fechamento.geral_cg_amarelo | digitos(3) }}
        </td>
        <td style="background-color: yellow">
          {{ fechamento.geral_pc_cg_amarelo | duasCasas }}%
        </td>
        <td style="background-color: yellow">1 &le;  {{indicadorCp}} &lt; 1,33</td>
        <td style="background-color: yellow">
          {{ fechamento.geral_cp_amarelo | digitos(3) }}
        </td>
        <td style="background-color: yellow">
          {{ fechamento.geral_pc_cp_amarelo | duasCasas }}%
        </td>
        <td style="background-color: yellow">1 &le;  {{indicadorCp}}K &lt; 1,33</td>
        <td style="background-color: yellow">
          {{ fechamento.geral_cpk_amarelo | digitos(3) }}
        </td>
        <td style="background-color: yellow">
          {{ fechamento.geral_pc_cpk_amarelo | duasCasas }}%
        </td>
      </tr>
      <tr>
        <td style="background-color: red; color: white">CG > 100%</td>
        <td style="background-color: red; color: white">
          {{ fechamento.geral_cg_vermelho | digitos(3) }}
        </td>
        <td style="background-color: red; color: white">
          {{ fechamento.geral_pc_cg_vermelho | duasCasas }}%
        </td>
        <td style="background-color: red; color: white"> {{indicadorCp}} &lt; 1</td>
        <td style="background-color: red; color: white">
          {{ fechamento.geral_cp_vermelho | digitos(3) }}
        </td>
        <td style="background-color: red; color: white">
          {{ fechamento.geral_pc_cp_vermelho | duasCasas }}%
        </td>
        <td style="background-color: red; color: white"> {{indicadorCp}}K &lt; 1</td>
        <td style="background-color: red; color: white">
          {{ fechamento.geral_cpk_vermelho | digitos(3) }}
        </td>
        <td style="background-color: red; color: white">
          {{ fechamento.geral_pc_cpk_vermelho | duasCasas }}%
        </td>
      </tr>
    </table>
  </b-card>
</template>

<script>
export default {
  name: "BoxIndicadores",
  props: {
    fechamento: null,
    descricao:'',
    desenho:'',
    indicador: null,
  },
  computed: {
    indicadorCp() {
      return this.indicador == 'cp' ? 'CP' : 'PP';
    }
  }
};
</script>

<style scoped>
table.comBordaComplexa {
  text-align: center;
  font-weight: bold;
  border-collapse: collapse; /* CSS2 */
  background: #fffff0;
  font-size: 11px;
  padding: 0px;;
  height: 120px;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}

.subtitle {
  font-weight: 500;
  color: gray;
}
</style>