<template>
  
    <div class="card">
      <table class="text-center  table-bordered table-condensed">
        <tr>
          <th>
            <img
              :src="'../assets/simbol/' + elemento.tipo_elemento.imagem"
              width="24"
            />
          </th>
          <th colspan="3">{{ elemento.descricao }}</th>
          <th colspan="2">AUTOMARB</th>
        </tr>
        <tr>
          <th>Ax</th>
          <th>NOM</th>
          <th>MED</th>
          <th>DSV</th>
          <th colspan="2">TOLERANCE</th>
        </tr>
        <tr v-if="elemento.exibirx">
          <td :style="elemento.calculox | calculo">X</td>
          <td >{{ elemento.valor_padrao.nomx | duasCasas }}</td>
          <td>{{ elemento.valor_padrao.medx | duasCasas }}</td>
          <td :style="'background-color:'+elemento.valor_padrao.xdesvio_cor">
            {{
              (elemento.valor_padrao.nomx - elemento.valor_padrao.medx)
                | duasCasas
            }}
          </td>
          <td>{{ elemento.lixtol | duasCasas }}</td>
          <td>{{ elemento.lsxtol | duasCasas }}</td>
        </tr>
        <tr v-if="elemento.exibiry">
          <td :style="elemento.calculoy | calculo">Y</td>
          <td>{{ elemento.valor_padrao.nomy | duasCasas }}</td>
          <td>{{ elemento.valor_padrao.medy | duasCasas }}</td>
          <td :style="'background-color:'+elemento.valor_padrao.ydesvio_cor">
            {{
              (elemento.valor_padrao.nomy - elemento.valor_padrao.medy)
                | duasCasas
            }}
          </td>
          <td>{{ elemento.liytol | duasCasas }}</td>
          <td>{{ elemento.lsytol | duasCasas }}</td>
        </tr>
        <tr v-if="elemento.exibirz">
          <td :style="elemento.calculoz | calculo">Z</td>
          <td>{{ elemento.valor_padrao.nomz | duasCasas }}</td>
          <td>{{ elemento.valor_padrao.medz | duasCasas }}</td>
          <td :style="'background-color:'+elemento.valor_padrao.zdesvio_cor">
            {{
              (elemento.valor_padrao.nomz - elemento.valor_padrao.medz)
                | duasCasas
            }}
          </td>
          <td>{{ elemento.liztol | duasCasas }}</td>
          <td>{{ elemento.lsztol | duasCasas }}</td>
        </tr>
      </table>
    </div>
 
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
    controle: null,
  },
  data() {
    return {
      carregando: false,
      dados: null,
      tipo: null,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.table-condensed{
  font-size: 12px;
  padding: 0;
}
tr td,th{
  padding: 2px !important;
  
}

</style>
