<template>
    <!-- ======= Footer ======= -->
    <footer id="footer">

        <div class="footer-newsletter">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <h4>{{ $t('footer.receba-nossa-newsletter') }}</h4>
                        <p>{{ $t('footer.inscreva-se-para-ficar-sabendo-das-nossas-novidades') }} </p>
                        <form action="" method="post">
                            <input type="email" name="email"><input type="submit" :value="$t('footer.se-inscrever')">
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-top">
            <div class="container">
                <div class="row">

                    <div class="col-lg-4 col-md-6 footer-contact">
                        <h3>AutoMarb</h3>
                        <p>
                            Rua Cubatão, Nº: 379 <br>
                            Monte Castelo, CEP: 32285-170<br>
                            Brasil <br><br>
                            <strong>{{ $t('contact.telefone') }}</strong> +55 31 99289-0133<br>
                            <strong>Email:</strong> negocios@comarb.com.br<br>
                        </p>
                    </div>

                    <div class="col-lg-4 col-md-6 footer-links">
                        <h4>{{ $t('footer.links-uteis') }}</h4>
                        <ul>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="#hero">{{ $t('site-menu.home') }}</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="#about">{{ $t('site-menu.about') }}</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="#services">{{ $t('site-menu.services') }}</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="#hero">{{ $t('footer.termos-de-servico') }}</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="#hero">{{ $t('footer.politica-de-privacidade') }}</router-link></li>
                        </ul>
                    </div>
                   

                    <div class="col-lg-4 col-md-6 footer-links">
                        <h4>{{ $t('footer.nossas-redes-sociais') }}</h4>                        
                        <div class="social-links mt-3">
                            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                            <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                            <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="container footer-bottom clearfix">
            <div class="copyright">
                &copy; Copyright <strong><span>AutoMarb</span></strong>. {{ $t('footer.todos-os-direitos-reservados') }}
            </div>
            <div class="credits">                
            </div>
        </div>
    </footer><!-- End Footer -->
</template>

<script>
export default {

}
</script>

<style>

</style>