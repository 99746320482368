<template>
  <div class="apresentacoes pt-3">
   

      <b-breadcrumb>
        <b-breadcrumb-item active class="align-middle">
          <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
          {{ $t("breadcrumb.apresentacoes")}}
        </b-breadcrumb-item>
        <b-button variant="secondary" @click="showModalNovoApresentacaoGeral()" class="ml-auto" size="sm" v-if="user.permissao =='acesso_total' ">
          <b-icon-plus />{{ $t('botoes.nova-apresentacao') }}
        </b-button>
      </b-breadcrumb>      

      <b-card class="mt-3" no-body>
        <b-table :items="apresentacoes" :fields="fields" responsive="sm" head-variant="light" show-empty class="mb-0" :emptyText="$t('global.table-empty')">
          <template #cell(Ações)="row">           
            <b-button size="sm" @click="showModalApresentacaoGeral(row.item)">
              {{ $t('botoes.gerarApresentacao') }} </b-button>
          </template>
        </b-table>
      </b-card>    

    <apresentacao-familia ref="ApresentacaoFechamento" @gerou="gerou($event)" @gerouImage="gerouImage()"
      v-show="carregando" />
    <apresentacao-relatorio ref="ApresentacaoRelatorio" @gerou="gerou($event)" @gerouImage="gerouImage()"
      v-show="carregando" />

    <modal-apresentacao-geral :familia="familia" :apresentacao_id="apresentacao" @listaMudou="carregarApresentacoes()"
      @gerarApresentacao="gerarApresentacao($event)">
    </modal-apresentacao-geral>
    <modal-carregando-geral />
    <modal-carregando :count="quantidade_paginas_geradas" :tabs="quantidade_paginas" />
  </div>
</template>

<script>
import ModalApresentacaoGeral from "@/components/modal/ApresentacaoGeral.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import ModalCarregando from "@/components/modal/Carregando.vue";

import ApresentacaoFamilia from "@/components/apresentacoes/Familia.vue";
import ApresentacaoRelatorio from "@/components/apresentacoes/Relatorio.vue";

export default {
  name: "Apresentacoes",
  components: {
    ModalApresentacaoGeral,
    ModalCarregandoGeral,
    ApresentacaoFamilia,
    ApresentacaoRelatorio,
    ModalCarregando,
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    semanas: {
      get() {
        return this.$store.state.semanas;
      },
      set(value) {
        this.$store.commit("setSemanas", value);
      },
    },
  },
  data() {
    return {
      familia: {
        id: null,
        descricao: null,
        imagem: null,
        componentes: [],
      },
      resultado: null,
      apresentacao: null,
      optionsApresentacoes: [],
      carregando: false,
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key:  'Apresentação', label:  this.$t('title.apresentacao') , sortable: true },
        { key:  'Fornecedor',label:  this.$t('table.title.fornecedor') , sortable: true },
        { key:  'Responsáveis',label:  this.$t('table.title.responsaveis') , sortable: true },
        { key:  'Ações', label:  this.$t('table.title.acoes') , sortable: false },
      ],
      apresentacoes: [],
      dadosApresentacao: [],
      index: 0,
      itens: [],
      url: null,
      quantidade_paginas: 0,
      quantidade_paginas_geradas: 0,
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    enviarDadosApresentacao() {
      let formData = new FormData();

      formData.append("apresentacao_id", this.apresentacao);
      formData.append("semana", this.semana);
      formData.append("ano", this.ano);
      formData.append("tipo", "apresentacao");
      formData.append("quantidade_itens", this.dadosApresentacao.length);

      this.dadosApresentacao.forEach((item, i) => {
        if (item.tipo == "fechamento-geral") {
          formData.append("item_id[" + i + "]", item.item_id);
          formData.append("tipo_apresentacao[" + i + "]", "fechamento");

          if (item.imagens.fechamentoConformidade) {
            item.imagens.fechamentoConformidade.forEach((element) => {
              formData.append(
                "fechamentoConformidade[" + i + "][]",
                element.src
              );
            });
          }
          if (item.imagens.fechamentoConformidadePorPeca) {
            item.imagens.fechamentoConformidadePorPeca.forEach((element) => {
              formData.append(
                "fechamentoConformidadePorPeca[" + i + "][]",
                element.src
              );
            });
          }
          if (item.imagens.fechamentoCp) {
            item.imagens.fechamentoCp.forEach((element) => {
              formData.append("fechamentoCp[" + i + "][]", element.src);
            });
          }
          if (item.imagens.fechamentoCpPorPeca) {
            item.imagens.fechamentoCpPorPeca.forEach((element) => {
              formData.append("fechamentoCpPorPeca[" + i + "][]", element.src);
            });
          }
          if (item.imagens.fechamentoCpk) {
            item.imagens.fechamentoCpk.forEach((element) => {
              formData.append("fechamentoCpk[" + i + "][]", element.src);
            });
          }
          if (item.imagens.fechamentoCpkPorPeca) {
            item.imagens.fechamentoCpkPorPeca.forEach((element) => {
              formData.append("fechamentoCpkPorPeca[" + i + "][]", element.src);
            });
          }
        } else if (item.tipo == "componente") {
          formData.append("item_id[" + i + "]", item.item_id);
          formData.append("tipo_apresentacao[" + i + "]", "relatorio");

          if (item.imagens.capa) {
            item.imagens.capa.forEach((element) => {
              formData.append("capa[" + i + "][]", element.src);
            });
          }
          if (item.imagens.workflow) {
            item.imagens.workflow.forEach((element) => {
              formData.append("workflow[" + i + "][]", element.src);
            });
          }
          if (item.imagens.relatorioConformidade) {
            item.imagens.relatorioConformidade.forEach((element) => {
              formData.append(
                "relatorioConformidade[" + i + "][]",
                element.src
              );
            });
          }
          if (item.imagens.relatorioCPCPK) {
            item.imagens.relatorioCPCPK.forEach((element) => {
              formData.append("relatorioCPCPK[" + i + "][]", element.src);
            });
          }
          if (item.imagens.relatorioBoleado) {
            item.imagens.relatorioBoleado.sort(function (a, b) {
              if (a.ordem > b.ordem) {
                return 1;
              }
              if (a.ordem < b.ordem) {
                return -1;
              }
              return 0;
            });

            item.imagens.relatorioBoleado.forEach((element) => {
              formData.append("relatorioBoleado[" + i + "][]", element.img.src);
            });
          }
          if (item.imagens.relatorioCpk) {
            item.imagens.relatorioCpk.sort(function (a, b) {
              if (a.ordem > b.ordem) {
                return 1;
              }
              if (a.ordem < b.ordem) {
                return -1;
              }
              return 0;
            });
            item.imagens.relatorioCpk.forEach((element) => {
              formData.append("relatorioCpk[" + i + "][]", element.img.src);
            });
          }
          if (item.imagens.relatorioIndividual) {
            item.imagens.relatorioIndividual.sort(function (a, b) {
              if (a.ordem > b.ordem) {
                return 1;
              }
              if (a.ordem < b.ordem) {
                return -1;
              }
              return 0;
            });
            item.imagens.relatorioIndividual.forEach((element) => {
              formData.append("relatorioIndividual[" + i + "][]", element.img.src);
            });
          }
          if (item.imagens.planoDeAcao) {
            item.imagens.planoDeAcao.forEach((element) => {
              formData.append("planoDeAcao[" + i + "][]", element.src);
            });
          }
        }
      });

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        maxContentLength: 1000000000000,
        maxBodyLength: 10000000000000,
      };

      this.$http
        .post("api/apresentacao", formData, config)
        .then((response) => {
          var path =
            this.url + "/" + response.data.arquivo;
          var nome = response.data.arquivo;

          this.download(path, nome);

          setTimeout(() => {
            this.makeToast("success");
            this.capturing = false;
            this.carregando = false;
            this.count = 0;
            document.getElementById("app").style.backgroundColor = "";
            document.getElementById("app").style.opacity = "";

            this.$bvModal.hide("modal-carregando-geral");
            this.$bvModal.hide("modal-carregando");
          }, 300);
        })
        .catch((errors) => {
          if (errors.response) {
            this.menssagem = errors.response.data;
          }

          this.carregando = false;
          this.capturing = false;
          this.count = 0;
          document.getElementById("app").style.backgroundColor = "";
          document.getElementById("app").style.opacity = "";

          var els = document.getElementsByClassName("tab-pane");
          Array.prototype.forEach.call(els, function (el, index) {
            if (index == 0) {
              el.style.display = "block";
            } else {
              el.style.display = "none";
            }
          });

          this.tabIndex = 0;
          this.$bvModal.hide("modal-carregando");
        });
    },
    download(file, nome) {
      window.location.href = file;
    },
    gerou(imagens) {
      this.dadosApresentacao.push(imagens);
      this.index++;
      this.gerarApresentacao(this.itens);
    },
    gerouImage() {
      this.quantidade_paginas_geradas++;
    },
    gerarApresentacao(a) {
      //this.$bvModal.show("modal-carregando-geral");
      this.$bvModal.show("modal-carregando");
      this.$bvModal.hide("modal-apresentacao-geral");
      this.carregando = true;

      var els = document.getElementsByClassName("tab-pane");
      Array.prototype.forEach.call(els, function (el) {
        el.style.display = "block";
      });

      document.getElementById("app").style.backgroundColor = "#e4e4e4";
      document.getElementById("app").style.opacity = 0;

      this.itens = a;
      if (a.itens.length > 0 && this.index < a.itens.length) {
        let item = a.itens[this.index];

        if (item.type == "fechamento-geral") {
          this.quantidade_paginas += 6;
        } else if (item.type == "componente") {
          this.quantidade_paginas += 8;
        }

        if (item.type == "fechamento-geral") {
          this.$refs.ApresentacaoFechamento.carregarFechamentoGeral(
            this.ano,
            a.semana,
            item.value
          );
        } else if (item.type == "componente") {
          this.$refs.ApresentacaoRelatorio.carregarRelatorio(
            this.ano,
            a.semana,
            item.value
          );
        }
      } else {
        setTimeout(() => {
          this.enviarDadosApresentacao();
        }, 300);
      }
    },
    showModalNovoApresentacaoGeral() {
      this.apresentacao = null;
      this.$bvModal.show("modal-apresentacao-geral");
    },
    showModalApresentacaoGeral(item) {
      this.apresentacao = item.Id;
      this.$bvModal.show("modal-apresentacao-geral");
    },
    carregarApresentacoes() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/apresentacao-geral")
        .then((response) => {
          this.optionsApresentacoes = [];
          this.apresentacoes = [];
          response.data.forEach((element) => {
            this.optionsApresentacoes.push({
              value: element.id,
              text: element.descricao,
            });

            var resp = "";
            element.responsaveis.forEach((responsavel) => {
              resp += responsavel.user.name;
            });

            this.apresentacoes.push({
              Id: element.id,
              Apresentação: element.descricao,
              Fornecedor: element.fornecedor,
              Responsáveis: resp,
            });            
          });
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    carregarApresentacaoGeral() {
      if (this.apresentacao != null) {
        this.$bvModal.show("modal-carregando-geral");
        this.carregando = true;
        this.menssagem = null;
        this.resultado = this.limpar;
        this.$http
          .get("api/apresentacao-geral/" + this.apresentacao)
          .then((response) => {
            this.resultado = response.data;
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          });
      }
    },
  },
  mounted() {
    this.carregarApresentacoes();
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    apresentacao: function () {
      this.carregarApresentacaoGeral();
    },
    ano: function () {
      this.carregarApresentacaoGeral();
    },
    semana: function () {
      this.carregarApresentacaoGeral();
    },
  },
};
</script>


<style>
</style>