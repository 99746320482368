<template>
        <!-- ======= About Us Section ======= -->
        <section id="about" class="about">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>{{ $t('about.about-us') }}</h2>
          </div>

          <div class="row content">
            <div class="col-lg-6">

              <p>{{ $t('about.paragrafo-1') }}</p>
              <p>{{ $t('about.paragrafo-2') }}</p>
              
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <p>{{ $t('about.paragrafo-3') }}</p>
              <p>{{ $t('about.paragrafo-4') }}</p>              
            </div>
          </div>

        </div>
      </section><!-- End About Us Section -->
</template>

<script>
export default {

}
</script>

<style>

</style>