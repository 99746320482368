<template>
  <div class="relatorio">
    <b-alert show variant="danger" v-if="menssagem">
      <span
        ><b-icon icon="exclamation-octagon" aria-hidden="true"></b-icon>
        {{ menssagem }}</span
      >
    </b-alert>
    <div v-else>
      

      <div
        v-on:scroll.passive="scrollPagina"
        v-b-scrollspy
        v-for="(pagina, index) in resultado.paginas"
        :key="pagina.id"
        :ref="'pagina_individual' + index"
        class="mb-3 pagina"
      >
       <b-row class="relatorio-cabecalho mb-2" v-if="index == 0">
        <b-col cols="3">
          <box-indicadores-conformidade
            :fechamento="resultado.fechamento"
            :descricao="componente.descricao"
            :desenho="componente.desenho"
            v-if="componente"
            :indicador="indicador"
          />
        </b-col>
        <b-col cols="5" class="p-0">
          <table class="table-bordered detalhes-controle">
            <tr>
              <td colspan="2" class="titulo">
                {{ componente.descricao }} - {{ componente.desenho }}
              </td>
            </tr>
            <tr>
              <td class="titulo">Relatório Dimensional</td>
              <td rowspan="3">
                <b-img
                  center
                  height="80"
                  :src="componente.imagem | urlImagem"
                />
              </td>
            </tr>
            <tr>
              <td>
                <b-form-radio-group
                  id="status_controle"
                  v-model="status_controle"
                  name="status_controle"
                  size="sm"
                  class="p-1"
                >
                  <b-form-radio value="aprovado" class="radio-status">Aprovado</b-form-radio>
                  <b-form-radio value="reprovado" class="radio-status">Reprovado</b-form-radio>
                  <b-form-radio value="liberado" class="radio-status">Liberado</b-form-radio>
                </b-form-radio-group>
              </td>
            </tr>
            <tr>
              <td>
                <b-row>
                  <b-col>
                    <b>Data:</b>
                    {{
                      new Date(
                        resultado.controle.dh_controle
                      ).toLocaleDateString("pt-br")
                    }}
                  </b-col>
                  <b-col>
                    <b>Hora:</b
                    >{{
                      new Date(
                        resultado.controle.dh_controle
                      ).toLocaleTimeString("pt-br")
                    }}
                  </b-col>
                  <b-col v-if="resultado.controle.lote"><b>Lote:</b> {{resultado.controle.lote}}</b-col>
                </b-row>
                
              </td>
            </tr>
          </table>
        </b-col>
        <b-col cols="4" class="pl-0 pr-0"
          ><b-card no-body class="container-risk">
            <risk-bar
              ref="riskbar"
              v-if="resultado"
              :risks="resultado.risk.quantidade"
              :cores="resultado.risk.cor"
              :categorias="resultado.risk.label"
            />
            <risk-legenda /> </b-card
        ></b-col>
      </b-row>

        <b-overlay :show="carregando" rounded="sm" opacity="1">
          <div
           :class="index == 0 ? 'pagina1 border' : 'pagina border'"
            :id="'paginaindividual' + pagina.id"
            :ref="'pagina' + pagina.id"
            :style="{
              'background-image':
                'url(' + url + '/organization/' + user.tenant_id + '/api/storage/' + pagina.imagem + ')',
            }"
          >

          <v-stage :config="index == 0 ? stage1Size: stageSize" class="kanva" :ref="'stage'+ pagina.id">
            <v-layer>
              <v-line
                v-for="line in avaliableLinhas(pagina.id)"
                :key="line.id"
                :config="{
                  stroke: 'black',
                  strokeWidth: 1,
                  points: line.points,
                }"
              />
            </v-layer>
          </v-stage>
            <elemento-componente
              v-for="elemento in pagina.elementos"
              :key="'caixa' + elemento.id"
              :elemento="elemento"
              tipo="individual"
              :style="
                'left:' +
                elemento.posicaocaixal +
                'px; top:' +
                elemento.posicaocaixat +
                'px;'
              "
            >
            </elemento-componente>

            <ponto-componente
              v-for="ponto in pagina.elementos"
              :key="'ponto' + ponto.id"
              :ponto="ponto"
              tipo="individual"
              :style="
                'left:' +
                ponto.posicaopontol +
                'px; top:' +
                ponto.posicaopontot +
                'px;'
              "
            >
            </ponto-componente>
          </div>
        </b-overlay>
        <b-row align-v="end" class="align-self-end paginacao">
          <b-col class="text-center"
            >{{ index + 1 }}/{{ resultado.paginas.length }}</b-col
          >
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ElementoComponente from "@/components/Elemento.vue";
import PontoComponente from "@/components/Ponto.vue";
import BoxIndicadoresConformidade from "@/components/BoxIndicadoresConformidade.vue";
import LeaderLine from "leader-line-vue";
import RiskLegenda from "@/components/RiskLegenda.vue";
import RiskBar from "@/components/RiskBar.vue";

export default {
  name: "RelatorioIndividual",
  props: {
    resultado: {
      paginas: null,
      individual: {
        fechamento: null,
      },
    },
    componente: null,
    indicador: null
  },
  components: {
    ElementoComponente,
    PontoComponente,
    BoxIndicadoresConformidade,
    RiskLegenda,
    RiskBar   
  },
  data() {
    return {
      url:null,
      status_controle:'liberado',
      windowTop: 0,
      carregando: false,      
      connections: [],
      menssagem: null,
      stageSize: {
        width: 1035,
        height: 652,
      },
      stage1Size: {
        width: 1035,
        height: 507,
      },
    };
  },
  methods: {
    avaliableLinhas(p_id) {
      return this.connections
        .filter((c) => c.pagina == p_id);
    },
    
    carregarRelatorio() {
      this.carregando = true;
      this.menssagem = null;
      
      this.connections = [];

      if (this.$refs.riskbar) {
        this.$refs.riskbar[0].updateGrafico();
      }      

      setTimeout(() => {
        this.carregar();
      }, 300);
    },
    carregar() {
      this.carregando = false;

      for (const keyPagina in this.resultado.paginas) {
        var elementos = this.resultado.paginas[keyPagina].elementos;

        for (const key in elementos) {
         
          var pontoRec = document.getElementById("pontoindividual" + elementos[key].id).getBoundingClientRect()         
          var caixaRec = document.getElementById("caixaindividual" + elementos[key].id).getBoundingClientRect()          

          var pontox = parseFloat(
            document
              .getElementById("pontoindividual" + elementos[key].id)
              .style.left.slice(0, -2)
          );
          var pontoy = parseFloat(
            document
              .getElementById("pontoindividual" + elementos[key].id)
              .style.top.slice(0, -2)
          );
          var caixax = parseFloat(
            document
              .getElementById("caixaindividual" + elementos[key].id)
              .style.left.slice(0, -2)
          );
          var caixay = parseFloat(
            document
              .getElementById("caixaindividual" + elementos[key].id)
              .style.top.slice(0, -2)
          );    
          
          var conX = 0;
          var conY = 0;
          switch (elementos[key].connection) {
            case 1:
                conX = caixaRec.width / 2
                conY = caixaRec.height / 2
                break;
            case 2:
                conX = 0
                conY = 0
                break;
            case 3:
                conX = caixaRec.width / 2
                conY = 0
                break;
            case 4:
                conX = caixaRec.width
                conY = 0
                break;
            case 5:
                conX = caixaRec.width
                conY = caixaRec.height / 2
                break;
            case 6:
                conX = caixaRec.width
                conY = caixaRec.height
                break;
            case 7:
                conX = caixaRec.width / 2
                conY = caixaRec.height
                break;
            case 8:
                conX = 0
                conY = caixaRec.height
                break;
            case 9:
                conX = 0
                conY = caixaRec.height / 2
                break;
            default:
                conX = caixaRec.width / 2
                conY = caixaRec.height / 2
          }

          this.connections.push({
            id: 'linha'+ elementos[key].id,
            pagina: this.resultado.paginas[keyPagina].id,
            points: [
              pontox + (pontoRec.width / 2), 
              pontoy + (pontoRec.height / 2), 
              caixax + conX,
              caixay + conY,
              ],
          });
        }
      }
    },
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    },
  },
  mounted() {
    this.carregarRelatorio();
    window.addEventListener("scroll", this.onScroll);
    this.url = process.env.VUE_APP_ROOT_API
  },
  watch: {
    resultado: {
      handler() {
        this.carregarRelatorio();
      },
      deep: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  
};
</script>

<style scoped>
.container-pagina {
  /* overflow-y: auto; */
}
.pagina {
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.pagina1 {
  width: 1035px;
  height: 507px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-size: cover;
}

.container-indicadores {
  height: 140px;
}
.container-risk {
  height: 120px;
}

.detalhes-controle {
  font-size: 14px;
  height: 120px;
}
.detalhes-controle .titulo{  
  font-weight: bold;
}


tr td,
thead,
th {
  padding: 2px !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle !important;
}

.kanva{
  position: absolute;
  padding: 0px;
  margin: 0px;
}

.relatorio-cabecalho {
  width: 1050px;
}
.paginacao{
 /* margin-top: -30px; */
 font-size: 10px;
}
</style>