<template>
    <div>
        <b-row align-v="stretch">
            <b-col sm="2">
                <b-card bg-variant="light" :header="$t('header.selecionar-caracteristicas')">
                    <b-form-select v-model="caracteristica_selecionada" :options="optionsCaracteristicas"
                        :select-size="29">
                    </b-form-select>
                </b-card>
            </b-col>
            <b-col sm="8">
                <b-card>
                    <div id="wrapper">
                        <div id="chart-area">
                            <apexchart type="area" height="500" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col sm="2" class="bold">
                <b-card>
                    <b-row align-h="center">
                        {{ trend.caracteristicas[caracteristica_selecionada].ponto }}
                        {{ trend.caracteristicas[caracteristica_selecionada].ax.toUpperCase() }}
                    </b-row>
                    <b-row align-h="center">
                        Especificado: {{ trend.caracteristicas[caracteristica_selecionada].specifieds[0] | duasCasas }}
                    </b-row>
                    <b-row align-h="center">
                        {{ trend.caracteristicas[caracteristica_selecionada].desvios.length }} Controle(s)
                    </b-row>
                </b-card>

                <b-card class="mt-3">
                    <b-row>
                        <b-col>CP: {{ trend.caracteristicas[caracteristica_selecionada].cp | duasCasas }}</b-col>
                        <b-col>CPK: {{ trend.caracteristicas[caracteristica_selecionada].cpk | duasCasas }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col>Media: {{ trend.caracteristicas[caracteristica_selecionada].med[0] | duasCasas }}</b-col>
                        <b-col>Range: {{ trend.caracteristicas[caracteristica_selecionada].range[0] | duasCasas }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col> <span class="red">LIE:</span> {{
                        trend.caracteristicas[caracteristica_selecionada].lie[0] | duasCasas }}</b-col>
                        <b-col> <span class="red">LSE:</span> {{
                        trend.caracteristicas[caracteristica_selecionada].lse[0] | duasCasas }}</b-col>
                    </b-row>
                    <b-row align-h="center">
                        <b-col> <span class="blue">Desvio Padrão:</span> {{
                        trend.caracteristicas[caracteristica_selecionada].sigma | duasCasas }}</b-col>
                    </b-row>
                </b-card>
                <b-card class="mt-3">
                    Legenda
                    <GaussLegenda />
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>
  
<script>
import GaussLegenda from "./GaussLegenda.vue";
export default {
    props: {
        trend: null,
    },
    data() {
        return {
            newCategories: [],
            newSeriesLeft: [],
            newSeries: [],
            newSeriesRigth: [],
            caracteristica_selecionada: 0,
            optionsCaracteristicas: [],
            series: [
                { data: [] },
                { data: [] },
                { data: [] }
            ],
            chartOptions: {
                chart: {
                    type: "area",
                    height: 350,
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "round",
                    colors: undefined,
                    width: 1,
                    dashArray: 0,
                },
                title: {
                    text: "Distribuição normal",
                    align: "left"
                },
                subtitle: {
                    text: "Análise",
                    align: "left"
                },
                xaxis: {
                    tickAmount: 24,
                    type: 'numeric',
                    categories: []
                },
                yaxis: {
                    decimalsInFloat: 2,
                    opposite: true
                },
                legend: {
                    show:false,
                    horizontalAlign: "left"
                },
                grid: {
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            },
        };
    },
    mounted() {
        this.updateGrafico();
        this.carregarCaracteristicas();
    },
    methods: {
        carregarCaracteristicas() {
            this.menssagem = null;
            this.optionsCaracteristicas = [];
            this.trend.caracteristicas.forEach((caract, index) => {
                this.optionsCaracteristicas.push({
                    value: index,
                    text: caract.ponto + " - " + caract.ax.toUpperCase(),
                });
            });
        },
        updateGrafico() {
            this.newCategories = []
            this.newSeriesLeft = []
            this.newSeriesRigth = []
            this.newSeries = []
            var minDesvio = Math.min.apply(Math, this.trend.caracteristicas[this.caracteristica_selecionada].desvios);
            var maxDesvio = Math.max.apply(Math, this.trend.caracteristicas[this.caracteristica_selecionada].desvios);
            var xBegin = Math.min.apply(Math, [this.trend.caracteristicas[this.caracteristica_selecionada].lie[0], minDesvio]) - 0.5;
            var xEnd = Math.max.apply(Math, [this.trend.caracteristicas[this.caracteristica_selecionada].lse[0], maxDesvio]) + 0.5;
            var gauss_var = this.trend.caracteristicas[this.caracteristica_selecionada].sigma;
            var gauss_mean = this.trend.caracteristicas[this.caracteristica_selecionada].med[0];
            var nbiter = 450;

            for (var i = 0; i <= nbiter; i++) {
                this.newCategories[i] = xBegin + i * (xEnd - xBegin) / nbiter;
                var vary = xBegin + i * (xEnd - xBegin) / nbiter;
                var valor = (1 / (gauss_var * Math.sqrt(2 * Math.PI))) * Math.exp(-((this.newCategories[i] - gauss_mean) * (this.newCategories[i] - gauss_mean)) / (2 * gauss_var));

                if (vary <= this.trend.caracteristicas[this.caracteristica_selecionada].lie[0]) {
                    this.newSeriesLeft[i] = valor
                } else if (vary >= this.trend.caracteristicas[this.caracteristica_selecionada].lse[0]) {
                    this.newSeriesRigth[i] = valor
                } else {
                    this.newSeries[i] = valor
                }
            }

            this.series[0].data = this.newSeriesLeft;
            this.series[1].data = this.newSeries;
            this.series[2].data = this.newSeriesRigth;

            this.chartOptions = {
                stroke: {
                    width: 0
                },
                colors: ['#cc0000', '#0099ff', '#cc0000'],
                fill: {
                    type: 'solid',
                    colors: ['#cc0000', '#0099ff', '#cc0000']
                },
                annotations: {
                    xaxis: [{
                        x: this.trend.caracteristicas[this.caracteristica_selecionada].lie[0],
                        strokeDashArray: 0,
                        borderColor: "#cc0000",
                        label: {
                            borderColor: "#cc0000",
                            style: {
                                color: "#fff",
                                background: "#cc0000",
                            },
                            text: "LIE",
                        }
                    }, {
                        x: this.trend.caracteristicas[this.caracteristica_selecionada].lse[0],
                        strokeDashArray: 0,
                        borderColor: "#cc0000",
                        label: {
                            borderColor: "#cc0000",
                            style: {
                                color: "#fff",
                                background: "#cc0000",
                            },
                            text: "LSE",
                        }
                    }, {
                        x: this.trend.caracteristicas[this.caracteristica_selecionada].med[0],
                        strokeDashArray: 0,
                        borderColor: "#000000",
                        label: {
                            borderColor: "#000000",
                            style: {
                                color: "#fff",
                                background: "#000000",
                            },
                            text: "Mean",
                        }
                    }]
                },
                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return parseFloat(value).toFixed(2);
                        },
                    },
                    categories: this.newCategories,
                },
            };

        },
    },
    watch: {
        caracteristica_selecionada: function () {
            this.updateGrafico();
        },
    },
    components: { GaussLegenda }
};
</script>
<style scoped>
.red {
    color: red;
}

.blue {
    color: blue;
}

.bold {
    font-weight: bold;
}
</style>
  