<template>
  <b-modal
    id="modal-config-relatorio"
    size="lg"
    :title=" $t('title.automarb-configuracoes-do-relatorio') "
  >
    <b-card no-body>
      <b-alert show variant="danger" v-if="menssagem">
        <ul v-if="menssagem">
          <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
        </ul>
      </b-alert>
      <b-tabs card>
        <b-tab :title=" $t('title.conformidade') " active v-if="grafico == 'conformidade'">
          <b-card-text>
            <b-card bg-variant="light" :header="$t('header.fechamento-grafico-em-barra')">
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.dimensao') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.cg_dimensao">
                      <b-form-select-option
                        v-for="n in 60"
                        :key="n"
                        :value="n"
                        >{{ n}}</b-form-select-option
                      >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.periodo') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.cg_periodo">
                      <b-form-select-option
                        v-for="n in 60"
                        :key="n"
                        :value="n"
                        >{{ n}}</b-form-select-option
                      >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.objetivo') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :options="obj"
                      v-model="form.cg_forecast"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
          </b-card-text>
        </b-tab>
        <b-tab title="Cp/Cpk" v-if="grafico == 'cpk'">
          <b-card-text>
            <b-card
              bg-variant="light"
              header="Fechamento CP (Gráfico em Barra)"
            >
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.dimensao') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.cp_dimensao">
                      <b-form-select-option
                        v-for="n in 60"
                        :key="n"
                        :value="n"
                        >{{ n }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.periodo') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.cp_periodo">
                      <b-form-select-option
                        v-for="n in 60"
                        :key="n"
                        :value="n"
                        >{{ n }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.objetivo') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :options="obj"
                      v-model="form.cp_forecast"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>

            <b-card
              bg-variant="light"
              header="Fechamento CPK (Gráfico em Barra)"
              class="mt-3"
            >
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.dimensao') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.cpk_dimensao">
                      <b-form-select-option
                        v-for="n in 60"
                        :key="n"
                        :value="n"
                        >{{ n }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.periodo') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.cpk_periodo">
                      <b-form-select-option
                        v-for="n in 60"
                        :key="n "
                        :value="n "
                        >{{ n  }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group>
                <b-row align-v="center">
                  <b-col>
                    <label class="mr-sm-2">{{ $t('label.objetivo') }}</label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :options="obj"
                      v-model="form.cpk_forecast"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8"> </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t('botoes.salvar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    relatorio: null,
    grafico:null
  },
  data() {
    return {
      form: {
        cg_periodo: 35,
        cg_dimensao: 35,
        cg_forecast: 1,

        cpk_periodo: 35,
        cpk_dimensao: 35,
        cpk_forecast: 0.9,

        cp_periodo: 35,
        cp_dimensao: 35,
        cp_forecast: 0.95,
      },
      obj: [
        { value: 0.0, text: 0.0 },
        { value: 0.05, text: 0.05 },
        { value: 0.1, text: 0.1 },
        { value: 0.15, text: 0.15 },
        { value: 0.2, text: 0.2 },
        { value: 0.25, text: 0.25 },
        { value: 0.3, text: 0.3 },
        { value: 0.35, text: 0.35 },
        { value: 0.4, text: 0.4 },
        { value: 0.45, text: 0.45 },
        { value: 0.5, text: 0.5 },
        { value: 0.55, text: 0.55 },
        { value: 0.6, text: 0.6 },
        { value: 0.65, text: 0.65 },
        { value: 0.7, text: 0.7 },
        { value: 0.75, text: 0.75 },
        { value: 0.8, text: 0.8 },
        { value: 0.85, text: 0.85 },
        { value: 0.9, text: 0.9 },
        { value: 0.95, text: 0.95 },
        { value: 1.0, text: 1.0 },
      ],
      show: true,
      carregando: false,
      menssagem: null,
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    carregarConfiguracoes() {
      this.carregando = true;
      this.$http
        .get("api/graficobarra/" + this.relatorio)
        .then((response) => {
          this.form = response.data;
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response;
          this.carregando = false;
        });
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .put("api/graficobarra/"+ this.relatorio, this.form, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("config-geral");
          this.$emit("configMudou");
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarConfiguracoes();
  },
};
</script>

<style scoped>
</style>