<template>
  <b-modal id="modal-apresentacao-geral" size="xl" :title="$t('title.apresentacaoGeral')">
    <template #modal-header>
      <h5>{{ $t('title.apresentacaoGeral') }}</h5>
      <p class="h4 btn">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>

    <b-form v-if="show">
      <b-card no-body>
        <b-alert show variant="danger" v-if="menssagem">
          {{ menssagem }}
        </b-alert>

        <b-tabs card>
          <b-tab :title="$t('modal.dados-gerais-exibicao')" active>
            <b-card-text>
              <b-row>
                <b-col sm="6">
                  <b-card bg-variant="light" :header="$t('header.dados-da-tela-inicial')">
                    <b-form-group :label="$t('label.nome-da-apresentacao')">
                      <b-form-input v-model="form.descricao" type="text"
                        :placeholder="$t('placeholder.entre-com-o-nome')" required>
                      </b-form-input>
                    </b-form-group>

                    <b-form-group :label="$t('label.fornecedor-descricao')">
                      <b-form-input id="input-live" v-model="form.fornecedor"
                        :placeholder="$t('placeholder.entre-com-o-fornecedor')">
                      </b-form-input>
                    </b-form-group>

                    <b-form-group label="Semana:">
                      <select class="form-control" v-model="form.semana">
                        <option v-for="n in semanas" :key="n" :value="n">
                          {{ n }}
                        </option>
                      </select>
                    </b-form-group>

                    <b-form-group :label="$t('label.responsavel')" label-for="tags-with-dropdown">
                      <multiselect v-model="form.responsaveis" :options="availableOptionsResponsaveis" :multiple="true"
                        :placeholder="$t('placeholder.selecione-o-responsavel')" track-by="text" label="text"><span
                          slot="noResult">{{ $t('error.nao-existe-responsaveis-disponiveis') }}</span></multiselect>
                    </b-form-group>

                    <b-form-group :label="$t('label.local')">
                      <b-form-input id="input-live" v-model="form.local"
                        :placeholder="$t('laceholder.entre-com-o-local')"></b-form-input>
                    </b-form-group>

                    <b-form-group :label="$t('label.data')">
                      <b-form-datepicker v-model="form.data" locale="pt-BR"></b-form-datepicker>
                    </b-form-group>
                  </b-card>
                </b-col>
                <b-col sm="6">
                  <b-card bg-variant="light" :header="$t('header.exibicao')">
                    <b-form-checkbox v-model="form.print_workflow">WorkFlow</b-form-checkbox>
                    <b-form-checkbox v-model="form.print_conformidade">{{ $t('modal.conformidade') }}</b-form-checkbox>
                    <b-form-checkbox v-model="form.print_cpk">Cp&Cpk / Pp&Ppk</b-form-checkbox>
                    <b-form-checkbox v-model="form.print_boleado">{{ $t('modal.relatorioBoleado') }}</b-form-checkbox>
                    <b-form-checkbox v-model="form.print_relatorio">{{ $t('modal.relatorioConvecional') }}
                    </b-form-checkbox>
                    <b-form-checkbox v-model="form.print_individual">{{ $t('modal.relatorioIndividual')
                    }}</b-form-checkbox>
                    <b-form-checkbox v-model="form.print_plano">{{ $t('modal.planoDeAcao') }}</b-form-checkbox>
                  </b-card>

                  <b-card bg-variant="light" class="mt-4">
                    <b-row>
                      <b-col sm="6">
                        <b-card :header="$t('header.logotipo-cliente')" header-tag="header">
                          <b-card-text><img :src="form.logoCliente | urlImagem" alt="Logo" height="40"
                              v-on:click="showModalUploadImagem(1)" /></b-card-text>

                          <!-- <template #footer>
                            <b-button href="#" block variant="secondary" size="sm">Alterar</b-button>
                          </template> -->
                        </b-card>
                      </b-col>
                      <b-col sm="6">
                        <b-card :header="$t('header.logotipo-fornecedor')" header-tag="header">
                          <b-card-text><img :src="form.logoFornecedor | urlImagem" alt="Logo" height="40"
                              v-on:click="showModalUploadImagem(2)" /></b-card-text>

                          <!-- <template #footer>
                            <b-button href="#" block variant="secondary" size="sm">Alterar</b-button>
                          </template> -->
                        </b-card>
                      </b-col>
                    </b-row>
                    <b-row class="mt-3">
                      <b-col sm="12">
                        <b-card :header="$t('header.rodape')" header-tag="header">
                          <b-card-text><img :src="form.logoRodape | urlImagem" alt="Logo" height="40"
                              v-on:click="showModalUploadImagem(3)" /></b-card-text>

                          <!-- <template #footer>
                            <b-button href="#" block variant="secondary" size="sm">Alterar</b-button>
                          </template> -->
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab :title="$t('title.composicao')">
            <b-card-text>
              <b-row>
                <b-col sm="6">
                  <b-card bg-variant="light" :header="$t('header.produtos')" class="container-caracteristicas">
                    <b-form-group label="Origem:">
                      <b-form-select v-model="origem" :options="optionsOrigem" size="sm">
                      </b-form-select>
                    </b-form-group>

                    <b-form-group>
                      <b-form-select v-if="origem == 'componente'" v-model="componente_selecionado" :options="componentes"
                        :select-size="10" size="sm">
                      </b-form-select>
                      <b-form-select v-if="origem == 'fechamento-geral'" v-model="fechamento_selecionado"
                        :options="fechamentos" :select-size="10" size="sm">
                      </b-form-select>
                      <b-form-select v-if="origem == null" :select-size="10" size="sm">
                      </b-form-select>
                    </b-form-group>

                    <b-row align-v="center">
                      <b-col>
                        <b-img v-if="imagem" center height="115" :src="imagem | urlImagem" />
                        <img v-else src="../../assets/sem-foto.png" height="120" />
                      </b-col>
                      <b-col>
                        <b-form-group id="input-group-2" :label="$t('label.relatorio')">
                          <b-form-select v-model="relatorio_selecionado" :options="relatorios" :select-size="5" size="sm">
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-button size="sm" block v-on:click="addItem()">
                      <b-icon icon="plus" aria-hidden="true"></b-icon>{{ $t('botoes.adicionar') }}
                    </b-button>
                  </b-card>
                </b-col>
                <b-col sm="6">
                  <b-card bg-variant="light" :header="$t('header.itens-selecionados')"
                    class="container-caracteristicas d-flex flex-column" v-if="form.itens.length > 0">
                    <!--  <b-form-group>
                      <b-form-select v-model="item_selecionado"  :select-size="10" size="sm">
                      </b-form-select>
                    </b-form-group> -->
                    <div class="table-wrapper">
                      <table class="
                            table
                            table-bordered
                            table-fixed
                            table-condensed
                            table-sm
                          ">
                        <thead>
                          <th>Id</th>
                          <th>{{ $t('table.title.tipo') }}</th>
                          <th>{{ $t('table.title.descricao') }}</th>
                        </thead>
                        <draggable :list="form.itens" tag="tbody">
                          <tr v-for="(element, index) in form.itens" :key="(element.value + index)"
                            @click="selectRow(element.value)" :class="estilo(element.value)">
                            <td scope="row">{{ element.value }}</td>
                            <td>{{ element.type }}</td>
                            <td>{{ element.text }}</td>
                          </tr>
                        </draggable>
                      </table>
                    </div>

                    <b-row align-v="center">
                      <b-col>
                        <b-img v-if="imagem_relatorio" center height="115" :src="imagem_relatorio | urlImagem" />
                        <img v-else src="../../assets/sem-foto.png" height="120" />
                      </b-col>
                      <b-col>
                        <b-button size="sm" block v-on:click="removeItem()">
                          <b-icon icon="trash" aria-hidden="true"></b-icon>
                          {{ $t('botoes.remover-item-selecionado') }} </b-button>
                        <b-button size="sm" block v-on:click="removeItens()">
                          <b-icon icon="trash" aria-hidden="true"></b-icon>
                          {{ $t('botoes.remover-todos-os-itens') }} </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab :title="$t('title.apresentacoes')" v-if="apresentacao_id && form">
            <b-button @click="gerarApresentacao">{{ $t('botoes.gerarApresentacao') }}</b-button>

            <b-table :items="form.apresentacoes" :fields="fields" responsive="sm" bordered hover small center
              :current-page="currentPage" :per-page="perPage" class="table-controles text-center mt-2"
              head-variant="light">
              <template #cell(created_at)="row">
                {{ row.item.created_at | dataCompleta }}
              </template>

              <template v-slot:cell(ações)="row">
                <b-button variant="link">
                  <b-icon icon="cloud-arrow-down-fill" variant="primary" @click="baixar(row.item.arquivo)"></b-icon>
                </b-button>
              </template>
            </b-table>

            <b-row>
              <b-col sm="5" md="6" class="my-1">
                <b-form-group>
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="form.apresentacoes.length" :per-page="perPage"
                  align="fill" size="sm" class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-form>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col>
                <p class="h1 ml-3">
                  <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                </p>
              </b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                  {{ $t('modal.preenchaTodosOsDadosCorretamente') }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button variant="primary" class="float-right" v-if="carregando" disabled>
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button v-else type="submit" variant="primary" class="float-right" @click="salvarApresentacaoGeral()">
              {{ $t('botoes.salvar') }} </b-button>
            <b-button v-if="apresentacao_id" type="submit" variant="danger" class="float-right mr-2" @click="deletar()">
              {{ $t('botoes.deletar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <upload-imagem :tipoImagem="tipoImagem" @logoMudou="alterarLogo"></upload-imagem>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";
import UploadImagem from "./UploadImagem.vue";
export default {
  components: {
    Multiselect,
    draggable,
    UploadImagem,
  },
  props: {
    apresentacao_id: null,
    familia: null,
  },
  data() {
    return {
      form: {
        apresentacoes: [],
        fornecedor: "",
        responsaveis: [],
        semana: "",
        local: "",
        data: "",
        descricao: null,
        itens: [],
        print_cpk: true,
        print_workflow: true,
        print_conformidade: true,
        print_boleado: true,
        print_relatorio: true,
        print_plano: true,
        print_individual: true,
        logoFornecedor: null,
        logoCliente: null,
        logoRodape: null,
      },
      tipoImagem: 1,
      search: "",
      origem: null,
      optionsOrigem: [
        { text: this.$t('opcao.fechamento-geral'), value: "fechamento-geral" },
        { text: this.$t('opcao.componente'), value: "componente" },
      ],
      optionsResponsaveis: [],
      responsaveis: [],
      show: true,
      menssagem: null,
      carregando: null,
      componentes: [],
      componente_selecionado: null,
      relatorios: [],
      relatorio_selecionado: null,
      imagem: null,
      item_selecionado: null,
      imagem_relatorio: null,

      fechamentos: [],
      fechamento_selecionado: null,

      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 5, text: this.$t('paginacao.5-itens-por-pagina') },
        { value: 10, text: this.$t('paginacao.10-itens-por-pagina') },
        { value: 15, text: this.$t('paginacao.15-itens-por-pagina') },
        { value: 100, text: this.$t('paginacao.100-itens-por-pagina') },
      ],
      url: null,
      fields: [
        "id",
        {
          key: "created_at",
          label: this.$t('table.title.data-criacao'),
          sortable: true,
        },
        this.$t('table.title.arquivo'),
        this.$t('table.title.acoes'),
      ],
    };
  },
  methods: {
    baixar(item) {
      var path = this.url + "/" + item;

      window.location.href = path;
    },
    gerarApresentacao() {
      this.$emit("gerarApresentacao", this.form);
    },
    alterarLogo(logo) {
      if (logo) {
        if (logo.tipo == "1") {
          this.form.logoCliente = logo.imagem;
        }
        if (logo.tipo == "2") {
          this.form.logoFornecedor = logo.imagem;
        }
        if (logo.tipo == "3") {
          this.form.logoRodape = logo.imagem;
        }
      }
    },
    showModalUploadImagem(tipoImagem) {
      this.tipoImagem = tipoImagem;
      this.$bvModal.show("modal-upload-imagem");
    },
    estilo(item) {
      if (this.item_selecionado != null) {
        if (item == this.item_selecionado) {
          return "highlight";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    selectRow(item) {
      this.item_selecionado = item;
    },

    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
    salvarApresentacaoGeral() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      if (this.apresentacao_id != null) {
        this.$http
          .patch(
            "api/apresentacao-geral/" + this.apresentacao_id,
            this.form,
            config
          )
          .then((response) => {
            this.makeToast("success");
            this.$bvModal.hide("modal-apresentacao-geral");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      } else {
        this.$http
          .post("api/apresentacao-geral", this.form, config)
          .then((response) => {
            this.makeToast("success");
            this.$bvModal.hide("modal-apresentacao-geral");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-apresentacao-geral");
    },
    addItem() {
      if (
        this.componente_selecionado != null ||
        this.fechamento_selecionado != null
      ) {
        this.carregarRelatorio();
      }
    },
    removeItem() {
      if (this.item_selecionado != null) {
        var index = this.form.itens
          .map(function (item) {
            return item.value;
          })
          .indexOf(this.item_selecionado);
        if (index > -1) {
          this.form.itens.splice(index, 1);
        }
      }
      this.item_selecionado = null;
      this.imagem_relatorio = null;
    },
    removeItens() {
      this.form.itens = [];
      this.item_selecionado = null;
      this.imagem_relatorio = null;
    },
    carregarComponentes() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/componente")
        .then((response) => {
          response.data.forEach((item) => {
            this.componentes.push({
              value: item.id,
              text:
                item.familia.descricao +
                " - " +
                item.descricao +
                " - " +
                item.desenho,
            });
          });

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarComponente() {
      this.carregando = true;
      this.menssagem = null;
      this.relatorios = [];

      this.$http
        .get("api/componente/" + this.componente_selecionado)
        .then((response) => {
          response.data.relatorios.forEach((item) => {
            this.relatorios.push({
              value: item.id,
              text: item.descricao,
            });
          });

          this.imagem = response.data.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      if (this.origem == "componente") {
        this.$http
          .get("api/relatorio/simples/" + this.relatorio_selecionado)
          .then((response) => {
            var c = {
              type: "componente",
              value: this.relatorio_selecionado,
              text:
                response.data.componente.familia.descricao +
                " - " +
                response.data.componente.descricao +
                " - " +
                response.data.componente.desenho +
                " - " +
                response.data.descricao,
            };
            this.form.itens.push(c);

            this.carregando = false;
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      } else if (this.origem == "fechamento-geral") {
        this.$http
          .get("api/fechamento-geral/" + this.relatorio_selecionado)
          .then((response) => {
            var c = {
              type: "fechamento-geral",
              value: this.relatorio_selecionado,
              text:
                response.data.familia.descricao +
                " - " +
                response.data.descricao,
            };
            this.form.itens.push(c);

            this.carregando = false;
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      }
    },

    carregarDadosRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/relatorio/simples/" + this.item_selecionado)
        .then((response) => {
          this.imagem_relatorio = response.data.componente.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarFechamentos() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/fechamento-geral")
        .then((response) => {
          response.data.forEach((item) => {
            this.fechamentos.push({
              value: item.id,
              text: item.descricao,
            });
          });

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarFechamento() {
      this.carregando = true;
      this.menssagem = null;
      this.relatorios = [];

      var fech = this.fechamentos.filter(
        (f) => f.value == this.fechamento_selecionado
      );

      this.relatorios.push({
        value: fech[0].value,
        text: fech[0].text,
      });

      this.carregando = false;
    },

    carregarApresentacaoGeral() {
      this.$http
        .get("api/apresentacao-geral/" + this.apresentacao_id)
        .then((response) => {
          response.data.responsaveis.forEach((responsavel) => {
            responsavel.text = responsavel.user.name;
            responsavel.value = responsavel.user_id;
          });

          this.form = response.data;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },
    deletar() {
      let confirmacao = confirm(
        this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-apresentacao')
      );

      if (!confirmacao) {
        return false;
      }

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(
          "api/apresentacao-geral/" + this.apresentacao_id,
          formData,
          config
        )
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-apresentacao-geral");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    carregarUsuarios() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/user")
        .then((response) => {
          this.optionsResponsaveis = []
          response.data.forEach((element) => {
            let departamento = element.departamento_id ? '(' + element.departamento.descricao + ')' : ''
            this.optionsResponsaveis.push({
              value: element.id,
              text: element.name + departamento,
            });
          });
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
  },
  computed: {
    semanas: {
      get() {
        return this.$store.state.semanas;
      },
      set(value) {
        this.$store.commit("setSemanas", value);
      },
    },
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptionsResponsaveis() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.optionsResponsaveis.filter(
        (opt) => this.responsaveis.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptionsResponsaveis.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  mounted() {
    this.carregarComponentes();
    this.carregarUsuarios();
    this.carregarFechamentos();
    if (this.apresentacao_id != null) {
      this.carregarApresentacaoGeral();
    }
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    origem: function () {
      this.componente_selecionado = null
      this.fechamento_selecionado = null
    },
    componente_selecionado: function () {
      if (this.componente_selecionado)
        this.carregarComponente();
    },
    fechamento_selecionado: function () {
      if (this.fechamento_selecionado)
        this.carregarFechamento();
    },
    item_selecionado: function (item) {
      if (item) {
        var it_selecioando = this.form.itens.filter(
          (it) => it.value == item
        )[0];
        if (it_selecioando.type == "componente") {
          this.carregarDadosRelatorio();
        } else {
          this.imagem_relatorio = null;
        }
      }
    },
    apresentacao_id: function () {
      if (this.apresentacao_id != null) {
        this.carregarApresentacaoGeral();
      } else {
        this.form = {
          apresentacoes: [],
          fornecedor: "",
          responsaveis: [],
          semana: "",
          local: "",
          data: "",
          descricao: null,
          itens: [],
          print_cpk: true,
          print_workflow: true,
          print_conformidade: true,
          print_boleado: true,
          print_relatorio: true,
          print_plano: true,
          print_individual: true,
          logoFornecedor: null,
          logoCliente: null,
          logoRodape: null,
        };
      }
    },
    familia: function () {
      this.form.familia_id = this.familia.id;
      if (!this.apresentacao_id) {
        this.form.descricao = this.familia.descricao;
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  height: 320px;
  max-height: 320px !important;
  overflow: auto;
  font-size: 14px;
}

.table-wrapper table {
  background-color: white;
}

.container-caracteristicas {
  height: 100%;
}

.text-radio {
  font-size: 14px;
}

.highlight {
  background-color: #ddd;
}

tr:hover {
  cursor: pointer;
}
</style>