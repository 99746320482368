<template>
  <div class="pt-3">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand>
        <router-link to="/"><img src="@/assets/logo.png" alt="Logo" height="40" /></router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="justify-content-md-center">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item v-b-modal.config-geral>Configurações</b-nav-item> -->

          <b-nav-item-dropdown no-caret right>
            <template #button-content>
              <b-avatar :badge="totalNotificacoes > 0 ? totalNotificacoes.toString() : false"
                badge-variant="danger" variant="link" icon="bell-fill"></b-avatar>
            </template>
            <b-dropdown-text style="width:100%; color: grey">
              {{ $t('menu.voce-possui') }} {{ totalNotificacoes }} {{ $t('menu.notificacoes') }}
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-for="(notification, index) in notifications" :key="notification.id"
              :to="notification.data.url">

              <b-row align-v="center" align-h="center" class="text-sm-left"
                :class="notification.read_at ? 'text-gray' : 'text-gray-bold'">
                <b-col cols="auto" class="pl-0">
                  <b-img center height="30" :src="notification.data.urlImagem | urlImagem" />
                </b-col>
                <b-col class="pr-3">
                  <b-row class="font-weight-bold small">{{ notification.data.message }}</b-row>
                  <b-row class="text-truncate font-weight-light small">{{ notification.data.componente }}</b-row>
                  <b-row class="font-weight-light small">{{ $t('label.semana') }} {{ formatDate(notification.created_at) }}</b-row>
                </b-col>
                <b-col cols="auto">
                  <b-icon v-if="!notification.read_at" icon="circle-fill" animation="throb" variant="primary">
                  </b-icon>
                </b-col>
              </b-row>

              <b-dropdown-divider v-if="index < (notifications.length - 1)"></b-dropdown-divider>

            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-text style="width: 100%; color: grey" class="text-left">
              <router-link to="/notificacoes">
                <b-button variant="outline-primary">{{ $t('menu.acessar-todos') }}</b-button>
              </router-link>

            </b-dropdown-text>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right no-caret>
            <template #button-content>
              <b-avatar variant="link" :src="user.profileImage | urlImagem"></b-avatar>
            </template>
            <b-dropdown-text style="width: 240px; color: grey">
              {{ user.name }}
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/minha_area">{{ $t("menu.meuPerfil") }}</b-dropdown-item>
            <b-dropdown-item @click="logOut">{{ $t("menu.sair") }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <configuracao-geral />
  </div>
</template>

<script>
import ConfiguracaoGeral from "@/components/modal/ConfiguracaoGeral.vue";

import dayjs from 'dayjs'
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export default {
  name: "Menu",
  data() {
    return {
      totalNotificacoes: 0,
      notifications: []
    }
  },
  components: {
    ConfiguracaoGeral,
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  methods: {
    formatDate(date) {     
      return dayjs(date).week();
    },
    carregarNotificacoes() {
      this.carregando = true;
      this.$http
        .get("api/notifications?page=1&perpage=10")
        .then((response) => {
          this.totalNotificacoes = response.data.total
          this.notifications = response.data.data
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    this.carregarNotificacoes();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-gray {
  color: gray;
  min-width: 25rem;
}

.text-gray-bold {
  color: rgb(78, 78, 78);
  min-width: 25rem;
}
</style>
