<template>
  <div class="ponto" ref="draggableContainer" :id="'ponto'+tipo + ponto.id">
    <div class="draggable" :ref="'ponto' + ponto.id" @mousedown="dragMouseDown">
      <span class="dot"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ponto: {
      type: Object,
    },
    tipo:''
  },
  name: "Ponto",
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      posBoxX: 0,
      posBoxY: 0,
    };
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault();
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();

      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      // set the element's new position:
      this.$emit("moveu", this.ponto.id);
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.$emit("paroudemover", {
        ponto: this.ponto.id,
        pagina: this.ponto.pagina_id,
      });
    },
  },
};
</script>

<style scoped>
.ponto {
  float: left;
  border-color: blueviolet;
  position: absolute;
}

.draggable table {
  background-color: white;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

#draggable-container {
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>