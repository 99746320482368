<template>
    <b-modal id="modal-departamento" size="lg" :title="$t('title.departamento')">
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5>{{ $t('title.departamento') }}</h5>
            <div class="btn">
                <b-icon icon="x" @click="close()"></b-icon>
            </div>
        </template>
        <b-form v-if="show">
            <b-form-group :label="$t('label.descricao')">
                <b-form-input v-model="form.descricao" type="text" :placeholder="$t('placeholder.entre-com-a-descricao')"
                    required>
                </b-form-input>
            </b-form-group>

            <b-form-group :label="$t('label.permissoes')" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group id="checkbox-group-1" v-model="form.permissoes" :options="optionsPermissoes"
                    :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
            </b-form-group>
        </b-form>

        <b-alert show variant="danger" v-if="menssagem">
            <ul v-if="menssagem">
                <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
            </ul>
        </b-alert>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-v="center">
                    <b-col cols="12" md="8">
                        <b-row align-v="center" align-h="center">
                            <b-col>
                                <p class="h1 ml-3">
                                    <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                                </p>
                            </b-col>
                            <b-col cols="12" md="10">
                                <p class="pt-2">
                                    <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                                    {{ $t('modal.preenchaTodosOsDadosCorretamente') }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" md="4">
                        <b-button variant="primary" class="float-right" v-if="carregando" disabled>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                            {{ $t('botoes.carregando') }}
                        </b-button>

                        <b-button v-if="!carregando" type="submit" variant="primary" class="float-right" @click="salvar()">
                            {{ $t('botoes.salvar') }} </b-button>

                        <b-button v-if="departamento.id && !carregando" type="submit" variant="danger"
                            class="float-right mr-2" @click="
                                showMsgConfirmation(
                                    'Tem certeza que deseja remover esse departamento?'
                                )
                                ">
                            {{ $t('botoes.deletar') }} </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        departamento: {
            type: Object,
            default: function () {
                return {
                    id: null,
                    descricao: null,
                    permissoes:[]
                };
            },
        },
    },
    data() {
        return {
            form: {
                descricao: null,
                permissoes:[]
            },
            departamentos: [],
            show: true,
            menssagem: null,
            carregando: null,
            url: "api/departamento",
            optionsPermissoes: []
        };
    },
    methods: {
        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        close() {
            this.$bvModal.hide("modal-departamento");
        },
        limpar() {
            this.form.descricao = null;
            this.form.permissoes = [];
            this.menssagem = null;
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;

            let config = {
                headers: {
                    Accept: "application/json",
                },
            };

            if (this.departamento.id) {
                this.$http
                    .patch(this.url, this.form, config)
                    .then((response) => {
                        this.makeToast("success");
                        this.$bvModal.hide("modal-departamento");
                        this.carregando = false;
                        this.$emit("listaMudou");
                    })
                    .catch((errors) => {
                        this.menssagem = errors.response.data.errors;
                        this.carregando = false;
                    });

            } else {
                this.$http
                    .post(this.url, this.form, config)
                    .then((response) => {
                        this.makeToast("success");
                        this.$bvModal.hide("modal-departamento");
                        this.carregando = false;
                        this.$emit("listaMudou");
                    })
                    .catch((errors) => {
                        this.menssagem = errors.response.data.errors;
                        this.carregando = false;
                    });
            }
        },
        deletar() {
            this.menssagem = null;
            this.carregando = true;
            let formData = new FormData();
            formData.append("_method", "delete");

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post(this.url, formData, config)
                .then((response) => {
                    this.makeToast("success");
                    this.$bvModal.hide("modal-departamento");
                    this.carregando = false;
                    this.$emit("listaMudou");
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        showMsgConfirmation(msg) {
            this.$bvModal
                .msgBoxConfirm(msg, {
                    title: this.$t('title.confirme-por-favor'),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t('confirmacao.sim'),
                    cancelTitle: this.$t('confirmacao.nao'),
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value == true) {
                        this.deletar();
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },        
        carregarPermissoes() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/permissoes")
                .then((response) => {
                    this.optionsPermissoes = []
                    response.data.forEach((element) => {
                        this.optionsPermissoes.push({
                            value: element.id,
                            text: element.descricao,
                        });
                    });
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
    },
    mounted() {
        this.carregarPermissoes();
    },
    watch: {
        departamento: function (departamento) {
            if (this.departamento.id) {
                this.form.descricao = departamento.descricao;
                this.form.permissoes = departamento.permissoes;
                this.url = "api/departamento/" + this.departamento.id;
            } else {
                this.url = "api/departamento";
                this.limpar();
            }
        }
    },
};
</script>

<style ></style>