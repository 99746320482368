<template>
  <div class="card">
    <table class="text-center table-bordered table-condensed">
      <tr>
        <th>
          <img
            :src="'../assets/simbol/' + elemento.tipo_elemento.imagem"
            width="24"
          />
        </th>
        <th colspan="3">{{ elemento.descricao }}</th>
        <th colspan="2">AUTOMARB</th>
      </tr>
      <tr>
        <th>Ax</th>
        <th width="36">{{indicadorCp}}</th>
        <th width="36">{{indicadorCp}}K</th>
        <th>XMED</th>
        <th>RANGE</th>
        <th>TOLERANCE</th>
      </tr>
      <tr v-if="elemento.exibirx">
        <td :style="elemento.calculox | calculo">X</td>
        <td ></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          {{ elemento.lixtol | duasCasas }} | {{ elemento.lsxtol | duasCasas }}
        </td>
      </tr>
      <tr v-if="elemento.exibiry">
        <td :style="elemento.calculoy | calculo">Y</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          {{ elemento.liytol | duasCasas }} | {{ elemento.lsytol | duasCasas }}
        </td>
      </tr>
      <tr v-if="elemento.exibirz">
        <td :style="elemento.calculoz | calculo">Z</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          {{ elemento.liztol | duasCasas }} | {{ elemento.lsztol | duasCasas }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
  },
  computed: {
    indicadorCp() {
      return this.indicador == "cp" ? "CP" : "PP";
    },
  },
};
</script>

<style scoped>
.table-condensed {
  font-size: 12px;
  padding: 0;
}
tr td,
th {
  padding: 2px !important;
 
}
</style>